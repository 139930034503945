import styled from "styled-components";

export const Container = styled.div`
  text-align: center;

  svg {
    width: 50rem;
    height: auto;
    margin-top: 5rem;

    ${({ theme }) => theme.media.sm`
      width: 100%;
    `}
  }
`;

export const Title = styled.h2`
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 3.2rem;
  margin: 0 0 2.1rem;
  color: ${({ theme }) => theme.colors.grayDark};
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 1.6rem 0;

  a {
    color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    text-decoration: none;
  }
`;
