import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 3rem 0;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
  `}
`;

export const Header = styled.div`
  margin-bottom: 3rem;
`;

export const Aside = styled.aside`
  flex-shrink: 0;
  margin: 2rem 0;
  margin-left: -11.2rem;
  padding-right: 6.4rem;
  position: sticky;
  top: 8rem;

  ${({ theme }) => theme.media.lg`
    margin-left: 0;
  `}

  ${({ theme }) => theme.media.sm`
    margin-top: 0;
    margin-bottom: 3rem;
    padding: 0;
    position: static;
  `}
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  ${({ theme }) => theme.media.sm`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -1rem;
  `}
`;

export const ListItem = styled.li`
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 2rem;
  text-align: left;

  ${({ theme }) => theme.media.sm`
    margin: 0;
    padding: 1rem;
  `}

  a {
    color: ${({ theme }) => theme.colors.gray};
    text-decoration: none;

    &.active {
      color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    }
  }
`;

export const Main = styled.main`
  width: 100%;
  max-width: 64rem;
  padding: 2.4rem;

  /* TODO: Is this a candidate for a <Well> component? */
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  border: 0.1rem solid ${({ theme }) => theme.colors.grayLighter};
  border-radius: 0.6rem;
`;
