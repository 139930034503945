import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";

class FormUpdate extends Component {
  static propTypes = {
    formId: PropTypes.string.isRequired,
    fetch: PropTypes.bool
  };

  static defaultProps = {
    fetch: true
  };

  state = {
    fetch: this.props.fetch
  };

  onClick() {
    const { formId } = this.props;

    this.setState({ fetch: false }, () => {
      const form = document.querySelector(`#${formId}`);

      if (form) form.submit();
    });
  }

  render() {
    const { fetch } = this.state;

    return (
      <Fragment>
        <button
          className="text-sm text-helix-brand"
          type="button"
          onClick={this.onClick.bind(this)}>
          Update
        </button>
        <input type="hidden" name="fetch" value={fetch} />
      </Fragment>
    );
  }
}

export default FormUpdate;
