import styled from "styled-components";
import { Tab } from "react-tabs";

export const Container = styled(Tab)`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin: 0 1.2rem;
  border-bottom: 0.2rem solid transparent;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.react-tabs__tab--selected {
    color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    border-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  }
`;
