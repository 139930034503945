import styled from "styled-components";

export const Container = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 4.2rem;
  height: 4.2rem;
  margin-right: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  color: ${({ theme, hasError }) =>
    hasError ? theme.colors.red : theme.colors.grayAlt};
  border: 0.2rem solid
    ${({ theme, hasError }) =>
      hasError ? theme.colors.red : theme.colors.grayLighter};
`;

export const Text = styled.span`
  flex-grow: 1;
  color: ${({ theme, hasError }) =>
    hasError ? theme.colors.red : theme.styleGuide.colors.helix['brand']};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Title = styled.span`
  flex-grow: 1;
  display: block;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const SubTitle = styled.span`
  font-size: 1.3rem;
  line-height: 2.4rem;
`;

export const Emoji = styled.span`
  display: inline-block;
  font-family: "Segoe UI Emoji";
  font-size: 1.9rem;
  transform: translateY(0.1rem);
`;
