import styled from "styled-components";
import { Container as BaseFormGroup } from "../../common/styled/form-group";
import { Container as BaseLabel } from "../../common/styled/label";
import { default as BaseInput } from "../../common/Input";
import { default as BaseButton } from "../../common/Button";

export const Section = styled.div`
  border-top: ${({ theme }) =>
    `1px solid ${theme.styleGuide.colors.gray[400]}`};

  &:first-child {
    margin-top: 0;
    border: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SubSection = styled.div`
  padding: 2.4rem 0;
  border-top: ${({ theme }) =>
    `1px solid ${theme.styleGuide.colors.gray[400]}`};
  &:first-child {
    border: none;
  }
`;

export const Header = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3rem;
  margin: 0 0 1.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const Description = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 2.4rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
      font-size: 1.3rem;
      line-height: 1.7rem;
    `}
`;

export const SubSectionDescription = styled(Description)`
  margin: 0 0 0.6rem;
`;

export const SubHeader = styled.h3`
  margin-bottom: 2.4rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};
`;

export const Tutorial = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
`;

export const IntegrationLabel = styled.h4`
  margin-bottom: 0.6rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const Button = styled(BaseButton)``;

export const RemoveButton = styled(BaseButton)`
  color: ${({ theme }) => theme.styleGuide.colors.red[600]};
  &:hover:not([disabled]) {
    color: ${({ theme }) => theme.styleGuide.colors.red[500]};
  }

  &:focus:not([disabled]) {
    color: ${({ theme }) => theme.styleGuide.colors.red[600]};
  }
`;

export const FormGroup = styled(BaseFormGroup)`
  flex-basis: 22rem;
  flex-grow: 3;
  margin: 0;

  ${Button} {
    margin-top: 2rem;
  }

  ${RemoveButton} {
    margin-top: 2rem;
  }
`;

export const Input = styled(BaseInput)`
  margin: 0;
`;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;

  ${FormGroup} {
    margin-left: 1.2rem;
    &:first-child:last-child {
      margin-left: 0;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      flex-basis: 12.8rem;
      flex-grow: 0;
    }
    ${({ theme }) => theme.media.md`
      flex-basis: 0;
      flex-grow: 0;
      width: 100%;
      margin-left: 0;
      &:first-child {
        flex-grow: 0;
        flex-basis: 0;
        width: 100%;
      }
      &:last-child {
        flex-grow: 0;
        flex-basis: 0;
        width: 100%;
      }
  `}
  }
  ${({ theme }) => theme.media.md`
    flex-direction: column;
  `}
`;

export const Label = styled(BaseLabel)`
  margin-bottom: 0.4rem;
`;

export const CopyInput = styled(Input)`
  flex-basis: 22rem;
  flex-grow: 3;
  ${({ theme }) => theme.media.md`
    flex-basis: 0;
    flex-grow: 0;
    margin-bottom: 2.4rem;
  `}
`;
export const CopyButton = styled(BaseButton)`
  flex-basis: 12.8rem;
  flex-grow: 0;
  ${({ theme }) => theme.media.md`
    flex-basis: 0;
    flex-grow: 0;
  `}
`;
