import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";
import { default as BaseCheckboxLabel } from "../CheckboxLabel";

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 50rem;
  max-height: 60rem;
  height: 100%;
  padding-left: 0;
  padding-right: 0;

  ${({ theme }) => theme.media.sm`
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
  `}
`;

export const ModalHeader = styled(BaseModal.Header)`
  flex-shrink: 0;
  padding: 0 2rem;
`;

export const ModalBody = styled(BaseModal.Body)`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 2rem;
`;

export const ModalFooter = styled(BaseModal.Footer)`
  padding: 0 2rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Message = styled.div`
  width: 0;
  height: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  top: -99999rem;
  left: -99999rem;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -1rem;
`;

export const Action = styled.div`
  padding: 0 1rem;

  &:not(:first-child):last-child {
    margin-left: auto;
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10rem 0;

  svg {
    width: 5.7rem;
    height: 5.7rem;
    margin-bottom: 2rem;
    fill: ${({ theme }) => theme.colors.green};
  }
`;

export const Status = styled.div`
  font-size: 1.8rem;
  margin-bottom: 2rem;
`;

export const Recipient = styled.div`
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: -0.1rem;
  position: relative;
  z-index: 1;
`;

export const Tab = styled.button`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  margin-left: 2.8rem;
  padding: 0 0 0.8rem;
  color: ${({ theme, isActive }) =>
    isActive
      ? theme.styleGuide.colors.helix['brand']
      : theme.styleGuide.colors.gray[800]};
  border-bottom: 0.2rem solid
    ${({ theme, isActive }) =>
      isActive ? theme.styleGuide.colors.helix['brand'] : "transparent"};
  appearance: none;
  cursor: pointer;

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}

  &:first-child {
    margin-left: 0;
  }
`;

export const Customize = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 -2rem;
  padding: 1.6rem 2rem 0;
  border-top: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
  overflow: auto;
`;

export const CheckboxLabel = styled(BaseCheckboxLabel)`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const Includes = styled.div`
  flex-grow: 1;
  margin: 0 -2rem;
  padding: 1.6rem 2rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
`;

export const Include = styled.div`
  margin-bottom: 1.4rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const IncludesHeader = styled.h3`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  margin: 2.4rem 0 1rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const SendFromEmailApp = styled.strong`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  a {
    color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    text-decoration: none;
  }
`;

export const Preview = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  margin: 0 -2rem;
  padding: 1.2rem 3.6rem;
  overflow: auto;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
  border-top: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
`;

export const EmailIntro = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 1.6rem 0 2.4rem;
  text-align: center;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const EmailCard = styled.div`
  display: flex;
  align-items: center;
  min-width: 36.4rem;
  padding: 1.2rem;
  border-radius: 0.6rem;
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  background-color: ${({ theme }) => theme.styleGuide.colors.white};

  ${({ theme }) => theme.media.sm`
    min-width: 0;
  `}
`;

export const EmailCardImage = styled.div`
  flex-shrink: 0;
  margin-right: 2.4rem;

  img,
  svg {
    width: 9.6rem;
    height: 12.4rem;
    vertical-align: middle;
  }
`;

export const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  margin: 0;
  padding-right: 1rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Address = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  padding-right: 1rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const CityStateZip = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  padding-right: 1rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Links = styled.div`
  margin-top: 1.6rem;

  a {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2rem;
    margin-left: 1.2rem;
    color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    text-decoration: none;

    ${({ theme }) => theme.media.sm`
      font-size: 1.5rem;
    `}

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const Agent = styled.div`
  margin-top: 2.4rem;
  text-align: center;
`;

export const AgentAvatar = styled.div`
  margin-bottom: 0.8rem;

  img {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
  }
`;

export const AgentName = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const AgentPhone = styled.a`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const AgentCompany = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const AgentCompanyLogo = styled.div`
  margin-top: 1.2rem;

  img {
    max-width: 11rem;
    max-height: 4.8rem;
    margin: auto;
  }
`;

export const PoweredBy = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin: 2.4rem 0 2.8rem;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.styleGuide.colors.gray[600]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-top: 2.4rem;
  `}

  svg {
    width: 9.6rem;
    height: 1.6rem;
    margin: 0.8rem auto 0;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[600]};
  }
`;
