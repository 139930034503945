import { Modal } from "@wrstudios/components";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { updatePageConfig } from "../../api/page";
import Button from "../common/Button";
import Checkbox from "../common/Checkbox";
import Input from "../common/Input";
import Loading from "../common/Loading";
import usePageConfig from "../common/usePageConfig";
import {
  AgentNotes,
  CheckboxContainer,
  Checkboxes,
  CheckboxLabel,
  Error,
  Label,
  ModalContent,
  ModalFooter,
  Note,
  Optional,
  Saved
} from "./styled/customize-modal-average-price-per-sqft";

const MAX_NOTE_LENGTH = 190;

function CustomizeModalAveragePricePerSqft({ reportId, page, onClose }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [note, setNote] = useState("");
  const [showSubjectProperty, setShowSubjectProperty] = useState(false);
  const [showTrendline, setShowTrendline] = useState(false);
  const [updateError, setUpdateError] = useState();
  const { isLoading, error } = usePageConfig({
    reportId,
    pageKey: page.key,
    onSuccess(config) {
      setNote(config.note || "");
      setShowSubjectProperty(config.showSubjectProperty || false);
      setShowTrendline(config.showTrendline || false);
    }
  });

  const isMountedRef = useRef();
  useEffect(() => {
    isMountedRef.current = true;

    let timeout;
    if (isSaved) {
      timeout = setTimeout(() => {
        if (isMountedRef.current) {
          setIsSaved(false);
        }
      }, 2000);
    }

    return () => {
      isMountedRef.current = false;
      clearTimeout(timeout);
    };
  }, [isSaved]);

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <Modal.Header>
          Average Price/Sq.Ft. <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <AgentNotes>
            <Label htmlFor="note">
              Add a note <Optional>Optional</Optional>
            </Label>
            <Input
              id="note"
              as="textarea"
              rows={7}
              value={note}
              maxLength={MAX_NOTE_LENGTH}
              autoFocus
              placeholder="Add a note for your client"
              onChange={(e) => setNote(e.target.value)}
            />
            <Note>
              {note.length}/{MAX_NOTE_LENGTH} Characters
            </Note>
          </AgentNotes>
          <Checkboxes>
            <CheckboxContainer>
              <CheckboxLabel>
                <Checkbox
                  size="sm"
                  checked={showSubjectProperty}
                  onChange={(e) => setShowSubjectProperty(e.target.checked)}
                />
                Show subject property in chart
              </CheckboxLabel>
            </CheckboxContainer>
            <CheckboxContainer>
              <CheckboxLabel>
                <Checkbox
                  size="sm"
                  checked={showTrendline}
                  onChange={(e) => setShowTrendline(e.target.checked)}
                />
                Show trendline in chart
              </CheckboxLabel>
            </CheckboxContainer>
          </Checkboxes>
        </Modal.Body>
        <ModalFooter>
          {(error || updateError) && <Error>{error || updateError}</Error>}
          {isSaved && <Saved>Saved!</Saved>}
          <Button
            app="cma"
            disabled={isUpdating || isLoading}
            onClick={async () => {
              try {
                setIsUpdating(true);
                setUpdateError(undefined);
                await updatePageConfig(reportId, page.key, {
                  note,
                  show_subject_property: showSubjectProperty,
                  show_trendline: showTrendline
                });
                setIsSaved(true);
              } catch (error) {
                setUpdateError(error.message);
              } finally {
                setIsUpdating(false);
              }
            }}>
            <Loading showSpinner={isUpdating}>
              {isUpdating ? "Saving" : "Save"}
            </Loading>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

CustomizeModalAveragePricePerSqft.propTypes = {
  reportId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  reportId: state.report.id
});

export default connect(mapStateToProps)(CustomizeModalAveragePricePerSqft);
