import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IconTrashCan } from "@wrstudios/icons";
import { colors } from "../../theme";
import Button from "../common/Button";
import Input from "../common/Input";
import Label from "../common/Label";
import Datepicker from "../common/Datepicker";
import Loading from "../common/Loading";
import AlertDanger from "../common/AlertDanger";
import { Select, Option } from "../common/Select";
import AddressAutoComplete from "../address/AddressAutoComplete";
import AdvancedOptions from "../options/AdvancedOptions";
import FilePicker from "../file/FilePicker";
import { Remove } from "./styled/listing-form";
import { translate } from "../../utils/locale";

class ListingForm extends Component {
  constructor(props) {
    super(props);

    const landMeasurementKey =
      props.report.land_measurement === "acres" ? "acres" : "lotsize";
    const landMeasurementUnit =
      props.report.land_measurement === "acres" ? "acres" : "sqft";
    const state = {
      showAlert: false,
      mls: this.props.listing.data.mlsnum || "",
      address:
        this.props.listing.geo_address || this.props.listing.data.address || "",
      price: this.props.listing.data.price || "",
      remarks: this.props.listing.data.remarks || "",
      beds: { value: this.props.listing.data.beds || "", taxData: false },
      baths: { value: this.props.listing.data.baths || "", taxData: false },
      bathsFull: {
        value: this.props.listing.data.baths_full || "",
        taxData: false
      },
      bathsHalf: {
        value: this.props.listing.data.baths_half || "",
        taxData: false
      },
      sqft: { value: this.props.listing.data.sqft || "", taxData: false },
      priceSold: this.props.listing.data.price_sold || "",
      priceOriginal: this.props.listing.data.price_list_orig || "",
      dateList: this.props.listing.data.date_list
        ? new Date(this.props.listing.data.date_list)
        : null,
      dateSold: this.props.listing.data.date_sold
        ? new Date(this.props.listing.data.date_sold)
        : null,
      status: [
        { label: "Active", value: "active" },
        { label: "Holds", value: "hold" },
        { label: "Backup", value: "backup" },
        { label: "Pending", value: "pending" },
        { label: "Sold", value: "closed" },
        { label: "Expired", value: "expired" },
        { label: "Withdrawn", value: "withdrawn" },
        { label: "Canceled", value: "canceled" }
      ],
      remarks: this.props.listing.data.remarks || "",
      photos: [],
      advancedOptions: [
        {
          name: "listing[data][year_built]",
          label: "Year Blt.",
          value: this.props.listing.data.year_built || "",
          type: "number"
        },
        {
          name: `listing[data][${landMeasurementKey}]`,
          label: `Lot Size (${landMeasurementUnit})`,
          value: this.props.listing.data[landMeasurementKey] || "",
          type: "number"
        },
        {
          name: "listing[data][lotdim]",
          label: "Lot Dim.",
          value: this.props.listing.data.lotdim || ""
        },
        {
          name: "listing[data][taxes]",
          label: "Taxes",
          value: this.props.listing.data.taxes || "",
          type: "number"
        },
        {
          name: "listing[data][garages]",
          label: "Garages",
          value: this.props.listing.data.garages || "",
          type: "number"
        },
        {
          name: "listing[data][area]",
          label: "Area",
          value: this.props.listing.data.area || ""
        },
        {
          name: "listing[data][subdivision]",
          label: "Subdivison",
          value: this.props.listing.data.subdivision || ""
        },
        {
          name: "listing[data][style]",
          label: "Style",
          value: this.props.listing.data.style || ""
        }
      ],
      advancedOptionsOpen: false,
      timestamp: Date.now()
    };

    state.selectedStatus = state.status.find(
      ({ value }) => value === this.props.listing.data.status
    ) || {
      label: "Active",
      value: "active"
    };

    this.state = state;
  }

  render() {
    const {
      listings,
      report,
      request,
      isUpdating,
      onRemove,
      onSubmit,
      onClose
    } = this.props;
    const {
      showAlert,
      mls,
      address,
      price,
      beds,
      bathsFull,
      bathsHalf,
      sqft,
      status,
      selectedStatus,
      priceSold,
      priceOriginal,
      dateList,
      dateSold,
      photos,
      remarks,
      timestamp,
      advancedOptions,
      advancedOptionsOpen
    } = this.state;
    const { fetching, error = {} } = request;
    const hasError = error && !!Object.keys(error).length;
    const addFetching = listings.filter === "add" && fetching;
    const removeFetching = listings.filter === "remove" && fetching;
    const updateFetching = listings.filter === "update" && fetching;

    return (
      <>
        <form
          className={`h-full bg-white rounded-md p-8 ${
            isUpdating ? "" : "border border-grey-lighter"
          }`}
          onSubmit={onSubmit}>
          <input type="hidden" name="listing[data][source]" value="manual" />
          <h3 className="text-xl leading-2xl text-grey-darker font-normal">
            {!isUpdating && "Add a listing"}
            {isUpdating && "Update Listing"}
          </h3>
          <div className="flex flex-wrap -mx-6 pt-12 pb-10">
            {!isUpdating && (
              <>
                <div className="w-full px-6">
                  <h3 className="text-sbase leading-sxl font-normal text-grey-darker mb-6">
                    On the {translate("mls.label", "MLS")}
                  </h3>
                </div>
                <div className="w-full sm:w-1/2 tw-md:w-1/5 mb-6 px-6">
                  <Label htmlFor="mlsnum">
                    {translate("mls.label", "MLS")} Number
                  </Label>
                  <Input
                    name="listing[mlsnum]"
                    id="mlsnum"
                    value={mls || ""}
                    onChange={(e) => this.setState({ mls: e.target.value })}
                  />
                </div>
                <div className="w-full my-6 px-6">
                  <div className="relative border-t-1 border-grey-lighter">
                    <div className="w-14 h-14 flex items-center justify-center absolute pin-t text-sm leading-none uppercase bg-white border-1 border-grey-lighter rounded-full report-or-top">
                      or
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="w-full mt-6 px-6">
              <div className="flex flex-wrap -m-4">
                {!isUpdating && (
                  <div className="w-full px-4">
                    <h3 className="text-sbase leading-sxl font-normal text-grey-darker mb-6">
                      Off {translate("mls.label", "MLS")} Listing
                    </h3>
                  </div>
                )}
                <div className="w-full sm:w-3/5 p-4 tw-md:pt-0">
                  <AddressAutoComplete
                    key={timestamp}
                    id="address"
                    name="listing[data][address]"
                    address={address || ""}
                    onSelect={this.onAddressSelect}
                    onChange={(address) => this.setState({ address })}
                    autoFocus={true}
                  />
                </div>
                <div className="w-full" />
                <div className="w-1/2 sm:w-1/4 tw-md:w-1/5 p-4">
                  <Label htmlFor="beds">Beds</Label>
                  <Input
                    ref={(input) => (this.beds = input)}
                    name="listing[data][beds]"
                    id="beds"
                    value={beds.value || ""}
                    style={{ color: beds.taxData && colors.purple }}
                    onChange={(e) =>
                      this.setState({
                        beds: { value: e.target.value, taxData: false }
                      })
                    }
                  />
                </div>
                <div className="w-1/2 sm:w-1/4 tw-md:w-1/5 p-4">
                  <Label htmlFor="baths_full">Full Baths</Label>
                  <Input
                    name="listing[data][baths_full]"
                    id="baths_full"
                    value={bathsFull.value || ""}
                    style={{ color: bathsFull.taxData && colors.purple }}
                    onChange={(e) =>
                      this.setState({
                        bathsFull: { value: e.target.value, taxData: false }
                      })
                    }
                  />
                </div>
                <div className="w-1/2 sm:w-1/4 tw-md:w-1/5 p-4">
                  <Label htmlFor="baths_half">Half Baths</Label>
                  <Input
                    name="listing[data][baths_half]"
                    id="baths_half"
                    value={bathsHalf.value || ""}
                    style={{ color: bathsHalf.taxData && colors.purple }}
                    onChange={(e) =>
                      this.setState({
                        bathsHalf: { value: e.target.value, taxData: false }
                      })
                    }
                  />
                </div>
                {report.includes.sqft && (
                  <div className="w-1/2 sm:w-1/4 tw-md:w-1/5 p-4">
                    <Label htmlFor="sqft">Sq Ft</Label>
                    <Input
                      name="listing[data][sqft]"
                      id="sqft"
                      value={sqft.value || ""}
                      style={{ color: sqft.taxData && colors.purple }}
                      onChange={(e) =>
                        this.setState({
                          sqft: { value: e.target.value, taxData: false }
                        })
                      }
                    />
                  </div>
                )}
                <div className="w-1/2 sm:w-1/4 tw-md:w-1/5 p-4">
                  <Label htmlFor="status">Status</Label>
                  <Select
                    id="status"
                    name="listing[data][status]"
                    value={selectedStatus.value}
                    onChange={(e) =>
                      this.setState({
                        selectedStatus: {
                          label: e.target.dataset.label,
                          value: e.target.value
                        }
                      })
                    }>
                    {status.map((status) => (
                      <Option
                        key={status.value}
                        value={status.value}
                        data-label={status.label}>
                        {status.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="w-1/2 sm:w-1/4 tw-md:w-1/5 p-4">
                  <Label htmlFor="price">Price</Label>
                  <Input
                    name="listing[data][price_list]"
                    id="price"
                    value={price || ""}
                    onChange={(e) => this.setState({ price: e.target.value })}
                  />
                </div>
                <div className="w-1/2 sm:w-1/4 tw-md:w-1/5 p-4">
                  <Label htmlFor="price-original">Original Price</Label>
                  <Input
                    name="listing[data][price_list_orig]"
                    id="price-original"
                    value={priceOriginal || ""}
                    onChange={(e) =>
                      this.setState({ priceOriginal: e.target.value })
                    }
                  />
                </div>
                <div className="w-1/2 sm:w-1/4 tw-md:w-1/5 p-4">
                  <Label htmlFor="price-sold">Sold Price</Label>
                  <Input
                    name="listing[data][price_sold]"
                    id="price-sold"
                    value={priceSold || ""}
                    onChange={(e) =>
                      this.setState({ priceSold: e.target.value })
                    }
                  />
                </div>
                <div className="w-1/2 sm:w-1/4 tw-md:w-1/5 p-4">
                  <Label htmlFor="date-list">Date Listed</Label>
                  <Datepicker
                    name="listing[data][date_list]"
                    id="date-list"
                    value={dateList}
                    onChange={(value) => this.setState({ dateList: value })}
                  />
                </div>
                <div className="w-1/2 sm:w-1/4 tw-md:w-1/5 p-4">
                  <Label htmlFor="date-sold">Date Sold</Label>
                  <Datepicker
                    name="listing[data][date_sold]"
                    id="date-sold"
                    value={dateSold}
                    onChange={(value) => this.setState({ dateSold: value })}
                  />
                </div>
                <div className="w-full p-4">
                  <Label htmlFor="remarks">Description</Label>
                  <Input
                    as="textarea"
                    name="listing[data][remarks]"
                    id="remarks"
                    rows={5}
                    value={remarks}
                    onChange={(e) => this.setState({ remarks: e.target.value })}
                  />
                </div>
                <div className="w-full p-4">
                  <Label htmlFor="photos">Photos</Label>
                  <FilePicker
                    id="photos"
                    name="listing[photos][]"
                    showLabel={false}
                    files={[...photos].map((file) => file.name)}
                    accept={[
                      "image/png",
                      "image/x-png",
                      "image/gif",
                      "image/jpg",
                      "image/jpeg"
                    ]}
                    tip=""
                    maxSizeInMB={5}
                    multiple={true}
                    onChange={(photos) => this.setState({ photos })}
                  />
                </div>
                <div className="w-full p-4">
                  <AdvancedOptions
                    title="Advanced Info"
                    tip="Additional subject info used in four column layouts"
                    open={advancedOptionsOpen}
                    options={advancedOptions}
                    onToggle={this.onAdvancedOptionsToggle}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-end">
            {isUpdating && (
              <div className="w-full sm:w-auto sm:mr-auto flex-no-shrink">
                <Remove>
                  <Button
                    isFullWidth
                    app="attract"
                    type="button"
                    disabled={removeFetching}
                    onClick={() => this.setState({ showAlert: true })}>
                    {removeFetching && <Loading>Removing Listing</Loading>}
                    {!removeFetching && (
                      <>
                        <IconTrashCan />
                        Remove Listing
                      </>
                    )}
                  </Button>
                </Remove>
              </div>
            )}
            <div className="w-full sm:w-auto flex-no-shrink">
              <Button
                isFullWidth
                app="cma"
                variant="secondary"
                type="button"
                onClick={onClose}>
                Cancel
              </Button>
            </div>
            <div className="w-full flex-no-shrink sm:w-auto mt-4 sm:mt-0 ml-0 sm:ml-8">
              <Button
                isFullWidth
                app="cma"
                disabled={addFetching || updateFetching || (!address && !mls)}>
                {!isUpdating && (
                  <>
                    {addFetching && <Loading>Adding Listing</Loading>}
                    {!addFetching && "Add Listing"}
                  </>
                )}
                {isUpdating && (
                  <>
                    {updateFetching && <Loading>Updating Listing</Loading>}
                    {!updateFetching && "Update Listing"}
                  </>
                )}
              </Button>
            </div>
          </div>
          {hasError && (
            <div className="mt-4 text-right text-red">{error.message}</div>
          )}
        </form>
        {showAlert && (
          <AlertDanger
            title="Remove Listing"
            confirmButton="Delete Forever"
            onClose={() => this.setState({ showAlert: false })}
            onConfirm={onRemove}>
            Are you sure you want to remove listing: <strong>{address}</strong>?
          </AlertDanger>
        )}
      </>
    );
  }

  onAddressSelect = (_, taxData) => {
    const { sqft, beds, baths } = taxData;

    // Remap lotsize to lot_size
    if (taxData.lotsize) taxData.lot_size = taxData.lotsize;
    const hasTaxData = !!Object.keys(taxData).length;

    if (this.beds && !hasTaxData) this.beds.focus();

    const advancedOptions = this.state.advancedOptions.map((option) => {
      if (hasTaxData) {
        for (let o in taxData) {
          if (
            option.name === `listing[data][${o}]` &&
            !!taxData[o] &&
            taxData[o] !== "0"
          ) {
            option.value = taxData[o];
            option.style = { color: colors.purple };
          }
        }
      } else {
        option.value = "";
      }

      return option;
    });

    this.setState({
      beds: { value: beds || "", taxData: true },
      baths: { value: baths || "", taxData: true },
      sqft: { value: sqft || "", taxData: true },
      advancedOptions
    });
  };

  onAdvancedOptionsToggle = () => {
    this.setState(({ advancedOptionsOpen }) => ({
      advancedOptionsOpen: !advancedOptionsOpen
    }));
  };
}

ListingForm.propTypes = {
  user: PropTypes.shape({
    is_canadian: PropTypes.bool
  }),
  report: PropTypes.shape({
    id: PropTypes.number
  }),
  request: PropTypes.shape({
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    error: PropTypes.object
  }),
  listing: PropTypes.object,
  isUpdating: PropTypes.bool,
  addListing: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

ListingForm.defaultProps = {
  listing: {
    mls: "",
    data: {
      address: "",
      price: "",
      remarks: "",
      beds: "",
      baths: "",
      sqft: "",
      price_sold: "",
      price_list_orig: "",
      date_sold: "",
      date_list: "",
      status: "",
      year_built: "",
      lotsize: "",
      acres: "",
      lotdim: "",
      taxes: "",
      garages: "",
      area: "",
      subdivision: "",
      style: ""
    }
  }
};

const mapStateToProps = (state) => ({
  user: state.user,
  report: state.report,
  listings: state.listings,
  request: state.listings.request
});

export default connect(mapStateToProps)(ListingForm);
