
import styled, { css, keyframes } from "styled-components";

const spinKeyframes = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ full }) => (full ? "100%" : "auto")};
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  border-radius: 0.4rem;
  text-decoration: none;
  appearance: none;
  outline: none;
  position: relative;
  ${getButtonStyle};
  ${getButtonSize}

  &:hover:not([disabled]) {
    ${getHoverStyle};
  }

  &:focus:not([disabled]) {
    outline: none;
  }

  &:focus::after {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border-radius: 8px;
    z-index: 1;
    ${getFocusAfterStyle}
}

  &:active:not([disabled]) {
    ${getActiveStyle};
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const Spinner = styled.span`
  svg {
    width: 2rem;
    height: 2rem;
    animation: ${spinKeyframes} 0.75s linear infinite;
  }
`;

function getButtonStyle({ theme, variant, app }) {
  switch (variant) {
    default:
    case "primary":
      return css`
        background-color: ${app === "none"
          ? theme.styleGuide.colors.white
          : app === "cma" ? theme.styleGuide.colors.helix['brand'] : theme.styleGuide.colors[app][600]};
        color: ${app === "none"
          ? theme.styleGuide.colors.gray[900]
          : theme.styleGuide.colors.white};

        svg {
          fill: ${app === "none"
            ? theme.styleGuide.colors.gray[900]
            : theme.styleGuide.colors.white};
        }
      `;
    case "secondary":
      return css`
        box-shadow: none;
        background-color: transparent;
        color: ${app === "none"
          ? theme.styleGuide.colors.gray[900]
          : app === "cma" ? theme.styleGuide.colors.helix['brand'] : theme.styleGuide.colors[app][600]};

        svg {
          fill: ${theme.styleGuide.colors.gray[800]};
        }
      `;
    case "tertiary":
      return css`
        box-shadow: none;
        background-color: ${theme.styleGuide.colors.gray[300]};
        color: ${app === "none"
          ? theme.styleGuide.colors.gray[900]
          :  app === "cma" ? theme.styleGuide.colors.helix['brand'] : theme.styleGuide.colors[app][600]};

        svg {
          fill: ${app === "none"
            ? theme.styleGuide.colors.gray[900]
            :  app === "cma" ? theme.styleGuide.colors.helix['brand'] : theme.styleGuide.colors[app][600]};
        }
      `;
    case "danger":
      return css`
        box-shadow: none;
        background-color: ${app === "none"
            ? "#fee2e2"
            : "transparent"};
        color: ${app === "none"
          ? "#991B1B"
          : app === "cma" ? '#991B1B' : theme.styleGuide.colors[app][600]};
          
        svg {
          fill: #991B1B;
        }
      `;
  }
}

function getHoverStyle({ theme, variant, app }) {
  switch (variant) {
    default:
    case "primary":
      return css`
        background-color: ${app === "none"
          ? theme.styleGuide.colors.gray[300]
          : app === "cma" ? theme.styleGuide.colors.helix['light-green'] : theme.styleGuide.colors[app][700]};
      `;
    case "secondary":
      return css`
        background-color: ${app === "cma"
          ? '#F3F4F6' : "" };
        color: ${app === "none"
          ? theme.styleGuide.colors.gray[800]
          : app === "cma" ? theme.styleGuide.colors.helix['light-green'] : theme.styleGuide.colors[app][600]};
        border-color: ${app === "none"
          ? theme.styleGuide.colors.gray[800]
          : app === "cma" ? theme.styleGuide.colors.helix['light-green'] : theme.styleGuide.colors[app][700]};
      `;
    case "tertiary":
      return css`
        color: ${app === "none"
          ? theme.styleGuide.colors.gray[800]
          :  app === "cma" ? theme.styleGuide.colors.helix['light-green'] : theme.styleGuide.colors[app][600]};
      `;
    case "danger":
      return css`
        background-color: ${app === "none"
            ? "#fef2f2"
            : "inherit"};
        color: ${app === "none"
          ? "#7f1d1d"
          : app === "cma" ? "#7F1D1D" : theme.styleGuide.colors[app][600]};
      `;
  }
}

function getFocusAfterStyle({ theme, variant, app }) {
  switch (variant) {
    case "danger":
      return css`
        border: 2px solid ${theme.styleGuide.colors.helix['light-green']};
      `;
    default:
      return css`
        border: 2px solid ${theme.styleGuide.colors.helix['light-green']};
    `;
  }
}

function getActiveStyle({ theme, variant, app }) {
  switch (variant) {
    default:
    case "primary":
      return css`
        box-shadow: inset 0 0 0.2rem rgba(0, 0, 0, 0.2),
          inset 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
        ${app === "cma" ? `background-color: ${theme.styleGuide.colors.helix['light-green']}` : ''}
      `;
    case "secondary":
      return css`
        box-shadow: none;
        color: ${app === "none"
          ? theme.styleGuide.colors.gray[900]
          : app === "cma" ? theme.styleGuide.colors.helix['dark-green'] : theme.styleGuide.colors[app][700]};
        border-color: ${app === "none"
          ? theme.styleGuide.colors.gray[900]
          : app === "cma" ? theme.styleGuide.colors.helix['dark-green'] : theme.styleGuide.colors[app][600]};
      `;
    case "tertiary":
      return css`
        color: ${app === "none"
          ? theme.styleGuide.colors.gray[900]
          : app === "cma" ? theme.styleGuide.colors.helix['light-green'] : theme.styleGuide.colors[app][600]};
      `;
    case "danger":
      return css`
        background-color: "#fef2f2"
      `;
  }
}

function getButtonSize({ theme, size, variant }) {
  switch (size) {
    default:
    case "default":
      return css`
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2rem;
        padding: ${variant === "secondary" ? "0.7rem 1.5rem" : "0.8rem 1.6rem"};

        ${theme.media.sm`
          font-size: 1.5rem;
        `}
      `;
    case "small":
      return css`
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2rem;
        padding: ${variant === "secondary" ? "0.3rem 0.7rem" : "0.4rem 0.8rem"};

        ${theme.media.sm`
          font-size: 1.5rem;
        `}
      `;
    case "large":
      return css`
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.2rem;
        padding: ${variant === "secondary" ? "1.1rem 2.3rem" : "1.2rem 2.4rem"};

        ${theme.media.sm`
          font-size: 1.7rem;
        `};
      `;
  }
}
