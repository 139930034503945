import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";
import { getAddresses, getAddressDetails } from "../../api/location";
import Input from "../common/Input";
import {
  Container,
  Label,
  InputContainer,
  InputLoading,
  Menu,
  Item,
  Tip
} from "./styled/address-auto-complete";

class AddressAutoComplete extends Component {
  state = {
    addresses: [],
    address: this.props.address || "",
    mlsNumber: this.props.mls_num || "",
    lat: this.props.lat,
    lng: this.props.lng,
    city: this.props.city,
    throttleTiming: this.props.throttleTiming || 300,
    throttleCharCount: this.props.throttleCharCount || 7,
    throttle: false,
    hasSelected: false,
    fetching: false,
    fetched: false,
    fetchingTaxData: false,
    fetchedTaxData: false,
    fetchingTaxDataError: false,
    noResults: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // TODO: Break the list and item into presentational components.
  render() {
    const { id, name, autoFocus, label, placeholder, readOnly, onItemClick } =
      this.props;
    const {
      address,
      mlsNumber,
      addresses,
      lat,
      lng,
      city,
      fetched,
      fetching,
      fetchingTaxData,
      fetchedTaxData,
      fetchingTaxDataError
    } = this.state;
    const noResults = !addresses.length && fetched;

    return (
      <Fragment>
        <input type="hidden" name="subject_property[geo_lat]" value={lat} />
        <input type="hidden" name="subject_property[geo_lon]" value={lng} />
        <input type="hidden" name="subject_property[city]" value={city} />
        <input type="hidden" name={name} value={mlsNumber || address} />
        <input
          type="hidden"
          name="subject_property[geo_source]"
          value="mapbox"
        />
        <Downshift
          defaultHighlightedIndex={0}
          stateReducer={this.handleStateReducer}
          selectedItem={`${address}${mlsNumber ? ` (${mlsNumber})` : ""}`}>
          {({
            getRootProps,
            getInputProps,
            getItemProps,
            isOpen,
            highlightedIndex
          }) => (
            <Container {...getRootProps()}>
              <Label htmlFor={id}>
                <span>{label}</span>
                {fetchingTaxData && (
                  <Tip isLoading>
                    <i>Fetching Tax Data...</i>
                  </Tip>
                )}
                {!fetchingTaxData && fetchedTaxData && (
                  <Tip hasData>Tax data found</Tip>
                )}
                {!fetchingTaxData && fetchingTaxDataError && (
                  <Tip hasData={false}>No tax data. Input manually.</Tip>
                )}
              </Label>
              <InputContainer isFetching={fetching}>
                <Input
                  {...getInputProps({
                    id,
                    placeholder,
                    autoComplete: "off",
                    autoFocus,
                    readOnly,
                    className: "input"
                  })}
                />
                {fetching && <InputLoading text="" />}
              </InputContainer>
              {isOpen && !!addresses.length && (
                <Menu className="address-auto-complete-menu">
                  {addresses.map((item, index) => {
                    const isHighlighted =
                      highlightedIndex === index ||
                      (!highlightedIndex && index === 0);

                    return (
                      <Item
                        {...getItemProps({
                          item: item.value,
                          key: index,
                          index,
                          onClick: onItemClick,
                          isHighlighted,
                          className: isHighlighted ? "is-highlighted" : ""
                        })}
                        key={index}>
                        {item.value}
                        {item.mlsNumber && <strong> ({item.mlsNumber})</strong>}
                      </Item>
                    );
                  })}
                  {noResults && <Item as="div">No results</Item>}
                </Menu>
              )}
            </Container>
          )}
        </Downshift>
      </Fragment>
    );
  }

  onSelect = async (selectedAddress) => {
    const { fetchTaxData } = this.props;
    const address = this.state.addresses.find(
      (address) => address.value === selectedAddress
    );
    let taxData = {};

    const [lng, lat] = address.coordinates;
    this.setState({
      address: address.value,
      mlsNumber: address.mlsNumber,
      lat,
      lng
    });

    if (fetchTaxData) {
      try {
        this.setState({
          fetchingTaxData: true,
          fetchedTaxData: false,
          fetchingTaxDataError: false
        });

        taxData = await getAddressDetails(
          address.value,
          window.sessionData.currentUser.guid
        );
        const city = taxData.parsed_address.city;

        this.setState({ fetchingTaxData: false, fetchedTaxData: true, city });
      } catch (e) {
        this.setState({
          fetchingTaxData: false,
          fetchedTaxData: false,
          fetchingTaxDataError: true
        });
      }
    }

    this.props.onSelect(address, taxData);
  };

  onInputValueChange = (address) => {
    if (
      (address !== this.state.address &&
        address.length <= this.state.throttleCharCount) ||
      !window.sessionData.features.hasAddressAutocomplete
    ) {
      return this.setState({ address, mlsNumber: "", addresses: [] }, () =>
        this.props.onChange(address)
      );
    }

    if (
      address !== this.state.address &&
      address.length >= this.state.throttleCharCount &&
      this._isMounted
    ) {
      this.setState({ address, mlsNumber: "" }, () => {
        this.getAddresses();
        this.props.onChange(address);
      });
    }
  };

  getAddresses = async () => {
    const { useForge } = this.props;
    const { address, throttleTiming } = this.state;

    clearTimeout(this.timer);

    this.timer = setTimeout(async () => {
      this.setState({ fetching: true, fetched: false });

      try {
        const addresses = await getAddresses({
          address,
          guid: window.sessionData.currentUser.guid,
          mlsCode: window.sessionData.mls.code,
          useForge
        });

        if (this._isMounted) {
          this.setState({ addresses, fetched: true, fetching: false });
        }
      } catch (error) {
        if (this._isMounted) {
          this.setState({ fetched: false, fetching: false });
        }
      }
    }, throttleTiming);
  };

  handleStateReducer = (_, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        this.onSelect(changes.selectedItem);
        return changes;
      case Downshift.stateChangeTypes.changeInput:
        this.onInputValueChange(changes.inputValue);
        return changes;
      default:
        return changes;
    }
  };
}

AddressAutoComplete.defaultProps = {
  label: "Address",
  placeholder: "Enter your property address",
  autoFocus: false,
  readOnly: false,
  addresses: [],
  lat: "",
  lng: "",
  city: "",
  fetchData: true,
  fetchTaxData: true,
  useForge: false,
  onChange: function () {},
  onSelect: function () {}
};

AddressAutoComplete.propTypes = {
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  address: PropTypes.string,
  addresses: PropTypes.arrayOf(PropTypes.object),
  lat: PropTypes.string,
  lng: PropTypes.string,
  city: PropTypes.string,
  throttleTiming: PropTypes.number,
  fetchData: PropTypes.bool,
  fetchTaxData: PropTypes.bool,
  useForge: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onItemClick: PropTypes.func
};

export default AddressAutoComplete;
