import styled from "styled-components";

export const Badge = styled.span`
  display: inline-flex;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.25;
  margin-left: auto;
  padding: 0.1rem 0.3rem;
  text-transform: uppercase;
  user-select: none;
  border-radius: 0.2rem;
  background-color: ${({ theme, isNew }) =>
    isNew
      ? theme.styleGuide.colors.purple[500]
      : theme.styleGuide.colors.helix['brand']};
  color: ${({ theme }) => theme.styleGuide.colors.white};

  svg {
    width: 1.2rem;
    height: 1.2rem;
    flex-shrink: 0;
    margin-right: 0.1rem;
  }
`;
