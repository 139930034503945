import styled from "styled-components";

export const Wrapper = styled.div``;

export const Header = styled.h2`
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.3rem;
  margin: 0 0 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const LWButton = styled.a`
  border-radius: 0.6rem;
  padding: 0.8rem 1.6rem;
  border: none;
  height: 3.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
  box-sizing: border-box;
  display: block;
  white-space: nowrap;
  vertical-align: middle;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  width: 100%;
  text-align: center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  background-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  color: ${({ theme }) => theme.styleGuide.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.styleGuide.colors.helix['light-green']};
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[400]};
  margin: 3rem 0;
`;
