import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
  width: ${({ isFullWidth }) => isFullWidth && "100%"};
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  position: relative;

  svg {
    width: 2rem;
    height: 2rem;
    margin-top: 0.1rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[800]};
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: 0.8rem;
    z-index: 0;
  }
`;

export const StyledSelect = styled.select`
  display: block;
  width: 100%;
  font-size: 1.4rem;
  line-height: 2rem;
  min-width: 0;
  padding: 0.7rem 3rem 0.7rem 1.2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
  background-color: transparent;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  text-overflow: ellipsis;
  appearance: none;
  outline: none;
  position: relative;
  z-index: 1;

  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    outline: 1px solid ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  }
`;
