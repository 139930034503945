import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: -3.8rem;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.media.sm`
    margin-right: -3.2rem;
  `}
`;

export const Link = styled.a`
  display: inline-block;
  margin-right: 1.8rem;

  ${({ theme }) => theme.media.sm`
    margin-right: 1.2rem;
  `}

  svg {
    width: 3rem;
    height: 3rem;

    fill: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  }
`;
