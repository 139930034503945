import { Modal } from "@wrstudios/components";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { createContact } from "../../api/contacts";
import { useSession } from "../../Session";
import Flash from "../common/Flash";
import Input from "../common/Input";
import Label from "../common/Label";
import Loading from "../common/Loading";
import { Option, Select } from "../common/Select";
import {
  Action,
  Actions,
  Button,
  FieldError,
  FlashError,
  Form,
  InputContainer,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader
} from "./styled/contact-form";

function ContactForm({ onSave, onClose }) {
  const { currentUser, setCurrentUser } = useSession();

  const [error, setError] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [savingContact, setSavingContact] = useState(false);

  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: {
      number: "",
      phone_type_id: phone_types[2].value
    },
    address: {
      postal_code: "",
      country: "",
      state: "",
      city: "",
      street_address_1: ""
    }
  });

  const setErrors = (responseErrors) => {
    if (Array.isArray(responseErrors)) {
      const errors = {};

      responseErrors.forEach((error) => {
        const match = error.match(/(\w+(?:_\w+)*)/);
        if (match) {
          const fieldName = match[0];
          errors[fieldName.toLowerCase()] = error;
        } else {
          errors["general"] = (errors["general"] || []).concat(error);
        }
      });

      setFieldErrors(errors);
    }
  };

  const handleFieldChange = (fieldName, value) => {
    setFormValues({
      ...formValues,
      [fieldName]: value
    });

    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (fieldName === "email" || fieldName === "phone_number") {
        updatedErrors.phone_number = undefined;
        updatedErrors.email = undefined;
      } else {
        updatedErrors[fieldName] = undefined;
      }

      return updatedErrors;
    });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formValues.first_name.trim()) {
      errors.first_name = "First name is required.";
      isValid = false;
    }

    if (!formValues.last_name.trim()) {
      errors.last_name = "Last name is required.";
      isValid = false;
    }

    if (!formValues.email.trim() && !formValues.phone_number.number.trim()) {
      errors.email = "Email is required.";
      errors.phone_number = "Phone is required.";
      isValid = false;
    } else if (
      formValues.email.trim() &&
      !/\S+@\S+\.\S+/.test(formValues.email)
    ) {
      errors.email = "Please enter a valid email address.";
      isValid = false;
    }

    if (!formValues.phone_number.number.trim() && !formValues.email.trim()) {
      errors.email = "Email is required.";
      errors.phone_number = "Phone is required.";
      isValid = false;
    } else if (
      formValues.phone_number.number.trim() &&
      /[^0-9+]/.test(formValues.phone_number.number)
    ) {
      errors.phone_number = "Please enter a valid phone number.";
      isValid = false;
    }

    const { street_address_1, postal_code, country, state, city } =
      formValues.address;
    if (street_address_1.trim()) {
      if (!postal_code.trim()) {
        errors.postal_code = "Postal code is required.";
        isValid = false;
      }
      if (!country.trim()) {
        errors.country = "Country is required.";
        isValid = false;
      }
      if (!state.trim()) {
        errors.state = "State is required.";
        isValid = false;
      }
      if (!city.trim()) {
        errors.city = "City is required.";
        isValid = false;
      }
    }

    setFieldErrors(errors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setError("");
      setFieldErrors({});
      setSavingContact(true);

      const response = await createContact(formValues);
      const contact = response.contact;
      const newContact = {
        id: contact.id,
        name: `${contact.firstName} ${contact.lastName}`,
        email: !!contact.emails.length ? contact.emails[0].email : "",
        phone_number: !!contact.phoneNumbers.length
          ? contact.phoneNumbers[0].number
          : null,
        address: contact.addresses.length
          ? contact.addresses[0].normalizedAddress
          : null,
        categories: contact.categories || []
      };

      setCurrentUser({
        ...currentUser,
        contacts: [...currentUser.contacts, newContact]
      });

      onSave(newContact);
    } catch (error) {
      if (Array.isArray(error.errors)) {
        setErrors(error.errors);
      } else {
        setError(error.errors);
      }
      setSavingContact(false);
    }
  };

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          New Contact
          <Modal.Close onClick={onClose} />
        </ModalHeader>
        <ModalBody>
          <Form>
            <div className="flex w-full">
              <div className="mr-8 flex-1">
                <Label htmlFor="first_name">
                  <span>First Name</span>
                </Label>
                <InputContainer>
                  <Input
                    id="first_name"
                    data-autofocus
                    isFullWidth
                    value={formValues.first_name}
                    onChange={(e) =>
                      handleFieldChange("first_name", e.target.value)
                    }
                  />
                </InputContainer>
                {fieldErrors.first_name && (
                  <FieldError>{fieldErrors.first_name}</FieldError>
                )}
              </div>
              <div className="flex-1">
                <Label htmlFor="last_name">
                  <span>Last Name</span>
                </Label>
                <InputContainer>
                  <Input
                    id="last_name"
                    isFullWidth
                    value={formValues.last_name}
                    onChange={(e) =>
                      handleFieldChange("last_name", e.target.value)
                    }
                  />
                </InputContainer>
                {fieldErrors.last_name && (
                  <FieldError>{fieldErrors.last_name}</FieldError>
                )}
              </div>
            </div>
            <div
              className="flex w-2/3 flex-col pr-20"
              style={{ gap: "1.2rem" }}>
              <div className="flex-1">
                <Label htmlFor="email">
                  <span>Email</span>
                </Label>
                <InputContainer>
                  <Input
                    type="email"
                    id="email"
                    isFullWidth
                    value={formValues.email}
                    onChange={(e) => handleFieldChange("email", e.target.value)}
                  />
                </InputContainer>
                {fieldErrors.email && (
                  <FieldError>{fieldErrors.email}</FieldError>
                )}
              </div>
              <div className="flex w-full">
                <div className="w-s36 mr-5">
                  <Label htmlFor="phone_type">
                    <span>Phone Type</span>
                  </Label>
                  <Select
                    id="phone_type"
                    name="phone_type"
                    value={formValues.phone_number.phone_type_id}
                    onChange={(e) =>
                      handleFieldChange("phone_number", {
                        ...formValues.phone_number,
                        phone_type_id: e.target.value
                      })
                    }>
                    <Option value="">None</Option>
                    {phone_types.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="flex-1">
                  <Label htmlFor="phone">
                    <span>Phone Number</span>
                  </Label>
                  <InputContainer>
                    <Input
                      type="tel"
                      id="phone"
                      isFullWidth
                      value={formValues.phone_number.number}
                      onChange={(e) =>
                        handleFieldChange("phone_number", {
                          ...formValues.phone_number,
                          number: e.target.value
                        })
                      }
                    />
                  </InputContainer>
                  {fieldErrors.phone_number && (
                    <FieldError>{fieldErrors.phone_number}</FieldError>
                  )}
                </div>
              </div>
            </div>
            <div className="flex-1">
              <Label htmlFor="street_address">
                <span>Street Address</span>
              </Label>
              <InputContainer>
                <Input
                  type="text"
                  id="street_address"
                  isFullWidth
                  defaultValue={formValues.address.street_address_1}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      address: {
                        ...formValues.address,
                        street_address_1: e.target.value
                      }
                    });
                  }}
                />
              </InputContainer>
              {fieldErrors.street_address_1 &&
                !formValues.address.street_address_1 && (
                  <FieldError>{fieldErrors.street_address_1}</FieldError>
                )}
            </div>
            <div className="flex w-full">
              <div className="mr-4 flex-1">
                <Label htmlFor="city">
                  <span>City</span>
                </Label>
                <InputContainer>
                  <Input
                    id="city"
                    autoFocus
                    isFullWidth
                    defaultValue={formValues.address.city}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        address: {
                          ...formValues.address,
                          city: e.target.value
                        }
                      });
                    }}
                  />
                </InputContainer>
                {fieldErrors.city && !formValues.address.city && (
                  <FieldError>{fieldErrors.city}</FieldError>
                )}
              </div>
              <div className="mr-4 flex-1">
                <Label htmlFor="state">
                  <span>State / Province</span>
                </Label>
                <InputContainer>
                  <Input
                    id="state"
                    isFullWidth
                    defaultValue={formValues.address.state}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        address: {
                          ...formValues.address,
                          state: e.target.value
                        }
                      });
                    }}
                  />
                </InputContainer>
                {fieldErrors.state && !formValues.address.state && (
                  <FieldError>{fieldErrors.state}</FieldError>
                )}
              </div>
              <div className="flex-1">
                <Label htmlFor="zipcode">
                  <span>ZIP / Postal Code</span>
                </Label>
                <InputContainer>
                  <Input
                    id="zipcode"
                    isFullWidth
                    defaultValue={formValues.address.postal_code}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        address: {
                          ...formValues.address,
                          postal_code: e.target.value
                        }
                      });
                    }}
                  />
                </InputContainer>
                {fieldErrors.postal_code && !formValues.address.postal_code && (
                  <FieldError>{fieldErrors.postal_code}</FieldError>
                )}
              </div>
            </div>
            <div className="flex w-full">
              <div className="w-1/3 pr-5">
                <Label htmlFor="country">
                  <span>Country</span>
                </Label>
                <Select
                  defaultValue={formValues.address.country}
                  id="country"
                  name="country"
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      address: {
                        ...formValues.address,
                        country: e.target.value
                      }
                    });
                  }}>
                  <Option value="">None</Option>
                  {countries.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                {fieldErrors.country && !formValues.address.country && (
                  <FieldError>{fieldErrors.country}</FieldError>
                )}
              </div>
            </div>
          </Form>
          {error && (
            <FlashError>
              <Flash variant="error">{error}</Flash>
            </FlashError>
          )}
        </ModalBody>
        <ModalFooter>
          <Actions>
            <Action>
              <Button variant="tertiary" onClick={onClose}>
                Cancel
              </Button>
            </Action>
            <Action>
              <Button app="cma" disabled={savingContact} onClick={handleSubmit}>
                {savingContact ? <Loading showSpinner>Saving</Loading> : "Save"}
              </Button>
            </Action>
          </Actions>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ContactForm.defaultProps = {
  onSave: () => {},
  onClose: () => {}
};

ContactForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ContactForm;

const phone_types = [
  {
    label: "Home",
    value: "1"
  },
  {
    label: "Work",
    value: "2"
  },
  {
    label: "Mobile",
    value: "3"
  },
  {
    label: "Fax",
    value: "4"
  }
];

const countries = [
  {
    label: "Canada",
    value: "CA"
  },
  {
    label: "United States",
    value: "US"
  }
];
