import styled from "styled-components";
import { default as BaseLoading } from "../Loading";

export const Container = styled.div`
  position: relative;
`;

export const Details = styled.div`
  width: 100%;
  min-width: 0;
`;

export const Image = styled.div`
  flex-shrink: 0;
  margin-right: 2.4rem;

  svg,
  img {
    width: 9.6rem;
    height: 12.4rem;
  }

  img {
    display: block;
    object-fit: cover;
    border-radius: 0.2rem;
  }
`;

export const Title = styled.h2`
  display: block;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  margin: 0.8rem 0;
  padding-right: 1rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.media.sm`
    font-size: 1.7rem;
    line-height: 2.4rem;
  `}
`;

export const Text = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Notes = styled.div`
  width: 100%;
  font-size: 1rem;
  line-height: 1.7rem;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  img,
  svg {
    flex-shrink: 0;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.5rem;
  }
`;

export const Link = styled.a`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  text-decoration: none;
  white-space: nowrap;

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin: 0.8rem -0.5rem 0;
`;

export const Action = styled.div`
  display: flex;
  padding: 0 0.5rem;
`;

export const Loading = styled(BaseLoading)`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}

  svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`;
