import styled from "styled-components";
import { default as BaseLabel } from "../../common/Label";
import { default as BaseLoading } from "../../common/Loading";

export const Container = styled.div`
  position: relative;
`;

export const Label = styled(BaseLabel)`
  display: flex;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  position: relative;

  input {
    padding-right: ${({ isFetching }) => isFetching && "3.2rem"};
  }
`;

export const InputLoading = styled(BaseLoading)`
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 0.8rem;
`;

export const Menu = styled.div`
  width: 100%;
  max-height: 19.4rem;
  line-height: 2rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  overflow: auto;
  position: absolute;
  z-index: 3;
`;

export const Item = styled.button`
  display: block;
  width: 100%;
  padding: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  color: ${({ theme, isHighlighted }) =>
    isHighlighted && theme.styleGuide.colors.helix['brand']}};
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted && theme.styleGuide.colors.gray[300]};
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const Tip = styled.small`
  margin-left: 0.5rem;
  color: ${({ theme, isLoading, hasData }) =>
    isLoading
      ? theme.styleGuide.colors.gray[600]
      : !hasData
      ? theme.styleGuide.colors.gray[600]
      : theme.styleGuide.colors.purple[500]};
`;
