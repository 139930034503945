import styled, { css } from "styled-components";
import { Truncate } from "../../../styles/Text";

export const Overflow = styled.div`
  height: 0;
  overflow: hidden;
`;

export const Button = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.7rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  border: 0;
  background-color: transparent;
  appearance: none;

  &:focus,
  &:focus-within {
    outline: auto 0.1rem ${({ theme }) => theme.styleGuide.colors.gray[500]};
  }

  &[disabled] {
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }
`;

export const Value = styled(Truncate)`
  font-size: 1.5rem;
  margin-right: 0.5rem;
`;

export const Icon = styled.span`
  margin-left: auto;
  line-height: 0;

  svg {
    width: 2rem;
    height: 2rem;
    margin-top: 0.1rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  }
`;

export const Colors = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 0.7rem;
`;

export const Color = styled.span`
  width: 1.4rem;
  height: 1.4rem;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  position: relative;
  z-index: 2;

  &:last-child {
    margin-left: -0.3rem;
    z-index: 1;
  }
`;

export const Dropdown = styled.div`
  min-width: 100%;
  max-height: 24vh;
  margin-top: 0.75rem;
  overflow: auto;
  color: ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.1rem solid ${({ theme }) => theme.colors.grayLighter};
  box-shadow: ${({ theme }) => theme.shadows.md};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  appearance: none;
  position: absolute;
  z-index: 50;
`;

export const Option = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 100%;
  font-size: 1.3rem;
  line-height: 1.8rem;
  padding: 0.6rem 1.2rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  border: 0;
  background: transparent;
  cursor: pointer;
  white-space: nowrap;
  appearance: none;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.highlight};
    outline: none;
  }

  ${Color} {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

export const Group = styled.div``;

export const GroupHeader = styled.div`
  min-width: 100%;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.8rem;
  padding: 0.6rem 1.2rem;
  cursor: default;

  ~ ${Option} {
    padding-left: 2.4rem;
  }
`;

export const Wrapper = styled.div`
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
  border-radius: 0.4rem;
`;

export const Select = styled.div`
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  position: relative;

  ${({ open }) =>
    open &&
    css`
      ${Button} {
        outline: auto 0.5rem ${({ theme }) => theme.styleGuide.colors.helix['brand']};
      }
    `};

  ${({ small }) =>
    small &&
    css`
      ${Button} {
        font-size: 1.3rem;
        line-height: 1.1;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
      }
    `};

  ${({ skinny }) =>
    skinny &&
    css`
      margin-left: 1rem;
      margin-right: 1rem;
      background: none;

      ${Icon} {
        margin-left: 0;
        color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
        transform: translateY(-50%);

        position: absolute;
        top: 50%;
        right: 0.4rem;
      }

      ${Dropdown} {
        margin: 0;
        padding-right: 3.5rem;
        color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
        background-color: transparent;
        border-bottom: 0.1rem dashed ${({ theme }) => theme.colors.grayLighter};
        border-radius: 0;
        box-shadow: none;
        position: relative;
        z-index: 10;

        &::-ms-expand {
          display: none;
        }
      }
    `};

  ${Wrapper} {
    ${({ open }) =>
      open &&
      css`
        background-color: ${({ theme }) => theme.colors.grayLighter};
        border-color: ${({ theme }) => theme.colors.grayLighter};
      `};
  }
`;
