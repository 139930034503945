import styled from "styled-components";
import { Menu as BaseMenu } from "@wrstudios/components";
import { Card as BaseCard, CardBody as BaseCardBody } from "../Card";
import { default as BaseLoading } from "../Loading";

export const Container = styled.div`
  position: relative;
`;

export const Details = styled.div`
  width: 100%;
  min-width: 0;
`;

export const Image = styled.div`
  flex-shrink: 0;
  margin-right: 1.5rem;

  svg,
  img {
    width: 6.8rem;
    height: 8.8rem;
  }

  img {
    display: block;
    object-fit: cover;
    border-radius: 0.2rem;
  }
`;

export const TitleWithMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.h2`
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin: 0;
  padding-right: 1rem;
  color: ${({ theme }) => theme.colors.grayDark};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Text = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  height: 2rem;
  color: ${({ theme }) => theme.colors.grayDark};

  svg {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin: -1.5rem 0.9rem 0;
    color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  }
`;

export const TextLine = styled.span`
  display: inline-block;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Notes = styled.div`
  width: 100%;
  font-size: 1rem;
  line-height: 1.7rem;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.grayAlt};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  img,
  svg {
    flex-shrink: 0;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.5rem;
  }
`;

export const Link = styled.a`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  text-decoration: none;
  white-space: nowrap;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const DeleteOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.4rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const StatsContainer = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  ${({ isOpen }) => isOpen && `display: flex;`}
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -0.5rem;
`;

export const Action = styled.div`
  display: flex;
  padding: 0 0.5rem;
`;

export const ViewCountAction = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  margin-left: auto;
  margin-right: 1.2rem;
  cursor: pointer;

  svg {
    margin-right: 0.6rem;
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  }
`;

export const BasicAction = styled(Action)`
  &:not(:first-child):last-child {
    margin-left: 0;
  }
`;

export const Verb = styled.span`
  ${({ theme }) => theme.media.xl`
    display: none;
  `};

  ${({ theme }) => theme.media.lg`
    display: inline;
  `};

  ${({ theme }) => theme.media.md`
    display: none;
  `};

  ${({ theme }) => theme.media.sm`
    display: inline;
  `};

  @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: inline;
  }

  @media screen and (device-aspect-ratio: 40/71) and (orientation: portrait) {
    display: none;
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
    display: none;
  }

  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: portrait) {
    display: inline;
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    display: inline;
  }
`;

export const MenuButton = styled(BaseMenu.Button)`
  position: relative;
  z-index: 1;

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const MenuList = styled(BaseMenu.List)`
  &.bottom {
    transform: translateX(-100%);
    bottom: auto;
    top: -1.3rem;
    left: -1.2rem;
    right: auto;
  }
`;

export const MenuItem = styled(BaseMenu.Item)`
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.styleGuide.colors.white};
    position: absolute;
    right: 0;
    z-index: 1;
  }

  &:after {
    content: "";
    width: 1.2rem;
    height: 1.2rem;
    background-color: ${({ theme }) => theme.styleGuide.colors.white};
    box-shadow: ${({ theme }) => theme.styleGuide.shadows.overlay};
    transform: translateY(-50%) rotate(45deg);
    position: absolute;
    right: -0.6rem;
    top: 50%;
    z-index: 0;
  }

  &:not(:disabled).focused,
  &:not(:disabled):hover,
  &:not(:disabled):active {
    &:before,
    &:after {
      background-color: ${({ theme }) => theme.styleGuide.colors.gray[300]};
    }
  }
`;

export const MenuItemDanger = styled(BaseMenu.Item)`
  color: ${({ theme }) => theme.styleGuide.colors.red[500]};

  &:not(:disabled).focused,
  &:not(:disabled):hover,
  &:not(:disabled):active {
    color: ${({ theme }) => theme.styleGuide.colors.white};
    background-color: ${({ theme }) => theme.styleGuide.colors.red[500]};
  }
`;

export const Card = styled(BaseCard)`
  height: 15rem;
`;

export const CardBody = styled(BaseCardBody)`
  position: relative;
`;

export const Loading = styled(BaseLoading)`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.grayAlt};

  svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`;

export const CopyContainer = styled.span`
  display: block;
  margin: 0 -1.6rem;
  padding: 0 1.6rem;
  position: relative;
  z-index: 2;
`;

export const TransactionLink = styled.a`
  text-decoration: none;
`;

export const IconTransactionContainer = styled.span`
  cursor: pointer;
  svg > path {
    fill: ${({ theme, active }) =>
      active
        ? theme.styleGuide.colors.helix['brand']
        : theme.styleGuide.colors.gray[600]};
  }
`;
