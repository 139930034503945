import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { hasClass } from "../../utils/page";
import CustomizeInactiveFolder from "./CustomizeInactiveFolder";
import { Badge } from "./styled/customize-inactive-folders";
import { useSession } from "../../Session";

function CustomizeInactiveFolders({
  reportId,
  reportType,
  pages: _pages,
  marketingPages,
  luxPages,
  tomPages,
  isDocument,
  sections,
  accountName,
  accountPages: _accountPages,
  offerMarketingPages,
  offerLuxPages,
  offerTomPages,
  showMarketingPages,
  showLuxPages,
  showTomPages,
  resetPages,
  showIllustrationControl
}) {
  // Custom pages use the url as keys
  const accountPageKeys = _accountPages.map((page) => page.url);
  const accountPages = (
    _pages.filter((page) => page.key === "user_custom_pages")[0]?.children || []
  )
    .filter((page) => accountPageKeys.includes(page.key))
    .map((page) => ({ ...page, children: [] }));

  const pages = _pages
    .map((page) => {
      if (page.key === "user_custom_pages") {
        return {
          ...page,
          children: page.children.filter(
            (child) => !accountPageKeys.includes(child.key)
          )
        };
      }
      return page;
    })
    .filter((page) => {
      if (page.key === "user_custom_pages") {
        return page.children.length;
      }
      return true;
    });

  const { features } = useSession();
  const isMobile = hasClass("body", ["mobile", "tablet"]);
  const rdcContainer =
    features.offerRdcToolkit || features.hasRdcToolkit
      ? pages.filter((p) => p.key.includes("Realtor.com"))
      : [];
  const rdcPages = (rdcContainer[0]?.children || []).map((page) => ({
    ...page,
    children: []
  }));

  const filteredPages = pages.filter((p) => !p.key.includes("Realtor.com"));

  return (
    <Fragment>
      <CustomizeInactiveFolder title="Standard" pages={filteredPages} open />
      {!!accountPages.length && (
        <CustomizeInactiveFolder title={accountName} pages={accountPages} />
      )}
      {!isDocument && (
        <>
          {(!!rdcContainer.length ||
            showMarketingPages ||
            showTomPages ||
            showLuxPages) && <Badge>Premium</Badge>}
          {!!rdcContainer.length && (
            <CustomizeInactiveFolder
              title="Realtor.com® Listing Toolkit"
              isNew
              pages={rdcPages}
              open
            />
          )}
          {sections.map((section, index) => {
            return (
              <Fragment key={index}>
                {showMarketingPages && section === "power_pack" && (
                  <CustomizeInactiveFolder
                    title="Classic Power Pack Pages"
                    pages={marketingPages}
                    offer={offerMarketingPages}
                    open={showMarketingPages && !isMobile}
                    showIllustrationControl={showIllustrationControl}
                  />
                )}
                {showTomPages && section === "tom" && (
                  <CustomizeInactiveFolder
                    title="Tom Ferry Pages"
                    pages={tomPages}
                    offer={offerTomPages}
                    open={showTomPages && !isMobile}
                  />
                )}
                {showLuxPages && section === "lux" && (
                  <CustomizeInactiveFolder
                    title="Power Pack Pages"
                    pages={luxPages}
                    offer={offerLuxPages}
                    open={showLuxPages && !isMobile}
                    showIllustrationControl={showIllustrationControl}
                  />
                )}
              </Fragment>
            );
          })}
        </>
      )}
      <div className="mt-8">
        <button
          type="button"
          className="text-sm text-helix-brand"
          onClick={() => resetPages(reportId, reportType)}>
          Reset to default theme pages
        </button>
      </div>
    </Fragment>
  );
}

CustomizeInactiveFolders.propTypes = {
  reportId: PropTypes.number.isRequired,
  reportType: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  marketingPages: PropTypes.arrayOf(PropTypes.object).isRequired,
  luxPages: PropTypes.arrayOf(PropTypes.object).isRequired,
  tomPages: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDocument: PropTypes.bool.isRequired,
  sections: PropTypes.arrayOf(PropTypes.string).isRequired,
  offerMarketingPages: PropTypes.bool.isRequired,
  offerLuxPages: PropTypes.bool.isRequired,
  offerTomPages: PropTypes.bool.isRequired,
  showMarketingPages: PropTypes.bool.isRequired,
  showLuxPages: PropTypes.bool.isRequired,
  showTomPages: PropTypes.bool.isRequired,
  resetPages: PropTypes.func.isRequired,
  showIllustrationControl: PropTypes.bool.isRequired
};

export default CustomizeInactiveFolders;
