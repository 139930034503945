import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "@wrstudios/components";
import { updateDescription } from "../../actions/customize";
import Input from "../common/Input";
import Button from "../common/Button";
import {
  ModalContent,
  ModalFooter
} from "./styled/customize-modal-description";

function CustomizeModalDescription({
  reportId,
  description,
  updateDescription,
  onClose
}) {
  const [value, setValue] = useState(description.value || "");

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <Modal.Header>
          Description <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Input
            as="textarea"
            rows={10}
            value={value}
            autoFocus
            onChange={(e) => setValue(e.target.value)}
            className="input my-1"
          />
        </Modal.Body>
        <ModalFooter>
          <Button
            app="cma"
            disabled={description.fetching || !value}
            onClick={() => {
              updateDescription(reportId, value);
              onClose();
            }}>
            Update Description
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

CustomizeModalDescription.propTypes = {
  reportId: PropTypes.number.isRequired,
  description: PropTypes.shape({
    value: PropTypes.string,
    fetching: PropTypes.bool
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  updateDescription: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  reportId: state.report.id,
  description: state.customize.description
});

const mapDispatchToProps = {
  updateDescription
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizeModalDescription);
