import styled from "styled-components";
import BaseButton from "../../common/Button";

const opendoorBlue = "#1c85e8";

export const Subheader = styled.div`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.6rem;
  margin-bottom: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
    line-height: 1.8rem;
  `}
`;

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.6rem;
  margin-bottom: 1.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.6rem;
    line-height: 2rem;
  `}
`;

export const Emphasis = styled.span`
  color: ${opendoorBlue};
`;

export const EstimateTitle = styled(Title)`
  margin-bottom: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-bottom: ${({ small }) => (small ? 1 : 2.4)}rem;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  }
`;

export const Grid = styled.div`
  display: flex;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
    width: 100%;
    
    padding-top: 0;
  `}
`;

export const Cell = styled.div`
  ${({ theme }) => theme.media.sm`
    width: 100%;
  `}
`;

export const ImageCell = styled.div`
  flex-shrink: 0;
  margin-left: 3.5rem;

  ${({ theme }) => theme.media.sm`
    display: none;
  `}

  img {
    width: 27.1rem;
    height: 35.1rem;
    object-fit: cover;
    background-color: ${({ theme }) => theme.styleGuide.colors.gray[300]};

    ${({ theme }) => theme.media.xl`
      width: 15rem;
      height: auto;
    `}
  }
`;

export const PointsHeader = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin: 2rem 0 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Points = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Point = styled.li`
  display: flex;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 1.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    flex-shrink: 0;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.6rem;
    fill: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  }
`;

export const Estimate = styled.h2`
  margin-bottom: 1.6rem;
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 4.4rem;
  color: ${({ theme }) => theme.colors.grayDark};
`;

export const CommissionText = styled.div`
  margin-bottom: 1.6rem;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.colors.grayDark};
`;

export const CommissionRange = styled.div`
  margin-bottom: 1.6rem;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  color: ${opendoorBlue};
`;

export const DetailsTitle = styled.strong`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const DetailsText = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const Learn = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray};

  svg {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-top: -0.2rem;
    fill: ${({ theme }) => theme.colors.gray};
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
`;

export const StatusTitle = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3.2rem;
  margin-bottom: 1.2rem;
  color: ${({ theme }) => theme.colors.grayDark};

  ${({ theme }) => theme.media.sm`
    text-align: center;
  `}
`;

export const Status = styled.strong`
  font-weight: 700;
  text-transform: lowercase;
`;

export const Badge = styled.div`
  margin: 2.4rem 0;
  padding: 1.6rem 3.1rem;
  border-radius: 1.2rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};

  ${({ theme }) => theme.media.sm`
    padding: 1.2rem;
  `}

  svg {
    width: 8.8rem;
    height: 8.8rem;
    margin: auto;
  }
`;

export const BadgeGrid = styled.div`
  display: flex;
  margin: -0.8rem;
  align-items: center;
  ${({ theme }) => theme.media.sm`
    flex-direction: column;
    margin: -0.6rem;
  `};
`;

export const BadgeCell = styled.div`
  padding: 0.8rem;

  ${({ theme }) => theme.media.sm`
    padding: 0.6rem;
  `}
`;

export const BadgeTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  margin-bottom: 0.8rem;
  color: ${({ theme }) => theme.colors.grayDark};
`;

export const BadgeText = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.grayDark};
`;

export const BadgeLink = styled.a`
  color: ${({ theme }) => theme.colors.grayDark};
  text-decoration: underline;
`;

export const Actions = styled.div`
  margin-top: 3.3rem;
`;

export const BackButton = styled(BaseButton)`
  margin-right: 2rem;
`;

export const BackButtonIcon = styled.span`
  display: inline-block;

  svg {
    width: 1.9rem;
    height: 1.3rem;
    margin-right: 0.8rem;
    color: currentColor;
  }
`;

export const ButtonIcon = styled.span`
  display: inline-block;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;
    color: currentColor;
  }
`;
