import { Modal as BaseModal } from "@wrstudios/components";
import styled from "styled-components";
import { Title as BaseTitle } from "./customize-modal-kit";

export const ModalBody = styled(BaseModal.Body)`
  overflow: visible;
`;

export const Title = styled(BaseTitle)`
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.6rem;
  margin-top: 1.2rem;
  margin-bottom: 5rem;
  padding: 0 2.4rem;

  ${({ theme }) => theme.media.sm`
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 2.4rem;
  `}
`;

export const VideoContainer = styled.div`
  padding: 0 1.4rem;

  ${({ theme }) => theme.media.sm`
    padding: 0;
  `}

  > div {
    border-radius: 0.6rem;
  }
`;

export const Confirm = styled.a`
  text-decoration: none;
  padding: 0.95rem 1.6rem;
  background: #236847;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2), 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
  border-radius: 0.6rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;
