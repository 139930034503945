import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sort } from "../../actions/listings";
import { getSortOptions } from "../../selectors/listings";
import Select from "../select/Select";
import IconSort from "../icons/material/content/IconSort";

function findSortByOption(sortOptions = [], sortBy, sortDirection) {
  if (
    (typeof sortDirection === "undefined" ||
      !sortDirection.length ||
      !sortOptions.length) &&
    sortBy !== "manually"
  ) {
    return;
  }

  let option = sortOptions.find(([, value]) => value === sortBy);

  if (typeof option === "undefined") {
    option = sortOptions[0];
  }

  return {
    label: option[0],
    value: option[1]
  };
}

function ListingsControlsSort({
  reportId,
  sortOptions,
  sortDirection,
  sortBy,
  sort
}) {
  return (
    <div className="flex items-center">
      <span className="flex-no-shrink hidden lg:inline-block text-sm">
        Sort Listings
      </span>
      <div className="mr-6 lg:mx-6">
        <Select
          small={true}
          placeholder="Select Sort Option"
          selected={findSortByOption(sortOptions, sortBy, sortDirection)}
          options={sortOptions.map(([label, value]) => ({
            label,
            value
          }))}
          onSelect={(selected) =>
            sort(reportId, sortDirection || "desc", selected.value)
          }
        />
      </div>

      {!!sortBy.length && sortBy !== "manually" && (
        <div className="flex h-s14 border-2 border-grey-light rounded-lg">
          <button
            className={`h-full p-s2 border-r-1 border-grey-light rounded-tl-lg rounded-bl-lg ${
              sortDirection === "desc" ? "bg-grey-lightest" : ""
            }`}
            onClick={() => sort(reportId, "desc", sortBy)}>
            <span
              className={`inline-block text-grey listings-controls-icon asc ${
                sortDirection === "desc" ? "text-helix-brand" : ""
              }`}>
              <IconSort width={24} height={24} />
            </span>
          </button>
          <button
            className={`h-full p-s2 rounded-tr-lg rounded-br-lg ${
              sortDirection === "asc" ? "bg-grey-lightest" : ""
            }`}
            onClick={() => sort(reportId, "asc", sortBy)}>
            <span
              className={`inline-block text-grey listings-controls-icon desc ${
                sortDirection === "asc" ? "text-helix-brand bg-grey-lightest" : ""
              }`}>
              <IconSort width={24} height={24} />
            </span>
          </button>
        </div>
      )}
    </div>
  );
}

ListingsControlsSort.propTypes = {
  reportId: PropTypes.number.isRequired,
  sortOptions: PropTypes.array.isRequired,
  sortDirection: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sort: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { sortBy, sortDirection } = state.listings;
  const sortOptions = getSortOptions(state);

  return {
    reportId: state.report.id,
    sortOptions,
    sortDirection,
    sortBy
  };
};

const mapDispatchToProps = (dispatch) => ({
  sort: (reportId, direction, by) => dispatch(sort(reportId, direction, by)),
  toggle: (reportId, listingId, toggleState) =>
    dispatch(toggle(reportId, listingId, toggleState))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingsControlsSort);
