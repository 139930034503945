import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  padding: 56.25% 0 0 0; /*16:9*/
  height: 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};

  &:before {
    content: "Loading Video";
    font-size: 2.4rem;
    line-height: 3.3rem;
    color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    transform: translate(-50%, -50%);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
  }

  .wistia_embed,
  iframe,
  object,
  embed {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;
