import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loading } from "../../actions/page";

class BreadcrumbsLink extends Component {
  render() {
    const {
      item: { url, active, text, disableOnClick },
      isPageLoading
    } = this.props;

    return (
      <a
        href={url}
        className={`no-underline leading-2xl ${
          active ? "text-black" : "text-helix-gray-500 hover:text-helix-brand"
        } ${
          isPageLoading && disableOnClick ? "cursor-not-allowed opacity-50" : ""
        }`}
        onClick={disableOnClick ? this.handleOnClick : undefined}>
        {text}
      </a>
    );
  }

  handleOnClick = (e) => {
    const { isPageLoading, setIsPageLoading } = this.props;

    if (isPageLoading) {
      e.preventDefault();
      return;
    }

    setIsPageLoading(true);
  };
}

BreadcrumbsLink.propTypes = {
  item: PropTypes.shape({
    url: PropTypes.string,
    active: PropTypes.bool,
    text: PropTypes.string,
    disableOnClick: PropTypes.bool
  })
};

const mapStateToProps = (state) => ({
  isPageLoading: state.page.loading
});

const mapDispatchToProps = (dispatch) => ({
  setIsPageLoading: (status) => dispatch(loading(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbsLink);
