import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import accept from "attr-accept";
import IconCSV from "../icons/IconCSV";

class DropZone extends Component {
  static propTypes = {
    name: PropTypes.string,
    rejectedText: PropTypes.string,
    text: PropTypes.element,
    icon: PropTypes.element,
    accept: PropTypes.string,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    rejectClassName: PropTypes.string
  };

  static defaultProps = {
    className: "dropzone",
    activeClassName: "border-helix-brand",
    rejectClassName: "border-red text-red",
    rejectedText: "Invalid file type",
    text: (
      <Fragment>
        Drag file here or{" "}
        <span className="text-helix-brand cursor-pointer">browse</span>
      </Fragment>
    ),
    icon: <IconCSV width={39} height={50} viewBox="0 0 85 110" />
  };

  state = {
    isOver: false,
    valid: true,
    validDrop: true,
    file: null
  };

  constructor(props) {
    super(props);

    this.onDragOver = this.onDragOver.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();

    return false;
  }

  onDragEnter() {
    this.setState({ isOver: true });
  }

  onDragLeave() {
    this.setState({ isOver: false });
  }

  onDrop(e) {
    this.change(e.dataTransfer.files[0]);
  }

  onChange(e) {
    this.change(e.target.files[0]);
  }

  isValidFile(file) {
    let valid = accept(file, this.props.accept);

    // Let's try just the extensions since some browsers
    // are picky. e.g. application/zip could be application/x-compressed
    if (!valid) {
      const mimes = this.props.accept.split(",");
      const extension = file.name.split(".").pop();

      valid = mimes.some((mime) => extension === mime.split("/").pop());
    }

    return valid;
  }

  change(file) {
    const valid = this.isValidFile(file);

    this.setState(
      { file: valid ? file : null, isOver: false, valid, validDrop: valid },
      () => this.setState({ validDrop: true })
    );
  }

  render() {
    const {
      name,
      icon,
      text,
      accept,
      className,
      activeClassName,
      rejectClassName,
      rejectedText
    } = this.props;
    const { isOver, valid, validDrop, file } = this.state;

    let title = "No file chosen.";
    if (file && valid) title = file.name;
    if (!file && !valid) title = rejectedText;

    return (
      <div
        className={className}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        title={title}>
        <input
          key={!validDrop ? Date.now() : undefined}
          name={name}
          accept={accept}
          type="file"
          className="dropzone-input"
          onChange={this.onChange}
        />
        <p
          className={`flex items-center justify-center border-2 border-dashed border-grey-lighter rounded-lg text-lg leading-2xl ${
            (isOver || (file && valid)) && activeClassName
          } ${!isOver && !file && !valid && rejectClassName} dropzone-text`}>
          <span className="flex flex-col sm:flex-row items-center justify-center text-sbase leading-sxl text-center p-10 truncate dropzone-text-inner">
            {icon && (
              <span className="text-grey-lighter mb-8 sm:mb-0 sm:mr-8">
                {icon}
              </span>
            )}

            {((!file && valid) || isOver) && <span>{text}</span>}

            {!isOver && (
              <Fragment>
                {file && valid && (
                  <span className="text-helix-brand truncate" title={file.name}>
                    {file.name}
                  </span>
                )}
                {!file && !valid && (
                  <span className="truncate" title={rejectedText}>
                    {rejectedText}
                  </span>
                )}
              </Fragment>
            )}
          </span>
        </p>
      </div>
    );
  }
}

export default DropZone;
