import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";
import BaseTip from "../../common/Tip";

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 90rem;
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  margin: -1rem;
`;

export const Cell = styled.div`
  padding: 1rem;
`;

export const ErrorCell = styled(Cell)`
  margin-left: auto;
`;

export const Control = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const ControlDelete = styled(Control)`
  color: ${({ theme }) => theme.styleGuide.colors.red[500]};
`;

export const EditorContainer = styled.div`
  height: 50rem;
  position: relative;
`;

export const EditorDisabled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
`;

export const Tip = styled(BaseTip)`
  margin: 1rem 0;
`;

export const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FlashContainer = styled.div`
  margin-top: 2rem;
`;

export const Disclaimer = styled.i`
  display: block;
  font-size: 1.4rem;
  line-height: 2rem;

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const TemplateDisclaimer = styled(Disclaimer)`
  color: ${({ theme }) => theme.styleGuide.colors.purple[500]};
`;
