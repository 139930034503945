import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ActionLink from "../form/ActionLink";
import IconKeyboardArrowRight from "../icons/material/hardware/IconKeyboardArrowRight";
import BreadcrumbsLink from "./BreadcrumbsLink";

function BreadcrumbsList({ items }) {
  return (
    <ul className="items-center list-reset flex-wrap flex mb-12 sm:mb-0">
      {items.map((item, index) => {
        if (!item.url) item.url = null;

        return (
          <Fragment key={index}>
            {index > 0 && (
              <li>
                <span className="text-grey-dark mx-0 sm:mx-3 md:mx-6">
                  <IconKeyboardArrowRight />
                </span>
              </li>
            )}
            <li>
              {item.url && !item.form && !item.active && (
                <BreadcrumbsLink item={item} />
              )}
              {((!item.url && !item.form) || item.active) && (
                <span
                  className={`no-underline leading-2xl ${
                    item.active ? "text-black" : "text-helix-gray-500 hover:text-helix-brand"
                  }`}>
                  {item.text}
                </span>
              )}
              {item.form && (
                <ActionLink
                  href={"form_submit"}
                  className={`bg-transparent no-underline leading-2xl ${
                    item.active ? "!text-black" : "text-helix-gray-500 hover:text-helix-brand"
                  }`}
                  text={item.text}
                  loadingText={item.text}
                  showLoadingIndicator={false}
                  loadingClassName="opacity-50"
                />
              )}
            </li>
          </Fragment>
        );
      })}
    </ul>
  );
}

BreadcrumbsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      form: PropTypes.bool,
      disableOnClick: PropTypes.bool
    })
  ).isRequired
};

export default BreadcrumbsList;
