import "core-js/stable";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import "regenerator-runtime/runtime";
import smoothscroll from "smoothscroll-polyfill";
import { ThemeProvider } from "styled-components";
import Turbolinks from "turbolinks";
import { ApolloProvider } from "../Apollo";
import { SessionProvider } from "../Session";
import { createDynamicStore } from "../store";
import "../styles/application"; // This gets auto removed via `extract_css` in webpacker in production and staging
import theme from "../theme";
import { GlobalStyle } from "../theme/global";
import themeFromBit from "../theme/old";
import components from "../utils/components";

smoothscroll.polyfill();

Turbolinks.start();
Turbolinks.setProgressBarDelay(0);

const useTurboLinks = !document.body.classList.contains("no-turbolinks");

if (useTurboLinks) {
  Turbolinks.start();
  Turbolinks.setProgressBarDelay(0);
}

function load() {
  const store = createDynamicStore();

  document.querySelectorAll("[data-react-element]").forEach((element) => {
    const name = element.dataset.reactElement;
    const props = JSON.parse(element.dataset.reactElementProps || "{}");
    const Component = components[name];

    render(
      <SessionProvider>
        <ApolloProvider>
          <Provider store={store}>
            <ThemeProvider theme={{ ...themeFromBit, ...theme }}>
              <Component {...props} />
              <GlobalStyle />
            </ThemeProvider>
          </Provider>
        </ApolloProvider>
      </SessionProvider>,
      element
    );
  });
}

window.addEventListener("load", () => {
  document.body.classList.remove("loading");

  if (useTurboLinks) {
    document.addEventListener("turbolinks:load", () => {
      load();
      document.body.classList.remove("loading");
    });
  }
});

load();
