import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "../../Icon";

function IconLayoutPhotoPhoto(props) {
  return (
    <Icon width="233" height="303" viewBox="0 0 233 303" {...props}>
      {!props.hover && (
        <Fragment>
          <path
            fill="#c4c8d0"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#576474"
            d="M84.13 95.81a1.5 1.5 0 0 1-1.5-1.5V77.42A1.5 1.5 0 0 1 84 75.93L66.5 61.28 49 75.93a1.49 1.49 0 0 1 1 .43 1.52 1.52 0 0 1 .44 1.06v16.89a1.5 1.5 0 0 1-1.5 1.5 1.5 1.5 0 0 1-1.5-1.5V78.92h-2.61a1.5 1.5 0 0 1-1-2.65l21.71-18.09a1.48 1.48 0 0 1 1.92 0l21.67 18.09a1.52 1.52 0 0 1 .45 1.66 1.5 1.5 0 0 1-1.41 1h-2.54v15.38a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path fill="#e1e5e9" d="M70.2 94.31V82.17h-7.41v12.14" />
          <path
            fill="#576474"
            d="M70.2 95.81a1.5 1.5 0 0 1-1.5-1.5V83.67h-4.41v10.64a1.5 1.5 0 1 1-3 0V82.17a1.5 1.5 0 0 1 1.5-1.5h7.41a1.5 1.5 0 0 1 1.5 1.5v12.14a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path
            fill="#c4c8d0"
            d="M95 114.25H38a8.55 8.55 0 0 1-8.53-8.54V47A8.55 8.55 0 0 1 38 38.43h57.8a7.84 7.84 0 0 1 7.83 7.83v59.45a8.55 8.55 0 0 1-8.63 8.54zM38 41.43A5.54 5.54 0 0 0 32.42 47v58.74a5.54 5.54 0 0 0 5.58 5.51h57a5.54 5.54 0 0 0 5.54-5.54V46.26a4.84 4.84 0 0 0-4.83-4.83zM195.63 114.25h-57.09a8.54 8.54 0 0 1-8.53-8.54V47a8.54 8.54 0 0 1 8.53-8.54h57.8a7.84 7.84 0 0 1 7.83 7.83v59.45a8.55 8.55 0 0 1-8.54 8.51zm-57.09-72.82A5.54 5.54 0 0 0 133 47v58.74a5.54 5.54 0 0 0 5.53 5.54h57.09a5.54 5.54 0 0 0 5.54-5.54V46.26a4.84 4.84 0 0 0-4.83-4.83zM168.23 137.32h34.73v3h-34.73zM31.46 137.32h117.77v3H31.46zM31.46 155.74h171.5v3H31.46zM71.91 174.15h131.05v3H71.91zM31.46 174.15h20.13v3H31.46zM31.46 207.38h34.73v3H31.46zM85.19 207.38h117.77v3H85.19zM31.46 225.79h171.5v3H31.46zM31.46 244.2h131.05v3H31.46zM182.83 244.2h20.13v3h-20.13z"
          />
          <path
            fill="#576474"
            d="M185.54 89.37h-36.63a1.5 1.5 0 0 1 0-3h36.63a1.5 1.5 0 0 1 0 3zM185.54 101.15h-36.63a1.5 1.5 0 0 1 0-3h36.63a1.5 1.5 0 0 1 0 3z"
          />
          <path
            fill="#e1e5e9"
            d="M185.78 86.5v-8.68a2.11 2.11 0 0 0-2.21-2h-33.08a2.11 2.11 0 0 0-2.21 2v8.68"
          />
          <path
            fill="#576474"
            d="M185.78 88a1.5 1.5 0 0 1-1.5-1.5v-8.68c0-.23-.3-.48-.71-.48h-33.08c-.42 0-.71.25-.71.48v8.68a1.5 1.5 0 0 1-3 0v-8.68a3.6 3.6 0 0 1 3.71-3.48h33.08a3.61 3.61 0 0 1 3.71 3.48v8.68a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path
            fill="#576474"
            d="M166.87 88.29a1.5 1.5 0 0 1-1.5-1.5V77.3a1.5 1.5 0 0 1 3 0v9.49a1.51 1.51 0 0 1-1.5 1.5z"
          />
          <path fill="#fff" d="M142.19 83.99h5.77v17.79h-5.77z" />
          <path
            fill="#576474"
            d="M148 103.29h-5.77a1.5 1.5 0 0 1-1.5-1.5V84a1.5 1.5 0 0 1 1.5-1.5H148a1.51 1.51 0 0 1 1.5 1.5v17.8a1.5 1.5 0 0 1-1.5 1.49zm-4.27-3h2.77v-14.8h-2.77z"
          />
          <path fill="#fff" d="M185.78 83.99h5.77v17.79h-5.77z" />
          <path
            fill="#576474"
            d="M191.55 103.29h-5.77a1.5 1.5 0 0 1-1.5-1.5V84a1.5 1.5 0 0 1 1.5-1.5h5.77a1.51 1.51 0 0 1 1.5 1.5v17.8a1.5 1.5 0 0 1-1.5 1.49zm-4.27-3h2.77v-14.8h-2.77z"
          />
          <path
            fill="#c4c8d0"
            d="M166.88 68.17a8.5 8.5 0 1 1 8.5-8.5 8.51 8.51 0 0 1-8.5 8.5zm0-14a5.5 5.5 0 1 0 5.5 5.5 5.51 5.51 0 0 0-5.5-5.5z"
          />
        </Fragment>
      )}
      {props.hover && (
        <Fragment>
          <path
            fill="#236847"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#236847"
            d="M84.13 95.81a1.5 1.5 0 0 1-1.5-1.5V77.42A1.5 1.5 0 0 1 84 75.93L66.5 61.28 49 75.93a1.49 1.49 0 0 1 1 .43 1.52 1.52 0 0 1 .44 1.06v16.89a1.5 1.5 0 0 1-1.5 1.5 1.5 1.5 0 0 1-1.5-1.5V78.92h-2.61a1.5 1.5 0 0 1-1-2.65l21.71-18.09a1.48 1.48 0 0 1 1.92 0l21.67 18.09a1.52 1.52 0 0 1 .45 1.66 1.5 1.5 0 0 1-1.41 1h-2.54v15.38a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path fill="#e1e5e9" d="M70.2 94.31V82.17h-7.41v12.14" />
          <path
            fill="#236847"
            d="M70.2 95.81a1.5 1.5 0 0 1-1.5-1.5V83.67h-4.41v10.64a1.5 1.5 0 1 1-3 0V82.17a1.5 1.5 0 0 1 1.5-1.5h7.41a1.5 1.5 0 0 1 1.5 1.5v12.14a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path
            fill="#236847"
            d="M95 114.25H38a8.55 8.55 0 0 1-8.53-8.54V47A8.55 8.55 0 0 1 38 38.43h57.8a7.84 7.84 0 0 1 7.83 7.83v59.45a8.55 8.55 0 0 1-8.63 8.54zM38 41.43A5.54 5.54 0 0 0 32.42 47v58.74a5.54 5.54 0 0 0 5.58 5.51h57a5.54 5.54 0 0 0 5.54-5.54V46.26a4.84 4.84 0 0 0-4.83-4.83zM195.63 114.25h-57.09a8.54 8.54 0 0 1-8.53-8.54V47a8.54 8.54 0 0 1 8.53-8.54h57.8a7.84 7.84 0 0 1 7.83 7.83v59.45a8.55 8.55 0 0 1-8.54 8.51zm-57.09-72.82A5.54 5.54 0 0 0 133 47v58.74a5.54 5.54 0 0 0 5.53 5.54h57.09a5.54 5.54 0 0 0 5.54-5.54V46.26a4.84 4.84 0 0 0-4.83-4.83zM168.23 137.32h34.73v3h-34.73zM31.46 137.32h117.77v3H31.46zM31.46 155.74h171.5v3H31.46zM71.91 174.15h131.05v3H71.91zM31.46 174.15h20.13v3H31.46zM31.46 207.38h34.73v3H31.46zM85.19 207.38h117.77v3H85.19zM31.46 225.79h171.5v3H31.46zM31.46 244.2h131.05v3H31.46zM182.83 244.2h20.13v3h-20.13z"
          />
          <path
            fill="#236847"
            d="M185.54 89.37h-36.63a1.5 1.5 0 0 1 0-3h36.63a1.5 1.5 0 0 1 0 3zM185.54 101.15h-36.63a1.5 1.5 0 0 1 0-3h36.63a1.5 1.5 0 0 1 0 3z"
          />
          <path
            fill="#e1e5e9"
            d="M185.78 86.5v-8.68a2.11 2.11 0 0 0-2.21-2h-33.08a2.11 2.11 0 0 0-2.21 2v8.68"
          />
          <path
            fill="#236847"
            d="M185.78 88a1.5 1.5 0 0 1-1.5-1.5v-8.68c0-.23-.3-.48-.71-.48h-33.08c-.42 0-.71.25-.71.48v8.68a1.5 1.5 0 0 1-3 0v-8.68a3.6 3.6 0 0 1 3.71-3.48h33.08a3.61 3.61 0 0 1 3.71 3.48v8.68a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path
            fill="#236847"
            d="M166.87 88.29a1.5 1.5 0 0 1-1.5-1.5V77.3a1.5 1.5 0 0 1 3 0v9.49a1.51 1.51 0 0 1-1.5 1.5z"
          />
          <path fill="#fff" d="M142.19 83.99h5.77v17.79h-5.77z" />
          <path
            fill="#236847"
            d="M148 103.29h-5.77a1.5 1.5 0 0 1-1.5-1.5V84a1.5 1.5 0 0 1 1.5-1.5H148a1.51 1.51 0 0 1 1.5 1.5v17.8a1.5 1.5 0 0 1-1.5 1.49zm-4.27-3h2.77v-14.8h-2.77z"
          />
          <path fill="#fff" d="M185.78 83.99h5.77v17.79h-5.77z" />
          <path
            fill="#236847"
            d="M191.55 103.29h-5.77a1.5 1.5 0 0 1-1.5-1.5V84a1.5 1.5 0 0 1 1.5-1.5h5.77a1.51 1.51 0 0 1 1.5 1.5v17.8a1.5 1.5 0 0 1-1.5 1.49zm-4.27-3h2.77v-14.8h-2.77zM166.88 68.17a8.5 8.5 0 1 1 8.5-8.5 8.51 8.51 0 0 1-8.5 8.5zm0-14a5.5 5.5 0 1 0 5.5 5.5 5.51 5.51 0 0 0-5.5-5.5z"
          />
        </Fragment>
      )}
    </Icon>
  );
}

IconLayoutPhotoPhoto.propTypes = {
  hover: PropTypes.bool
};

IconLayoutPhotoPhoto.defaultProps = {
  hover: false
};

export default IconLayoutPhotoPhoto;
