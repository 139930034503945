import React from "react";
import PropTypes from "prop-types";

function IconFlyer({ hover, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 233 303"
      {...props}
      aria-hidden>
      {!hover && (
        <>
          <path
            fill="#c4c8d0"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#576474"
            d="M74.79 76.58v-27.3h17.32v4.87H80.05v7.22H91V66H80v10.58zM96.2 76.58V48.11h5.27v28.47zM108.52 85.35l4.14-9.79-8.27-19.65h5.15l5.34 14h.12l5.34-14h5.15l-11.93 29.44zM141.4 69.44l3.6 3.12a9.85 9.85 0 0 1-8.54 4.6 9.12 9.12 0 0 1-7.06-3 11.31 11.31 0 0 1-2.73-7.94 11.31 11.31 0 0 1 2.71-7.93 8.86 8.86 0 0 1 6.84-3 8.74 8.74 0 0 1 6.81 3 11.43 11.43 0 0 1 2.67 7.93v1.52h-13.93a5.57 5.57 0 0 0 1.3 3.65 4.28 4.28 0 0 0 3.26 1.27 4.71 4.71 0 0 0 2.91-.88 10.4 10.4 0 0 0 2.16-2.34zM131.77 64h9a4.74 4.74 0 0 0-1.37-3.1 4.45 4.45 0 0 0-3.2-1.11 4.11 4.11 0 0 0-3.09 1.21 4.68 4.68 0 0 0-1.34 3zM149.75 76.58V55.91h4.79v2.18h.08a4.54 4.54 0 0 1 1.74-1.87 4.8 4.8 0 0 1 4.39-.31l-.75 4.91a4.7 4.7 0 0 0-1.6-.31q-3.56 0-3.55 4.72v11.35z"
          />
          <path
            fill="#c4c8d0"
            d="M157.82 215.32h27.41v3h-27.41zM49.89 215.32h92.93v3H49.89zM49.89 233.73h135.34v3H49.89zM81.81 252.14h103.42v3H81.81zM49.89 252.14h15.89v3H49.89z"
          />
          <path
            fill="#576474"
            d="M97.14 175.6a1.5 1.5 0 0 1-1.5-1.5v-16.88a1.5 1.5 0 0 1 1.41-1.5l-17.54-14.65L62 155.72a1.51 1.51 0 0 1 1.41 1.5v16.88a1.5 1.5 0 0 1-1.5 1.5 1.5 1.5 0 0 1-1.5-1.5v-15.38h-2.57a1.5 1.5 0 0 1-1-2.66L78.55 138a1.48 1.48 0 0 1 1.92 0l21.67 18.09a1.5 1.5 0 0 1-1 2.66h-2.5v15.35a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path fill="#e1e5e9" d="M83.21 174.1v-12.14H75.8v12.14" />
          <path
            fill="#576474"
            d="M83.21 175.6a1.5 1.5 0 0 1-1.5-1.5v-10.64H77.3v10.64a1.5 1.5 0 0 1-3 0V162a1.5 1.5 0 0 1 1.5-1.5h7.41a1.5 1.5 0 0 1 1.5 1.5v12.1a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path
            fill="#c4c8d0"
            d="M108.05 194H51a8.54 8.54 0 0 1-8.53-8.53v-58.71a8.55 8.55 0 0 1 8.53-8.54h57.8a7.84 7.84 0 0 1 7.83 7.83v59.46a8.55 8.55 0 0 1-8.58 8.49zM51 121.22a5.54 5.54 0 0 0-5.53 5.54v58.75A5.54 5.54 0 0 0 51 191h57.09a5.54 5.54 0 0 0 5.54-5.53v-59.42a4.84 4.84 0 0 0-4.83-4.83z"
          />
          <path
            fill="#576474"
            d="M166.46 151.89c-.49-1-2.19-2-4.2-2-1.82 0-3.6.7-3.62 2.22s1.9 1.88 3.81 2c3.83.41 8 1.5 8 6.08 0 3.34-2.17 5.38-6.21 5.79v2.44h-3.53V166a8.2 8.2 0 0 1-6.89-4.65l3.34-1.63c.75 1.63 3 2.81 5.12 2.81 2.35 0 4.12-.33 4.12-2.34 0-1.62-2-2.29-4.24-2.47-3.79-.36-7.5-1.11-7.47-5.64 0-3.4 3-5 6.13-5.3v-2.14h3.4v2.14a6.34 6.34 0 0 1 5.43 3.65z"
          />
          <path
            fill="#576474"
            d="M189 175.33h-49.16a1.51 1.51 0 0 1-1.31-.76l-9.6-16.92a1.5 1.5 0 0 1 0-1.44l9.6-18.15a1.51 1.51 0 0 1 1.33-.8H189a1.5 1.5 0 0 1 1.5 1.5v35.07a1.5 1.5 0 0 1-1.5 1.5zm-48.29-3h46.79v-32.07h-46.76L132 156.89z"
          />
        </>
      )}
      {hover && (
        <>
          <path
            fill="#236847"
            d="M225.75,303H7.25A7.26,7.26,0,0,1,0,295.75V7.25A7.26,7.26,0,0,1,7.25,0h218.5A7.26,7.26,0,0,1,233,7.25v288.5A7.26,7.26,0,0,1,225.75,303ZM7.25,3A4.26,4.26,0,0,0,3,7.25v288.5A4.26,4.26,0,0,0,7.25,300h218.5a4.26,4.26,0,0,0,4.25-4.25V7.25A4.26,4.26,0,0,0,225.75,3Z"
          />
          <path
            fill="#236847"
            d="M74.79,76.58V49.28H92.11v4.87H80.05v7.22H91V66h-11V76.58Z"
          />
          <path fill="#236847" d="M96.2,76.58V48.11h5.27V76.58Z" />
          <path
            fill="#236847"
            d="M108.52,85.35l4.14-9.79-8.27-19.65h5.15l5.34,14H115l5.34-14h5.15L113.56,85.35Z"
          />
          <path
            fill="#236847"
            d="M141.4,69.44,145,72.56a9.85,9.85,0,0,1-8.54,4.6,9.12,9.12,0,0,1-7.06-3,11.31,11.31,0,0,1-2.73-7.94,11.31,11.31,0,0,1,2.71-7.93,8.86,8.86,0,0,1,6.84-3,8.74,8.74,0,0,1,6.81,3,11.43,11.43,0,0,1,2.67,7.93v1.52H131.77a5.57,5.57,0,0,0,1.3,3.65,4.28,4.28,0,0,0,3.26,1.27,4.71,4.71,0,0,0,2.91-.88A10.4,10.4,0,0,0,141.4,69.44ZM131.77,64h9a4.74,4.74,0,0,0-1.37-3.1,4.45,4.45,0,0,0-3.2-1.11A4.11,4.11,0,0,0,133.11,61,4.68,4.68,0,0,0,131.77,64Z"
          />
          <path
            fill="#236847"
            d="M149.75,76.58V55.91h4.79v2.18h.08a4.54,4.54,0,0,1,1.74-1.87,4.8,4.8,0,0,1,4.39-.31L160,60.82a4.7,4.7,0,0,0-1.6-.31q-3.56,0-3.55,4.72V76.58Z"
          />
          <rect fill="#236847" x="157.82" y="215.32" width="27.41" height="3" />
          <rect fill="#236847" x="49.89" y="215.32" width="92.93" height="3" />
          <rect fill="#236847" x="49.89" y="233.73" width="135.34" height="3" />
          <rect fill="#236847" x="81.81" y="252.14" width="103.42" height="3" />
          <rect fill="#236847" x="49.89" y="252.14" width="15.89" height="3" />
          <path
            fill="#236847"
            d="M97.14,175.6a1.5,1.5,0,0,1-1.5-1.5V157.22a1.5,1.5,0,0,1,1.41-1.5L79.51,141.07,62,155.72a1.51,1.51,0,0,1,1.41,1.5V174.1a1.5,1.5,0,0,1-1.5,1.5h0a1.5,1.5,0,0,1-1.5-1.5V158.72H57.84a1.5,1.5,0,0,1-1-2.66L78.55,138a1.48,1.48,0,0,1,1.92,0l21.67,18.09a1.5,1.5,0,0,1-1,2.66H98.64V174.1A1.5,1.5,0,0,1,97.14,175.6Z"
          />
          <polyline
            fill="#e1e5e9"
            points="83.21 174.1 83.21 161.96 75.8 161.96 75.8 174.1"
          />
          <path
            fill="#236847"
            d="M83.21,175.6a1.5,1.5,0,0,1-1.5-1.5V163.46H77.3V174.1a1.5,1.5,0,0,1-3,0V162a1.5,1.5,0,0,1,1.5-1.5h7.41a1.5,1.5,0,0,1,1.5,1.5V174.1A1.5,1.5,0,0,1,83.21,175.6Z"
          />
          <path
            fill="#236847"
            d="M108.05,194H51a8.54,8.54,0,0,1-8.53-8.53V126.76A8.55,8.55,0,0,1,51,118.22h57.8a7.84,7.84,0,0,1,7.83,7.84v59.45A8.55,8.55,0,0,1,108.05,194ZM51,121.22a5.54,5.54,0,0,0-5.53,5.54v58.75A5.54,5.54,0,0,0,51,191h57.09a5.54,5.54,0,0,0,5.54-5.53V126.06a4.84,4.84,0,0,0-4.83-4.84Z"
          />
          <path
            fill="#236847"
            d="M166.46,151.89c-.49-1-2.19-2-4.2-2-1.82,0-3.6.7-3.62,2.22s1.9,1.88,3.81,2c3.83.41,8,1.5,8,6.08,0,3.34-2.17,5.38-6.21,5.79v2.44h-3.53V166a8.2,8.2,0,0,1-6.89-4.65l3.34-1.63c.75,1.63,3,2.81,5.12,2.81,2.35,0,4.12-.33,4.12-2.34,0-1.62-2-2.29-4.24-2.47-3.79-.36-7.5-1.11-7.47-5.64,0-3.4,3-5,6.13-5.3v-2.14h3.4v2.14a6.34,6.34,0,0,1,5.43,3.65Z"
          />
          <path
            fill="#236847"
            d="M189,175.33H139.84a1.51,1.51,0,0,1-1.31-.76l-9.6-16.92a1.5,1.5,0,0,1,0-1.44l9.6-18.15a1.51,1.51,0,0,1,1.33-.8H189a1.5,1.5,0,0,1,1.5,1.5v35.07A1.5,1.5,0,0,1,189,175.33Zm-48.29-3H187.5V140.26H140.74L132,156.89Z"
          />
        </>
      )}
    </svg>
  );
}

IconFlyer.propTypes = {
  hover: PropTypes.bool
};

IconFlyer.defaultProps = {
  hover: false
};

export default IconFlyer;
