import styled from "styled-components";

export const Container = styled.div`
  margin: -3rem 0 3rem;

  ${({ theme }) => theme.media.sm`
    margin: -1.6rem 0 3rem;
  `}
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 1rem;
  color: ${({ theme }) => theme.styleGuide.colors.white};
  background-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  font-weight: 600;
  letter-spacing: -0.02rem;
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0.8rem;
  border-radius: 9999rem;
  font-weight: 600;
  background-color: #a78bfa;
  font-size: 1.2rem;
  line-height: 1.6rem;

  svg {
    flex-shrink: 0;
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const Button = styled.a`
  font-weight: 700;
  cursor: pointer;
  appearance: none;
  color: white;
  text-decoration: none;
`;
