import styled from "styled-components";
import { default as BaseInput } from "../../common/Input";

export const Input = styled(BaseInput)`
  text-align: right;
  border-color: ${({ theme }) => theme.colors.purple};

  &:focus {
    outline: solid 1px ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    border-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  }

  ${({ theme }) => theme.media.sm`
    text-align: center;
  `}
`;
