import styled from "styled-components";

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-size: ${({ small, large }) =>
    small ? "1.3rem" : large ? "1.6rem" : "1.5rem"};
  font-weight: 600;
  padding: ${({ small, large }) =>
    small ? "0.8rem 1.4rem" : large ? "1.5rem 3rem" : "0.8rem 2rem"};
  line-height: 2rem;
  text-decoration: none;
  cursor: pointer;

  border: 0.2rem solid ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  border-bottom: 0.2rem solid ${({ theme }) => theme.colors.greenDark};
  background-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  color: ${({ theme }) => theme.colors.white};
  appearance: none;

  &,
  &[type="button"],
  &[type="reset"],
  &[type="submit"] {
    border-radius: 0.4rem;
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.greenLight};
    border-bottom-color: ${({ theme }) => theme.colors.greenDark};
    background-color: ${({ theme }) => theme.colors.greenLight};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.greenDark};
    border-color: ${({ theme }) => theme.colors.greenDark};
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      border-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
      border-bottom: 0.2rem solid ${({ theme }) => theme.colors.greenDark};
      background-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    }
  }
`;

export const ButtonSecondary = styled(Button)`
  border-color: ${({ theme }) => theme.colors.grayLighter};
  border-bottom-color: ${({ theme }) => theme.colors.grayLight};
  background-color: ${({ theme }) => theme.colors.grayLighter};
  color: ${({ theme }) => theme.colors.gray};

  &:hover,
  &:focus {
    border-color: #f1f5f9;
    border-bottom-color: ${({ theme }) => theme.colors.grayLight};
    background-color: #f1f5f9;
  }

  &:active {
    border-color: #c8ccd0;
    background-color: #c8ccd0;
  }

  &[disabled] {
    &:hover,
    &:focus,
    &:active {
      border-color: ${({ theme }) => theme.colors.grayLighter};
      border-bottom-color: ${({ theme }) => theme.colors.grayLight};
      background-color: ${({ theme }) => theme.colors.grayLighter};
    }
  }
`;

export const ButtonDanger = styled(Button)`
  border-color: ${({ theme }) => theme.colors.red};
  border-bottom-color: ${({ theme }) => theme.colors.redDark};
  background-color: ${({ theme }) => theme.colors.red};

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.redLight};
    border-bottom-color: ${({ theme }) => theme.colors.redDark};
    background-color: ${({ theme }) => theme.colors.redLight};
  }

  &:active {
    border-color: ${({ theme }) => theme.colors.redDark};
    background-color: ${({ theme }) => theme.colors.redDark};
  }

  &[disabled] {
    &:hover,
    &:focus,
    &:after {
      border-color: ${({ theme }) => theme.colors.red};
      border-bottom-color: ${({ theme }) => theme.colors.redDark};
      background-color: ${({ theme }) => theme.colors.red};
    }
  }
`;
