import styled from "styled-components";
import Skeleton from "../Skeleton";

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1.5rem;
`;

export const Cell = styled.div`
  width: 25%;
  padding: 1.5rem;

  ${({ theme }) => theme.media.lg`
    width: 50%;
  `};

  ${({ theme }) => theme.media.sm`
    width: 100%;
  `};
`;

export const Article = styled.a`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-radius: 0.4rem;
  text-decoration: none;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const Image = styled.img`
  height: 14.8rem;
  margin: ${({ isRdcArticle }) => (isRdcArticle ? "auto" : "0 auto")};
  padding: ${({ isRdcArticle }) => (isRdcArticle ? "2rem" : "0")};
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg {
    width: auto;
    height: 70%;
  }

  ${({ theme }) => theme.media.xl`
    height: 11.9rem;
  `}

  ${({ theme }) => theme.media.lg`
    height: 19.2rem;
  `}

  ${({ theme }) => theme.media.md`
    height: 13.9rem;
  `}

  ${({ theme, isRdcArticle }) => theme.media.sm`
    height: ${isRdcArticle ? "21.7rem" : "auto"};
    max-height: 27.9rem;
  `}
`;

export const SkeletonImage = styled(Skeleton)`
  width: 100%;
  height: 14.8rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  ${({ theme }) => theme.media.xl`
    height: 11.9rem;
  `}

  ${({ theme }) => theme.media.lg`
    height: 19.2rem;
  `}

  ${({ theme }) => theme.media.md`
    height: 13.9rem;
  `}

  ${({ theme }) => theme.media.sm`
    height: 27.9rem;
  `}
`;

export const Badge = styled.span`
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.1rem;
  letter-spacing: 0.023rem;
  padding: 0.1rem 0.2rem;
  background-color: ${({ theme }) => theme.colors.gray};
  background-color: ${({ type, theme }) =>
    type === "streams" && theme.colors.blue};
  background-color: ${({ type, theme }) =>
    type === "mlx" && theme.colors.blueAlt};
  background-color: ${({ type, theme }) =>
    type === "attract" && theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.2rem;
  text-transform: uppercase;
  position: absolute;
  top: 1rem;
  left: 1.3rem;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
  border-top: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};
`;

export const Title = styled.h4`
  flex-grow: 1;
  font-weight: 400;
`;

export const More = styled.span`
  display: inline-block;
  font-size: 1.3rem;
  line-height: 2.4rem;
  margin-top: 0.3rem;
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
`;
