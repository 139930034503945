import PropTypes from "prop-types";
import React from "react";
import { styleGuide } from "../../theme";
import { Container, Tab as StyledTab } from "./styled/tabs";

function Tabs(props) {
  return <Container {...props} />;
}

function Tab({ isActive, activeColor, ...props }) {
  return <StyledTab isActive={isActive} activeColor={activeColor} {...props} />;
}

Tab.defaultProps = {
  activeColor: styleGuide.colors.helix['brand']
};

Tab.propTypes = {
  isActive: PropTypes.bool,
  activeColor: PropTypes.string
};

Tabs.Tab = Tab;

export default Tabs;
