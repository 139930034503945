import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Title = styled.h3`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.6rem;
    line-height: 2rem;
  `}
`;

export const Badge = styled.span`
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.25;
  margin-left: 2rem;
  padding: 0.1rem 0.3rem;
  text-transform: uppercase;
  user-select: none;
  border-radius: 0.2rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  color: ${({ theme }) => theme.styleGuide.colors.white};
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
`;

export const Cell = styled.div`
  width: 20%;
  padding: 1rem;

  ${({ theme }) => theme.media.lg`
    width: 25%;
  `}

  ${({ theme }) => theme.media.md`
    width: calc(100% / 3);
  `}

  ${({ theme }) => theme.media.sm`
    width: 50%;
  `}
`;
