import React from "react";
import PropTypes from "prop-types";
import BreadcrumbsList from "./BreadcrumbsList";
import BreadcrumbsAction from "./BreadcrumbsAction";
import {Container} from './styled/breadcrumbs-list'

function Breadcrumbs({ items, action }) {
  return (
    <Container
      className={`items-end my-12 breadcrumbs flex-col sm:flex-row items-center justify-between ${
        !action ? "hidden md:flex" : "flex"
      }`}>
      <BreadcrumbsList items={items} />
      {action && (
        <BreadcrumbsAction
          text={action.text}
          textLoading={action.textLoading || action.text}
          url={action.url}
          form={action.form}
        />
      )}
    </Container>
  );
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    textLoading: PropTypes.string.isRequired,
    form: PropTypes.string
  })
};

export default Breadcrumbs;
