import styled, { css } from "styled-components";

export const inputStyle = css`
  display: block;
  width: ${({ isFullWidth }) => isFullWidth && "100%"};
  font-size: 1.4rem;
  line-height: 2rem;
  min-width: 0;
  padding: 0.7rem 1.2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  appearance: none;
  outline: none;

  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    outline: solid 1px ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  }

  &::placeholder {
    color: #9ca3af;
  }

  &:invalid {
    box-shadow: none;
  }

  ${({ as }) =>
    as === "label" &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;

export const Container = styled.input`
  ${inputStyle};
`;

export const HostedFieldWrapper = styled.div`
  .braintree-hosted-field {
    ${inputStyle};
    height: 3.6rem;

    &.braintree-hosted-fields-focused {
      border-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    }
  }
`;
