import styled from "styled-components";
import IconPlay from "../../icons/IconPlay";

export const Container = styled.button`
  width: 100%;
  height: 15rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};

  ${({ theme }) => theme.media.xl`
    flex-direction: column;
  `}

  ${({ theme }) => theme.media.lg`
    flex-direction: row;
  `}

  ${({ theme }) => theme.media.md`
    flex-direction: column;
  `}

  ${({ theme }) => theme.media.sm`
    flex-direction: row;
  `}

  ${({ theme }) => theme.media.xs`
    flex-direction: column;
  `}
`;

export const Icon = styled(IconPlay)`
  margin: 0 1rem 0 0;

  ${({ theme }) => theme.media.xl`
    margin: 0 0 1rem;
  `}

  ${({ theme }) => theme.media.lg`
    margin: 0 1rem 0 0;
  `}

  ${({ theme }) => theme.media.md`
    margin: 0 0 1rem;
  `}

  ${({ theme }) => theme.media.sm`
    margin: 0 1rem 0 0;
  `}

  ${({ theme }) => theme.media.xs`
    margin: 0 0 1rem;
  `}
`;
