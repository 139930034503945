import styled from "styled-components";

export const Label = styled.label`
  display: inline-flex;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.8rem;
  margin-bottom: 0.6rem;
  color: ${({ theme }) => theme.colors.gray};

  &[for] {
    cursor: pointer;
  }
`;

export const Text = styled.p`
  font-size: 1.5rem;
  margin: 0;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  text-decoration: none;
`;

export const Strong = styled.strong`
  color: ${({ theme }) => theme.colors.gray};
`;

export const Truncate = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
