import styled from "styled-components";
import BaseInput from "../../common/Input";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3.2rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: 1.2rem;
  padding-right: 1.2rem;
  color: #233040;
`;

export const HeaderDescription = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  padding-right: 1.2rem;
  color: #4b5563;
`;

export const Close = styled.button`
  cursor: pointer;
  appearance: none;
  display: flex;
  width: 2rem;
  padding: 0;
  margin-top: -0.5rem;
  flex-shrink: 0;
`;

export const Section = styled.div`
  margin-top: 3.2rem;
`;

export const AddNoteSection = styled(Section)`
  margin-top: 2.4rem;
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.6rem;
  margin-bottom: 1.6rem;
  color: #111827;
`;

export const ControlGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const BorderLessInput = styled(BaseInput)`
  padding: 0;
  border: 0;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type="number"] {
    -moz-appearance: textfield;
  }
`;

export const SwitchText = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  margin-left: 1rem;
`;

export const NetSheetHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;

export const ClearLink = styled.button`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  padding: 0;
  color: ${({ theme }) =>
    theme.live?.colors.primary || theme.styleGuide.colors.helix['brand']};
  text-decoration: none;
  appearance: none;
  cursor: pointer;
  margin-bottom: 1.6rem;
`;

export const AddButton = styled.button`
  color: ${({ theme }) => theme.live?.colors.primary};
`;

export const DeleteLink = styled.a`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  padding: 0;
  color: ${({ theme }) => theme.styleGuide.colors.red[600]};
  text-decoration: none;
  appearance: none;
  cursor: pointer;
`;

export const SheetRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border: 0.1rem solid #eeeeee;
  padding: 1.4rem;

  &:first-of-type,
  &:last-of-type {
    border-top: 0;
  }
`;

export const RangeInputContainer = styled.div`
  display: flex;
  gap: 1.1rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SheetColumnOne = styled.div`
  width: 5%;
`;

export const SheetColumnTwo = styled.div`
  width: 40%;
`;

export const SheetColumnThree = styled.div`
  width: 15%;
`;

export const SheetColumnFour = styled.div`
  width: 30%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
`;

export const SheetColumnFive = styled.div`
  cursor: pointer;
  padding-left: 0.8rem;
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: right;
`;

export const SheetLabel = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const ClearContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 30rem;
  flex-direction: column;
  border: 0.1rem solid #e5e7eb;
  border-radius: 0.6rem;
  background: #f9fafb;

  h3 {
    text-align: center;
    margin-bottom: 2.3rem;
  }

  button {
    text-align: center;
    margin: 1rem;
    height: 3.4rem;
    line-height: 1.3rem;
    margin-right: 1rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

export const AgentNoteHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 0.4rem;
  width: 100%;
`;

export const AgentNoteLabel = styled.label`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #374151;
`;

export const Optional = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #9ca3af;
`;

export const Percentage = styled(Optional)`
  margin-top: 0.4rem;
`;
