import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateSuggestedListPrice } from "../../actions/subject-property";
import { currency } from "../../utils/formatters";
import Tooltip from "../common/Tooltip";

class PricingGuideRow extends Component {
  static propTypes = {
    report: PropTypes.shape({
      id: PropTypes.number,
      includes: PropTypes.shape({
        sqft: PropTypes.bool
      })
    }),
    subjectProperty: PropTypes.object,
    title: PropTypes.string,
    titleColor: PropTypes.string,
    prices: PropTypes.shape({
      low: PropTypes.number,
      median: PropTypes.number,
      average: PropTypes.number,
      high: PropTypes.number
    }),
    sqfts: PropTypes.shape({
      low: PropTypes.number,
      median: PropTypes.number,
      average: PropTypes.number,
      high: PropTypes.number
    }),
    ppSqft: PropTypes.number,
    updateSuggestedListPrice: PropTypes.func
  };

  static defaultProps = {
    titleColor: "grey"
  };

  state = {
    showTooltip: false
  };

  render() {
    const {
      report,
      subjectProperty,
      title,
      titleColor,
      prices,
      ppSqft,
      updateSuggestedListPrice
    } = this.props;
    // We round to make the numbers correct.
    // Without rounding the numbers are off but we get a more accurate number.
    const roundedPPSqft = Math.round(ppSqft);
    const averagePPSqft = subjectProperty.sqft * roundedPPSqft;
    const plusMinus3Percent = !!subjectProperty.sqft
      ? `${currency(averagePPSqft - averagePPSqft * 0.03)} - ${currency(
          averagePPSqft + averagePPSqft * 0.03
        )}`
      : `${currency(prices.average - prices.average * 0.03)} - ${currency(
          prices.average + prices.average * 0.03
        )}`;

    // prettier-ignore
    const plusMinus3PercentShort = `${currency( prices.average - prices.average * 0.03, '$0[.]0a', false, 3 )} - ${currency(prices.average + prices.average * 0.03, '$0[.]0a', false, 3)}`

    return (
      <div className="tabley-row">
        <div className={`tabley-cell text-${titleColor}`}>
          <strong className="tabley-responsive-header">{title}</strong>
          <span className="hidden sm:block">{title}</span>
        </div>
        <div className="tabley-cell text-right">
          <strong className="tabley-responsive-header">Low:</strong>
          <button
            title={currency(prices.low, null, true)}
            className="sm:ml-auto sm:block text-grey text-right"
            type="button"
            onClick={() =>
              updateSuggestedListPrice(
                report.id,
                subjectProperty.id,
                prices.low
              )
            }>
            <span className="sm:hidden md:inline-block">
              {currency(prices.low)}
            </span>
            <span className="hidden sm:inline-block md:hidden">
              {currency(prices.low, "$0[.]0a", false, 3)}
            </span>
          </button>
        </div>
        <div className="tabley-cell text-right">
          <strong className="tabley-responsive-header">Median:</strong>
          <button
            title={currency(prices.median, null, true)}
            className="sm:ml-auto sm:block text-grey text-right"
            type="button"
            onClick={() =>
              updateSuggestedListPrice(
                report.id,
                subjectProperty.id,
                prices.median
              )
            }>
            <span className="sm:hidden md:inline-block">
              {currency(prices.median)}
            </span>
            <span className="hidden sm:inline-block md:hidden">
              {currency(prices.median, "$0[.]0a", false, 3)}
            </span>
          </button>
        </div>
        <div className="tabley-cell text-right">
          <strong className="tabley-responsive-header">Average:</strong>
          <button
            title={currency(prices.average, null, true)}
            className="sm:ml-auto sm:block text-grey text-right"
            type="button"
            onClick={() =>
              updateSuggestedListPrice(
                report.id,
                subjectProperty.id,
                prices.average
              )
            }>
            <span className="sm:hidden md:inline-block">
              {currency(prices.average)}
            </span>
            <span className="hidden sm:inline-block md:hidden">
              {currency(prices.average, "$0[.]0a", false, 3)}
            </span>
          </button>
        </div>
        <div className="tabley-cell text-right">
          <strong className="tabley-responsive-header">High:</strong>
          <button
            title={currency(prices.high, null, true)}
            className="sm:ml-auto sm:block text-grey text-right"
            type="button"
            onClick={() =>
              updateSuggestedListPrice(
                report.id,
                subjectProperty.id,
                prices.high
              )
            }>
            <span className="sm:hidden md:inline-block">
              {currency(prices.high)}
            </span>
            <span className="hidden sm:inline-block md:hidden">
              {currency(prices.high, "$0[.]0a", false, 3)}
            </span>
          </button>
        </div>
        {report.includes.sqft && (
          <Fragment>
            <div className="tabley-cell text-right text-helix-brand">
              <strong className="tabley-responsive-header text-grey">
                $/sq ft:
              </strong>
              {!subjectProperty.sqft && (
                <Tooltip
                  text="You need subject property sq ft to set price"
                  className="flex sm:inline">
                  <span className="text-grey">{currency(roundedPPSqft)}</span>
                </Tooltip>
              )}
              {subjectProperty.sqft && (
                <Tooltip
                  text="Set the suggested list price based on this $/sq ft"
                  className="inline-block">
                  <button
                    title={currency(roundedPPSqft, null, true)}
                    className="sm:ml-auto sm:block text-helix-brand text-right"
                    type="button"
                    onClick={() =>
                      updateSuggestedListPrice(
                        report.id,
                        subjectProperty.id,
                        averagePPSqft
                      )
                    }>
                    {currency(roundedPPSqft)}
                  </button>
                </Tooltip>
              )}
            </div>
            <div className="tabley-cell text-right text-helix-brand">
              <strong className="tabley-responsive-header text-grey">
                Avg. &plusmn;3%:
              </strong>
              <button
                title={plusMinus3Percent}
                className="sm:ml-auto sm:block text-helix-brand text-right"
                type="button"
                onClick={() =>
                  updateSuggestedListPrice(
                    report.id,
                    subjectProperty.id,
                    plusMinus3Percent
                  )
                }>
                <span className="sm:hidden md:inline-block whitespace-no-wrap">
                  {plusMinus3Percent}
                </span>
                <span className="hidden sm:inline-block md:hidden whitespace-no-wrap">
                  {plusMinus3PercentShort}
                </span>
              </button>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  report: state.report,
  subjectProperty: state.subjectProperty
});

const mapDispatchToProps = {
  updateSuggestedListPrice
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingGuideRow);
