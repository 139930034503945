import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 15rem;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.2rem;
  text-decoration: none;
  transition: background-color 0.2s;
  color: ${({ theme }) => theme.colors.grayDark};
  border: 0.1rem dashed ${({ theme }) => theme.colors.grayLight};
  border-radius: ${({ theme }) => theme.borderRadius.xl};

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const Icon = styled.div`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};

  svg {
    width: 3rem;
    height: 3rem;
  }
`;
