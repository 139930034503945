import styled, { createGlobalStyle } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  .tox {
    &.tox-tinymce {
      border-color: ${({ theme }) =>
        theme.styleGuide.colors.gray[500]} !important;
      border-width: 0.1rem !important;
      border-radius: 0.4rem !important;

      .tox-statusbar {
        height: 4.1rem !important;
        border-color: ${({ theme }) =>
          theme.styleGuide.colors.gray[500]} !important;
        border-width: 0.1rem !important;
      }

      .tox-toolbar__primary {
        border-bottom: 0.1rem solid
          ${({ theme }) => theme.styleGuide.colors.gray[500]} !important;
        background: none !important;
      }
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  .tox {
    &.tox-tinymce-aux {
      z-index: 99999 !important;
    }

    .tox-notification.tox-notification--warning {
      display: none !important;
    }

    .tox-form__group {
      margin: 0 0 2rem !important;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .tox-form__group {
        margin: 0 !important;
        padding: 0 0.5rem 0 0 !important;

        &:first-child {
          padding-left: 0 !important;
        }
      }
    }

    .tox-label {
      display: inline-block !important;
      font-size: 1.2rem !important;
      font-weight: 600 !important;
      line-height: 1.6rem !important;
      margin-bottom: 0.3rem !important;
      color: ${({ theme }) => theme.styleGuide.colors.gray[800]} !important;
      vertical-align: middle !important;

      &[for] {
        cursor: pointer !important;
      }

      ${({ theme }) => theme.media.sm`
        font-size: 1.3rem !important;
        font-weight: 700 !important;
        line-height: 1.7rem !important;
      `}
    }

    .tox-textfield,
    .tox-textarea,
    .tox-selectfield select {
      display: block!important;
      width: ${({ isFullWidth }) => isFullWidth && "100%"}!important;
      line-height: 2rem!important;
      min-width: 0!important;
      padding: 0.7rem 1.2rem!important;
      border-radius: 0.4rem!important;
      border: 0.1rem solid ${({ theme }) =>
        theme.styleGuide.colors.gray[500]}!important;
      background-color: ${({ theme }) =>
        theme.styleGuide.colors.white}!important;
      color: ${({ theme }) => theme.styleGuide.colors.gray[900]}!important;
      appearance: none!important;
      outline: none!important;

      &:active,
      &:focus {
        border-color: ${({ theme }) =>
          theme.styleGuide.colors.helix['brand']}!important;
      }

      &::placeholder {
        color: ${({ theme }) => theme.styleGuide.colors.gray[600]}!important;
      }
    }

    .tox-dialog {
      padding: 2rem 0 !important;
      border: 0 !important;
      border-radius: ${({ theme }) => theme.borderRadius.lg} !important;
      box-shadow: ${({ theme }) => theme.styleGuide.shadows.modal} !important;
    }

    .tox-dialog__title {
      font-size: 2.4rem !important;
      font-weight: 400 !important;
      line-height: 3.3rem !important;
    }

    .tox-dialog__header,
    .tox-dialog__body-content,
    .tox-dialog__footer {
      padding: 0 2rem !important;
    }

    .tox-dialog__header {
      margin-bottom: 2rem !important;
    }

    .tox-dialog__body-nav-item {
      color: ${({ theme }) => theme.styleGuide.colors.gray[900]} !important;

      &:focus {
        background-color: transparent !important;
      }

      &.tox-dialog__body-nav-item--active {
        background-color: transparent !important;
        border-color: transparent !important;
        color: ${({ theme }) => theme.styleGuide.colors.helix['brand']} !important;
      }
    }

    .tox-dialog__footer {
      margin-top: 2rem !important;
      border: 0 !important;

      .tox-button {
        display: inline-flex !important;
        align-items: center !important;
        justify-content: center !important;
        width: ${({ full }) => (full ? "100%" : "auto")} !important;
        cursor: pointer !important;
        box-shadow: ${({ theme }) =>
          theme.styleGuide.shadows.raised} !important;
        border-radius: 0.4rem !important;
        text-decoration: none !important;
        appearance: none !important;
        outline: none !important;
        background-color: ${({ theme }) =>
          theme.styleGuide.colors.helix['light-green']} !important;
        border-color: ${({ theme }) =>
          theme.styleGuide.colors.helix['light-green']} !important;
        color: ${({ theme }) => theme.styleGuide.colors.white} !important;

        &:hover:not([disabled]) {
          background-color: ${({ theme }) =>
            theme.styleGuide.colors.helix['brand']} !important;
          border-color: ${({ theme }) =>
            theme.styleGuide.colors.helix['brand']} !important;
        }

        &:focus:not([disabled]) {
          box-shadow: 0 0 0 0.2rem ${({ theme }) =>
            theme.styleGuide.colors.helix['dark-green']} !important;
          background-color: ${({ theme }) =>
            theme.styleGuide.colors.helix['brand']} !important;
          border-color: ${({ theme }) =>
            theme.styleGuide.colors.helix['brand']} !important;
        }

        &:active:not([disabled]) {
          box-shadow: inset 0 0 0.2rem rgba(0, 0, 0, 0.2), inset 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1) !important;
        }

        &[disabled] {
          cursor: not-allowed !important;
          opacity: 0.5 !important;
        }

        &--secondary {
          box-shadow: none !important;
          background-color: transparent !important;
          border: 0.1rem solid ${({ theme }) =>
            theme.styleGuide.colors.helix['light-green']} !important;
          color: ${({ theme }) => theme.styleGuide.colors.helix['light-green']} !important;

          &:hover:not([disabled]) {
            color: ${({ theme }) =>
              theme.styleGuide.colors.helix['light-green']} !important;
            border-color: ${({ theme }) =>
              theme.styleGuide.colors.helix['brand']} !important;
            background-color: transparent !important;
          }

          &:focus:not([disabled]) {
            box-shadow: 0 0 0 0.2rem ${({ theme }) =>
              theme.styleGuide.colors.helix['mint-green']} !important;
            color: ${({ theme }) =>
              theme.styleGuide.colors.helix['light-green']} !important;
            border-color: ${({ theme }) =>
              theme.styleGuide.colors.helix['brand']} !important;
            background-color: transparent !important;
          }

          &:active:not([disabled]) {
            box-shadow: none !important;
            color: ${({ theme }) =>
              theme.styleGuide.colors.helix['dark-green']} !important;
            border-color: ${({ theme }) =>
              theme.styleGuide.colors.helix['light-green']} !important;
            background-color: transparent !important;
          }
        }
      }
    }
  }
`;
