import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  flex-shrink: 0;
  width: 4.4rem;
  height: 2.4rem;
  padding: 0.4rem;
  cursor: pointer;
  border: 0;
  box-shadow: inset 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1),
    inset 0 0 0.2rem rgba(0, 0, 0, 0.2);
  background-color: ${({ theme, color, isOn }) =>
    isOn
      ? color || theme.styleGuide.colors.helix['brand']
      : theme.styleGuide.colors.gray[500]};
  transition: background-color 0.2s, border-color 0.2s;
  appearance: none;

  &[type="button"] {
    border-radius: 10rem;
  }

  &:focus {
    background-color: ${({ theme, color, isOn }) =>
      isOn
        ? color || theme.styleGuide.colors.helix['brand']
        : theme.styleGuide.colors.gray[300]};
  }
`;

export const Pill = styled.span`
  display: block;
  width: 100%;
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 1.6rem;
    height: 1.6rem;
  }

  &:after {
    background-color: ${({ theme }) => theme.styleGuide.colors.white};
    border-radius: 50%;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1),
      0 0 0.2rem rgba(0, 0, 0, 0.2);
    transform: translateX(${({ isOn }) => (isOn ? "-100%" : "0%")});
    transition: left 0.1s, transform 0.1s;
    position: absolute;
    left: ${({ isOn }) => (isOn ? "100%" : "0%")};
    top: 0;
  }
`;
