import { Select } from "@helix/select";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { components as ReactSelect } from "react-select";
import { useSession } from "../../Session";
import Label from "../common/Label";
import ContactForm from "./ContactForm";
import {
  Button,
  Container,
  InputContainer,
  Option
} from "./styled/contact-lookup";

function ContactLookup(props) {
  const { currentUser, features } = useSession();

  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContacts, setSelectedContacts] = useState(
    (props.contacts || []).map((contact) => ({
      label: `${contact.first_name} ${contact.last_name}`,
      value: contact.id
    }))
  );

  const CustomOption = (props) => (
    <ReactSelect.Option {...props}>
      <Option>
        <p>{props.data.label}</p>
        <span>{props.data.address ?? "No address"}</span>
        {props.isSelected && (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.9962 4.93763C16.3068 5.21168 16.3364 5.68563 16.0624 5.99623L8.56238 14.4962C8.4254 14.6515 8.23034 14.7432 8.0234 14.7496C7.81647 14.7561 7.61607 14.6767 7.46967 14.5303L3.96967 11.0303C3.67678 10.7374 3.67678 10.2626 3.96967 9.96968C4.26256 9.67679 4.73744 9.67679 5.03033 9.96968L7.96582 12.9052L14.9376 5.00379C15.2117 4.6932 15.6856 4.66358 15.9962 4.93763Z"
            />
          </svg>
        )}
      </Option>
    </ReactSelect.Option>
  );

  const contacts = currentUser.contacts || [];
  const selectedContactIds = selectedContacts.map((c) => c.value);

  const contactOptions = contacts
    .filter((contact) => !selectedContactIds.includes(contact.id))
    .map((contact) => ({
      label: contact.name,
      value: contact.id,
      address: contact.address
    }));

  return (
    <>
      {!selectedContacts.length ? (
        <input type="hidden" name="cma[contact_ids][]" value="" />
      ) : (
        selectedContacts.map((contact) => (
          <input
            key={`sel-${contact.value}`}
            type="hidden"
            name="cma[contact_ids][]"
            value={contact.value}
          />
        ))
      )}
      {currentUser.lwaId && features.hasContacts && (
        <>
          <Container>
            <Label htmlFor="contact-lookup">
              <span>Associated Contact</span>
            </Label>
            <InputContainer>
              <Select
                aria-label="select"
                dataLwtId="MultiSelect"
                placeholder=""
                isClearable
                isMulti
                menuIsOpen={searchTerm.length > 0}
                inputValue={searchTerm}
                value={selectedContacts}
                options={contactOptions}
                onInputChange={(value) => setSearchTerm(value)}
                onChange={(value) => setSelectedContacts(value)}
                onClear={() => setSelectedContacts([])}
                components={{ Option: CustomOption }}
              />
              <Button
                type="button"
                app="cma"
                onClick={() => setShowModal(true)}>
                Create New
              </Button>
            </InputContainer>
          </Container>
          {showModal && (
            <ContactForm
              onSave={(contact) => {
                setSelectedContacts((contacts) => [
                  ...contacts,
                  { label: contact.name, value: contact.id }
                ]);
                setShowModal(false);
              }}
              onClose={() => setShowModal(false)}
            />
          )}
        </>
      )}
    </>
  );
}

ContactForm.defaultProps = {
  contacts: []
};

ContactForm.propTypes = {
  contacts: PropTypes.array
};

export default ContactLookup;
