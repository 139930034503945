import styled from "styled-components";

export const Text = styled.p`
  font-size: 1.3rem;
  line-height: 2rem;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    text-decoration: none;
  }
`;

export const Orders = styled.div`
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Order = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 2rem;
  }
`;

export const Title = styled.strong`
  font-size: 1.5rem;
  line-height: 2rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const Cancel = styled.button`
  padding: 0;
  cursor: pointer;
  appearance: none;
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  vertical-align: baseline;
  text-decoration: none;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    stroke: currentColor;
    transform: translateY(-0.1rem);
  }
`;

export const Details = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;
