import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 0 2rem 4rem;
`;

export const Checkbox = styled.div`
  display: inline-flex;
  align-items: center;
  line-height: 0;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grayDark};
`;

export const Input = styled.input`
  width: 0;
  height: 0;
  appearance: none;
  position: absolute;
  z-index: -1;
`;

export const Marker = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  background-color: ${({ theme, hasError, isActive }) =>
    hasError
      ? theme.styleGuide.colors.attract[500]
      : isActive
      ? theme.styleGuide.colors.helix['brand']
      : theme.styleGuide.colors.white};
  color: ${({ theme, hasError, isActive }) =>
    hasError
      ? theme.styleGuide.colors.attract[500]
      : isActive
      ? theme.styleGuide.colors.white
      : theme.styleGuide.colors.gray[600]};
  border: 0.1rem solid
    ${({ theme, hasError, isActive }) =>
      hasError
        ? theme.styleGuide.colors.attract[500]
        : isActive
        ? theme.styleGuide.colors.helix['brand']
        : theme.styleGuide.colors.gray[600]};
  border-radius: ${({ theme }) => theme.borderRadius.lg};

  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;
