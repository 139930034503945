import { Modal } from "@wrstudios/components";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { updatePageConfig } from "../../api/page";
import Button from "../common/Button";
import Input from "../common/Input";
import Loading from "../common/Loading";
import usePageConfig from "../common/usePageConfig";
import {
  Error,
  Label,
  ModalContent,
  ModalFooter,
  Note,
  Optional,
  Saved
} from "./styled/customize-modal-time-to-sell";

const MAX_NOTE_LENGTH = 240;

function CustomizeModalTimeToSell({ reportId, page, onClose }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [initialNote, setInitialNote] = useState("");
  const [note, setNote] = useState("");
  const [updateError, setUpdateError] = useState();
  const { isLoading, error } = usePageConfig({
    reportId,
    pageKey: page.key,
    onSuccess(config) {
      setNote(config.note || "");
      setInitialNote(config.note || "");
    }
  });

  const isMountedRef = useRef();
  useEffect(() => {
    isMountedRef.current = true;

    let timeout;
    if (isSaved) {
      timeout = setTimeout(() => {
        if (isMountedRef.current) {
          setIsSaved(false);
        }
      }, 2000);
    }

    return () => {
      isMountedRef.current = false;
      clearTimeout(timeout);
    };
  }, [isSaved]);

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <Modal.Header>
          Time to Sell <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Label htmlFor="note">
            Add a note <Optional>Optional</Optional>
          </Label>
          <Input
            id="note"
            as="textarea"
            rows={7}
            value={note}
            maxLength={MAX_NOTE_LENGTH}
            autoFocus
            placeholder="Add a note for your client"
            onChange={(e) => setNote(e.target.value)}
          />
          <Note>
            {note.length}/{MAX_NOTE_LENGTH} Characters
          </Note>
        </Modal.Body>
        <ModalFooter>
          {(error || updateError) && <Error>{error || updateError}</Error>}
          {isSaved && <Saved>Saved!</Saved>}
          <Button
            app="cma"
            disabled={!note || isUpdating || isLoading || note === initialNote}
            onClick={async () => {
              try {
                setIsUpdating(true);
                setUpdateError(undefined);
                await updatePageConfig(reportId, page.key, { note });
                setIsSaved(true);
                setInitialNote(note);
              } catch (error) {
                setUpdateError(error.message);
              } finally {
                setIsUpdating(false);
              }
            }}>
            <Loading showSpinner={isUpdating}>
              {isUpdating ? "Saving" : "Save"}
            </Loading>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

CustomizeModalTimeToSell.propTypes = {
  reportId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  reportId: state.report.id
});

export default connect(mapStateToProps)(CustomizeModalTimeToSell);
