import { css } from "styled-components";

export const colors = {
  white: "#fff",
  black: "#000",
  cream: "#fdeecd",
  gray: "#576474",
  grayAlt: "#89929d",
  grayDark: "#233040",
  grayLight: "#c5c9d1",
  grayLighter: "#e1e5e9",
  grayLighterSuper: "#f0f2f4",
  grayLightest: "#fafafa",
  green: "#20b575",
  greenDark: "#079c5c",
  greenLight: "#3acf8f",
  red: "#FD5154",
  redDark: "#E4383B",
  redLight: "#ec5252",
  blue: "#0AA1DC",
  blueAlt: "#3A7ABE",
  blueDark: "#177DA9",
  blueLight: "#5265ec",
  blueBaby: "#00c9c7",
  charcoal: "rgba(35, 48, 64, 0.7)",
  purple: "#9B52EC",
  purpleLight: "rgba(155, 82, 236, 0.05)",
  purpleDark: "#2d335d",
  purpleDarker: "#131943",
  highlight: "rgba(74, 144, 226, 0.1)",
  transparent: "transparent"
};

export const shadows = {
  flat: "none",
  raised: "0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.1)",
  overlay: "0 0.4rem 0.8rem 0 rgba(0, 0 ,0, 0.1)",
  // sticky: '0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.1)',
  sticky: "0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1)",
  stickyUp: "0 -0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1)",
  modal: "0 1.2rem 2.4rem 0 rgba(0, 0, 0, 0.1)"
};

export const borderRadius = {
  xs: "0.125rem",
  sm: "0.3rem",
  md: "0.4rem",
  lg: "0.5rem",
  xl: "0.6rem",
  xxl: "0.8rem",
  full: "9999px"
};

export const sizes = {
  xxs: 325,
  xs: 400,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};

export const media = Object.keys(sizes).reduce((acc, label) => {
  if (label === "sm" || label === "xs") {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label] - 1}px),
        (min-device-width: 414px) and (max-device-width: 736px) /* iPhone 6+ 7+ and 8+ */,
        (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) /* iPhone X */ {
        ${css(...args)};
      }
    `;
  } else {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label] - 1}px) {
        ${css(...args)};
      }
    `;
  }

  return acc;
}, {});

// TODO: Replace the items above
// TODO: Rename this to "theme"
// These should be used instead of the above.
// until these are replaces you'll see "theme.styleGuide.colors"
export const styleGuide = {
  fonts: {
    sansSerif:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Open Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif',
    monoSpaced:
      'SFMono-Medium, "SF Mono", "Segoe UI Mono", "Roboto Mono", "Ubuntu Mono", Menlo, Courier, monospace'
  },
  colors: {
    white: "#fff",
    black: "#000",
    helix: {
      'brand': "#236847",
      'dark-green': "#233d2d",
      'light-green': "#488563",
      'mint-green': "#b7dbb6",
      'white-green': "#e5ede7",
      'sec-orange': "#f15423",
      'sec-light-orange': "#f8baa7",
      'sec-white-orange': "#fdede9",
      'sec-blue': "#3e6fdf",
      'sec-light-blue': "#a6cdf4",
      'sec-white-blue': "#f1f5ff"
    },
    gray: {
      100: "#fff",
      200: "#f9fafb",
      300: "#f4f5f6",
      400: "#eeeff1",
      500: "#e2e5e8",
      600: "#9ba4b0",
      700: "#737e8c",
      800: "#4d5a6a",
      900: "#111827"
    },
    cma: {
      50: "#F3F4F6",
      100: "#DBEAFE",
      200: "#BFDBFE",
      300: "#93C5FD",
      400: "#60A5FA",
      500: "#3B82F6",
      600: "#2563EB",
      700: "#1D4ED8",
      800: "#1E40AF",
      900: "#1E3A8A"
    },
    streams: {
      100: "#e6f8ff",
      200: "#b4e9fd",
      300: "#6ed1f7",
      400: "#4dbbe6",
      500: "#0aa1dc",
      600: "#0690c6",
      700: "#036d96",
      800: "#014965",
      900: "#00374d"
    },
    mlx: {
      100: "#e7f2fe",
      200: "#bad8f7",
      300: "#7db1e8",
      400: "#5c97d6",
      500: "#3a7abe",
      600: "#2964a3",
      700: "#174b82",
      800: "#0a325c",
      900: "#00254d"
    },
    attract: {
      100: "#ffebeb",
      200: "#ffcccd",
      300: "#ff999b",
      400: "#ff8082",
      500: "#fd5154",
      600: "#dd3c3f",
      700: "#c32225",
      800: "#790608",
      900: "#4d0001"
    },
    suite: {
      100: "#e7eafe",
      200: "#d1d7fa",
      300: "#939fec",
      400: "#707edb",
      500: "#5362c6",
      600: "#2939a3",
      700: "#172582",
      800: "#0a155c",
      900: "#131943"
    },
    // todo: Remove this when it is replaced with the brand
    lw: {
      100: "#DBEAFE",
      200: "#BFDBFE",
      300: "#93C5FD",
      400: "#60A5FA",
      500: "#3B82F6",
      600: "#2563EB",
      700: "#1D4ED8",
      800: "#1E40AF",
      900: "#1E3A8A"
    },
    teal: {
      100: "#ccffff",
      200: "#9efaf9",
      300: "#75f0ef",
      400: "#52e0df",
      500: "#33cccc",
      600: "#1fadac",
      700: "#0f8a89",
      800: "#056160",
      900: "#003333"
    },
    green: {
      100: "#e7fee7",
      200: "#a3f5a3",
      300: "#7de87d",
      400: "#5cd65c",
      500: "#40bf40",
      600: "#29a329",
      700: "#178217",
      800: "#0a5c0a",
      900: "#033003"
    },
    yellow: {
      100: "#fef6e6",
      200: "#fde0b4",
      300: "#fbc97e",
      400: "#f6b655",
      500: "#eea02b",
      600: "#da870b",
      700: "#ad6a05",
      800: "#7c4c03",
      900: "#4d2e00"
    },
    blue: {
      100: "#e7eefe",
      200: "#bacff7",
      300: "#93b0ec",
      400: "#7094db",
      500: "#4775d1",
      600: "#2952a3",
      700: "#173b82",
      800: "#0a255c",
      900: "#041b49"
    },
    purple: {
      100: "#f2e7fe",
      200: "#d7baf7",
      300: "#bd93ec",
      400: "#a370db",
      500: "#8953c6",
      600: "#6f2eb8",
      700: "#561b98",
      800: "#310a5c",
      900: "#180330"
    },
    violet: {
      100: "#fce7fe",
      200: "#f4baf7",
      300: "#e793ec",
      400: "#d570db",
      500: "#b840bf",
      600: "#9c29a3",
      700: "#7c1782",
      800: "#570a5c",
      900: "#2e0330"
    },
    red: {
      100: "#fee7e7",
      200: "#f7baba",
      300: "#ef8f8f",
      400: "#e05252",
      500: "#d22d2d",
      600: "#b31a1a",
      700: "#9c1616",
      800: "#760a0a",
      900: "#4b0202"
    }
  },
  shadows: {
    raised: "0 0.1rem 0.2rem rgba(0, 0, 0, 0.1), 0 0 0.2rem rgba(0, 0, 0, 0.2)",
    raisedInset:
      "0 0.1rem 0.2rem rgba(0, 0, 0, 0.1) inset, 0 0 0.2rem rgba(0, 0, 0, 0.2) inset",
    sticky: "0 0.2rem 0.4rem rgba(0, 0, 0, 0.1), 0 0 0.1rem rgba(0, 0, 0, 0.2)",
    stickyInset:
      "0 0.2rem 0.4rem rgba(0, 0, 0, 0.1) inset, 0 0 0.1rem rgba(0, 0, 0, 0.2) inset",
    overlay:
      "0 0.4rem 0.8rem rgba(0, 0, 0, 0.1), 0 0 0.1rem rgba(0, 0, 0, 0.2)",
    overlayInset:
      "0 0.4rem 0.8rem rgba(0, 0, 0, 0.1) inset, 0 0 0.1rem rgba(0, 0, 0, 0.2) inset",
    modal: "0 1.2rem 2.4rem rgba(0, 0, 0, 0.1), 0 0 0.1rem rgba(0, 0, 0, 0.2)",
    modalInset:
      "0 1.2rem 2.4rem rgba(0, 0, 0, 0.1) inset, 0 0 0.1rem rgba(0, 0, 0, 0.2) inset"
  },
  breakpoints: {
    xs: 400,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  }
};

function getStatusColor(status) {
  switch (status) {
    case "active":
      return styleGuide.colors.green[500];
    case "closed":
      return styleGuide.colors.red[500];
    case "pending":
      return styleGuide.colors.yellow[500];
    case "backup":
      return styleGuide.colors.teal[500];
    case "subject":
      return styleGuide.colors.purple[500];
    default:
      return styleGuide.colors.gray[900];
  }
}

function getStatusColorSpectrum(status) {
  switch (status) {
    case "active":
      return styleGuide.colors.green;
    case "closed":
      return styleGuide.colors.red;
    case "pending":
      return styleGuide.colors.yellow;
    case "backup":
      return styleGuide.colors.teal;
    case "subject":
      return styleGuide.colors.purple;
    default:
      return styleGuide.colors.gray;
  }
}

function getColorSpectrumFromColor(color) {
  return (
    Object.values(styleGuide.colors).find((spectrum) => {
      if (typeof spectrum !== "object") return;
      return Object.values(spectrum)
        .map((color) => color.toLowerCase())
        .includes((color || "").toLowerCase());
    }) || styleGuide.colors.gray
  );
}

export default {
  colors,
  shadows,
  borderRadius,
  sizes,
  media,
  styleGuide,
  getStatusColor,
  getStatusColorSpectrum,
  getColorSpectrumFromColor
};
