import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  z-index: 10;
`;

export const Content = styled.div`
  padding: 2rem;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
`;

export const Header = styled.h2`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  margin: 0;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.7rem;
    line-height: 2.4rem;
  `}
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Progress = styled.div`
  height: 0.5rem;
  margin: 1rem 0;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[500]};
`;

export const ProgressBar = styled.div`
  height: 100%;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
`;
