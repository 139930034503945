import { Modal as BaseModal } from "@wrstudios/components";
import styled from "styled-components";
import BaseFlash from "../../common/Flash";

export const ModalHeader = styled.div`
  padding: 2.4rem 3.2rem 1.6rem;
`;

export const ModalClose = styled(BaseModal.Close)`
  position: absolute;
  top: 2.6rem;
  right: 2.6rem;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.6rem;
  color: #374151;
  margin: 0;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #374151;
  margin-top: 1.2rem;
`;

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  height: 100%;
  padding: 0;
  max-width: 87rem;
  position: relative;

  ${({ theme }) => theme.media.md`
    max-width: 100%;
  `}

  .annotationLayer {
    display: none;
  }
`;

export const ModalBody = styled(BaseModal.Body)`
  padding: 0;
  margin: 0;
  border-bottom-left-radius: ${({ hideFooter }) => (hideFooter ? 0 : "0.6rem")};
  border-bottom-right-radius: ${({ hideFooter }) =>
    hideFooter ? 0 : "0.6rem"};
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.2rem;
  padding: 2.4rem 3.2rem;
`;

export const Flash = styled(BaseFlash)`
  font-weight: 400;
  border-radius: 0;

  svg {
    flex-shrink: 0;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    color: ${({ theme }) => theme.styleGuide.colors.yellow[600]};
  }
`;

export const FlashContent = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
`;

export const Edit = styled.button`
  font-size: 1.4rem;
  font-weight: 700;
  appearance: none;
  cursor: pointer;
  color: ${({ theme }) => theme.styleGuide.colors.cma[700]};
`;

export const Message = styled.span`
  display: inline-block;
  margin-right: 0.8rem;
`;

export const Close = styled.button`
  padding: 0.95rem 1.6rem;
  background: #e5e7eb;
  border-radius: 0.6rem;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #4b5563;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

export const Confirm = styled.a`
  text-decoration: none;
  padding: 0.95rem 1.6rem;
  background: #236847;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2), 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
  border-radius: 0.6rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;
