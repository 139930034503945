import styled from "styled-components";
import { default as BaseButton } from "../../common/Button";

export const Container = styled.div`
  position: relative;
  width: 100%;

  .helix-select {
    &__wrapper {
      width: 100%;
    }

    &__control {
      border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
      border-radius: 0.4rem !important;

      &--is-focused {
        box-shadow: none !important;
        border-color: ${({ theme }) =>
          theme.styleGuide.colors.helix["brand"]} !important;
        outline: solid 1px
          ${({ theme }) => theme.styleGuide.colors.helix["brand"]} !important;
      }
    }

    &__menu {
      svg {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        path {
          fill: ${({ theme }) =>
            theme.styleGuide.colors.helix["brand"]} !important;
        }
      }

      .helix-select__option {
        &.helix-select__option--is-focused,
        &:hover:not(.helix-select__option--is-disabled) {
          background-color: ${({ theme }) =>
            theme.styleGuide.colors.gray[300]} !important;
        }
      }
    }
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  gap: 1.2rem;
`;

export const Button = styled(BaseButton)`
  width: 112px;
  max-height: 36px !important;
  white-space: nowrap;
  align-self: flex-start;
`;

export const Option = styled.div`
  position: relative;

  p {
    font-size: 1.4rem;
    font-weight: 600;
    color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  }

  span {
    font-size: 1.2rem;
  }
`;
