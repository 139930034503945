import styled from "styled-components";

export const Container = styled.label`
  cursor: pointer;
`;

export const Circle = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0.4rem;
  border-radius: 50%;
  border: 0.2rem solid ${({ theme }) => theme.colors.grayLight};

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const Input = styled.input`
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;

  &:focus + ${Circle} {
    border-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  }

  &:checked + ${Circle} {
    border-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};

    &:after {
      background-color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    }
  }
`;
