import React, { Component } from "react";
import PropTypes from "prop-types";

class LinkToggler extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool,
    tip: PropTypes.string,
    buttonAriaLabel: PropTypes.string.isRequired,
    onToggle: PropTypes.func
  };

  static defaultProps = {
    open: false,
    onToggle: function () {}
  };

  state = {
    isOpen: this.props.open
  };

  handleClick() {
    this.setState(
      (prevState) => ({ isOpen: !prevState.isOpen }),
      () => this.props.onToggle(this.state.isOpen)
    );
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(props);
  }

  render() {
    return (
      <div
        className={`link-toggle ${
          this.state.isOpen ? "link-toggle-open" : "link-toggle-closed"
        }`}>
        <div className="flex">
          <button
            type="button"
            className="flex items-center helix-text-brand text-base leading-2xl link-toggle-cta mr-5"
            onClick={this.handleClick.bind(this)}
            aria-expanded={this.state.isOpen}
            aria-label={`Button: ${!this.state.isOpen ? "Open" : "Close"} ${
              this.props.buttonAriaLabel
            }`}>
            {this.props.title}
          </button>
          {this.props.tip && (
            <span className="flex items-center text-sm text-grey-dark mt-1">
              {this.props.tip}
            </span>
          )}
        </div>
        <div className={`mt-2 ${this.state.isOpen ? "block" : "hidden"}`}>
          <div className="flex flex-wrap -m-4">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default LinkToggler;
