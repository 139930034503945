import React, { Component, Fragment, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createObjectUrl, revokeObjectUrl } from "@wrstudios/utils";
import { CropperModal } from "@wrstudios/components";
import { colors } from "../../../../theme";
import { removePhoto } from "../../../../actions/subject-property";
import AddressAutoComplete from "../../../address/AddressAutoComplete";
import { Select, Option } from "../../../common/Select";
import Button from "../../../common/Button";
import Input from "../../../common/Input";
import Label from "../../../common/Label";
import AlertDanger from "../../../common/AlertDanger";
import AdvancedOptions from "../../../options/AdvancedOptions";
import IconDelete from "../../../icons/material/action/IconDelete";
import IconPhoto from "../../../icons/IconPhoto";
import {
  Photo,
  PhotoFile,
  PhotoFileInner,
  PhotoFileInput,
  PhotoFilePicker,
  PhotoFilePickerIcon,
  PhotoFilePickerText,
  PhotoFilePreview,
  PhotoFileControls,
  PhotoFileControlsChange,
  PhotoFileControlsDelete
} from "./styled/subject-property-form-partial";

class SubjectPropertyFormPartial extends Component {
  fileInputRef = createRef();

  state = {
    ...this.props,
    taxDataLoaded: false,
    hasTaxData: false,
    focusSqft: false,
    hasPhotoBeenSelected: Boolean(this.props.files.length),
    photoUrl: this.props.files.length >= 1 ? this.props.files[0] : "",
    showCropper: false,
    cropData: null,
    showAlert: false,
    fileKey: Math.random(),
    additionalMeasurements: {}
  };

  render() {
    const { address, sqft_type, land_measurement, property, sub_property } =
      this.props;
    const {
      options,
      additional_options,
      focusSqft,
      hasPhotoBeenSelected,
      photoUrl,
      showCropper,
      cropData,
      showAlert,
      additionalMeasurements
    } = this.state;

    if (this.sqft && focusSqft) this.sqft.focus();

    return (
      <Fragment>
        <div className="well">
          <h2 className="well-header">Subject Property</h2>
          <div className="my-8">
            <AddressAutoComplete
              {...address}
              onSelect={this.handleOnAddressChange}
            />
          </div>
          <div className="flex flex-wrap -m-4">
            <div className="flex flex-row w-full">
              {options.map((option, index) => (
                <div
                  key={index}
                  className={`p-4 ${
                    option.key === "sqft" ? "w-1/2" : "w-1/4"
                  }`}>
                  <Label htmlFor={option.name}>{option.label}</Label>

                  <div className="flex cma-input-text-select">
                    <Input
                      ref={(input) => !index && (this.sqft = input)}
                      id={option.name}
                      name={option.name}
                      value={option.value || ""}
                      className="input"
                      style={
                        option.key === "sqft" && sqft_type.enabled
                          ? {
                              borderRadius: "0.4rem 0 0 0.4rem",
                              zIndex: "2",
                              maxWidth: "70px",
                              ...option.style
                            }
                          : option.style
                      }
                      onChange={(e) =>
                        this.handleOnOptionChange(option.name, e.target.value)
                      }
                    />
                    {option.key === "sqft" && sqft_type.enabled ? (
                      <Select
                        aria-label="Property Square Foot Type"
                        defaultValue={
                          sqft_type.selected && sqft_type.selected.value
                        }
                        id="subject-property-sqft-type"
                        name="subject_property[sqft_type]"
                        style={
                          option.key === "sqft" && sqft_type.enabled
                            ? {
                                borderRadius: "0 0.4rem 0.4rem 0",
                                backgroundColor: "rgba(243,244,246,0.4)",
                                left: "-1px"
                              }
                            : {}
                        }>
                        {sqft_type.options.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full lg:w-1/2 p-4">
              {property.enabled && (
                <div>
                  <label htmlFor="cma-property-type" className="label">
                    Property Type
                  </label>
                  <Select
                    defaultValue={(property.selected || {}).value}
                    id="cma-property-type"
                    name="cma[prop_type]">
                    {property.options.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}

              {sub_property.enabled && (
                <div className="mt-8">
                  <label htmlFor="cma-property-sub-type" className="label">
                    Property Sub Type
                  </label>
                  <Select
                    defaultValue={(sub_property.selected || {}).value}
                    id="cma-property-sub-type"
                    name="cma[prop_sub_type]">
                    <Option value="">None</Option>
                    {sub_property.options.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
            </div>
            <div className="flex w-full lg:w-1/2 p-4">
              <Photo>
                <label
                  htmlFor="subject-property-photo"
                  className="flex-no-shrink label">
                  Property Photo
                </label>
                {cropData && (
                  <Fragment>
                    <input
                      type="hidden"
                      name="subject_property[photo][crop][width]"
                      value={cropData.width}
                    />
                    <input
                      type="hidden"
                      name="subject_property[photo][crop][height]"
                      value={cropData.height}
                    />
                    <input
                      type="hidden"
                      name="subject_property[photo][crop][x]"
                      value={cropData.x}
                    />
                    <input
                      type="hidden"
                      name="subject_property[photo][crop][y]"
                      value={cropData.y}
                    />
                  </Fragment>
                )}
                <PhotoFileInput
                  key={this.state.fileKey}
                  ref={this.fileInputRef}
                  type="file"
                  id="subject-property-photo"
                  name="subject_property[photo][file]"
                  onChange={this.handleOnPhotoChange}
                />
                {showCropper && (
                  <CropperModal
                    imageUrl={photoUrl}
                    onClose={this.handleOnClose}
                    footer={({ getData, getDataURL }) => (
                      <Button
                        app="cma"
                        onClick={() =>
                          this.handleOnCrop({
                            cropData: getData(),
                            photoUrl: getDataURL()
                          })
                        }>
                        Crop
                      </Button>
                    )}
                  />
                )}
                <PhotoFile>
                  <PhotoFileInner>
                    {!hasPhotoBeenSelected && (
                      <PhotoFilePicker
                        htmlFor="subject-property-photo"
                        style={{ color: "#236847" }}>
                        <PhotoFilePickerIcon>
                          <IconPhoto />
                        </PhotoFilePickerIcon>
                        <PhotoFilePickerText>
                          Add cover photo
                        </PhotoFilePickerText>
                      </PhotoFilePicker>
                    )}
                    {hasPhotoBeenSelected && (
                      <PhotoFilePreview
                        style={{ backgroundImage: `url('${photoUrl}')` }}>
                        <PhotoFileControls>
                          <PhotoFileControlsChange
                            type="button"
                            onClick={this.handleOnPhotoOpen}>
                            Change
                          </PhotoFileControlsChange>
                          <PhotoFileControlsDelete
                            type="button"
                            onClick={this.handleOnPhotoDelete}>
                            <IconDelete />
                            Delete
                          </PhotoFileControlsDelete>
                        </PhotoFileControls>
                      </PhotoFilePreview>
                    )}
                  </PhotoFileInner>
                </PhotoFile>
              </Photo>
            </div>
          </div>
          <div className="mt-8">
            <AdvancedOptions
              title="Advanced Info"
              tip="Additional subject info used in four column layouts"
              options={additional_options.options}
              land_measurement={land_measurement}
              additionalMeasurements={additionalMeasurements}
            />
          </div>
        </div>
        {showAlert && (
          <AlertDanger
            title="Delete Photo"
            confirmButton="Delete Forever"
            onClose={() => this.setState({ showAlert: false })}
            onConfirm={this.handleOnDeleteConfirm}>
            Are you sure you want to remove the subject property photo?
          </AlertDanger>
        )}
      </Fragment>
    );
  }

  handleOnAddressChange = ({ value }, taxData) => {
    const { address, additional_options } = this.state;
    let { options } = this.state;
    const taxDataLoaded = true;

    // Remap lotsize to lot_size
    if (taxData.lotsize) taxData.lot_size = taxData.lotsize;

    const hasTaxData = !!Object.keys(taxData).length;

    address.address = value;

    options = this.fillOptionsTaxData(options, taxData);

    this.setState(
      {
        address,
        options,
        taxDataLoaded,
        hasTaxData,
        focusSqft: !hasTaxData
      },
      () => {
        const additionalOptions = this.fillOptionsTaxData(
          additional_options.options,
          taxData
        );
        this.setState({
          additional_options: { options: additionalOptions }
        });
      }
    );
    this.setState({
      additionalMeasurements: {
        acres: taxData.acres || null,
        sqft: taxData.lotsize || taxData.lot_size || null
      }
    });
  };

  fillOptionsTaxData = (options, taxData) => {
    const hasTaxData = !!Object.keys(taxData).length;

    return options.map((option) => {
      if (hasTaxData) {
        for (let o in taxData) {
          if (option.key === o && !!taxData[o] && taxData[o] !== "0") {
            option.value = taxData[o];
            option.style = { color: colors.purple };
          }
        }
      } else {
        option.value = "";
      }
      return option;
    });
  };

  handleOnOptionChange = (name, value) => {
    const { options } = this.state;
    const newOptions = options.map((option) => {
      if (option.name === name) {
        option.value = value;
        option.style = {};
      }

      return option;
    });

    this.setState({ options: newOptions, focusSqft: false });
  };

  handleOnPhotoChange = (e) => {
    revokeObjectUrl(this.state.photoUrl);

    const hasFiles = !!e.target.files.length;
    let newState = {
      files: e.target.files,
      photoUrl: e.target.files[0] ? createObjectUrl(e.target.files[0]) : "",
      showCropper: hasFiles
    };

    if (!hasFiles) {
      newState = {
        ...newState,
        photoUrl: "",
        cropData: null,
        hasPhotoBeenSelected: false
      };
    }

    this.setState(newState);
  };

  handleOnPhotoDelete = () => {
    this.setState({ showAlert: true });
  };

  handleOnPhotoOpen = () => {
    this.setState({ fileKey: Math.random() }, () => {
      this.fileInputRef.current.click();
    });
  };

  handleOnCrop = ({ cropData, photoUrl }) => {
    this.setState({
      cropData,
      photoUrl,
      hasPhotoBeenSelected: true,
      showCropper: false
    });
  };

  handleOnClose = () => {
    this.setState({ fileKey: Math.random(), showCropper: false });
  };

  handleOnDeleteConfirm = () => {
    revokeObjectUrl(this.state.photoUrl);
    this.setState(
      {
        photoUrl: "",
        hasPhotoBeenSelected: false,
        cropData: null,
        showAlert: false,
        fileKey: Math.random()
      },
      () => {
        this.props.removePhoto(
          this.props.report.id,
          this.props.subjectProperty.id
        );
      }
    );
  };
}

SubjectPropertyFormPartial.propTypes = {
  address: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  property: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.object
  }).isRequired,
  sqft_type: PropTypes.shape({
    enabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.object
  }).isRequired,
  land_measurement: PropTypes.shape({
    enabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.object
  }),
  additional_options: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  files: PropTypes.arrayOf(PropTypes.string).isRequired,
  report: PropTypes.shape({
    id: PropTypes.number
  }),
  subjectProperty: PropTypes.shape({
    id: PropTypes.number
  }),
  removePhoto: PropTypes.func.isRequired,
  additionalMeasurements:
    PropTypes.shape({}) ||
    PropTypes.shape({
      acres: PropTypes.number || null,
      sqft: PropTypes.number || null
    })
};

const mapStateToProps = ({ report, subjectProperty }) => ({
  report,
  subjectProperty
});

const mapDispatchToProps = {
  removePhoto
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectPropertyFormPartial);
