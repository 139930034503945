import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "../../Icon";

function IconLayoutThreeProperties(props) {
  return (
    <Icon width="233" height="303" viewBox="0 0 233 303" {...props}>
      {!props.hover && (
        <Fragment>
          <path
            fill="#c4c8d0"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#576474"
            d="M58.91 70.52a1.5 1.5 0 0 1-1.5-1.5V57.13A1.49 1.49 0 0 1 58 56l-11.5-9.65L35 56a1.49 1.49 0 0 1 .58 1.18V69a1.5 1.5 0 0 1-3 0V58.63h-1.33a1.51 1.51 0 0 1-1.42-1 1.53 1.53 0 0 1 .45-1.63l15.26-12.75a1.48 1.48 0 0 1 1.92 0L62.72 56a1.5 1.5 0 0 1-1 2.65h-1.31V69a1.5 1.5 0 0 1-1.5 1.52z"
          />
          <path fill="#e1e5e9" d="M49.11 69.02v-8.55h-5.22v8.55" />
          <path
            fill="#576474"
            d="M49.11 70.52a1.5 1.5 0 0 1-1.5-1.5V62h-2.22v7a1.5 1.5 0 0 1-3 0v-8.53a1.5 1.5 0 0 1 1.5-1.5h5.22a1.5 1.5 0 0 1 1.5 1.5V69a1.5 1.5 0 0 1-1.5 1.52z"
          />
          <path
            fill="#c4c8d0"
            d="M66.59 83.5H26.41A6.46 6.46 0 0 1 20 77.05V35.69a6.47 6.47 0 0 1 6.46-6.45h40.63a6 6 0 0 1 6 6v41.81a6.46 6.46 0 0 1-6.5 6.45zM26.41 32.24A3.46 3.46 0 0 0 23 35.69v41.36a3.46 3.46 0 0 0 3.46 3.45h40.13a3.46 3.46 0 0 0 3.46-3.45V35.2a3 3 0 0 0-3-3zM61.28 98.86h9.48v3h-9.48zM23.95 98.86H56.1v3H23.95zM23.95 111.82h46.81v3H23.95zM34.99 124.78h35.77v3H34.99zM23.95 124.78h5.5v3h-5.5zM23.95 148.17h9.48v3h-9.48zM38.61 148.17h32.15v3H38.61zM23.95 161.13h46.81v3H23.95zM23.95 174.09h35.77v3H23.95zM65.26 174.09h5.5v3h-5.5zM61.28 195.52h9.48v3h-9.48zM23.95 195.52H56.1v3H23.95zM23.95 208.48h46.81v3H23.95zM34.99 221.44h35.77v3H34.99zM23.95 221.44h5.5v3h-5.5zM23.95 244.84h9.48v3h-9.48zM38.61 244.84h32.15v3H38.61zM23.95 257.8h46.81v3H23.95zM23.95 270.76h35.77v3H23.95zM65.26 270.76h5.5v3h-5.5z"
          />
          <path
            fill="#576474"
            d="M128.91 70.52a1.5 1.5 0 0 1-1.5-1.5V57.13A1.49 1.49 0 0 1 128 56l-11.49-9.6L105 56a1.49 1.49 0 0 1 .58 1.18V69a1.5 1.5 0 1 1-3 0V58.63h-1.34a1.51 1.51 0 0 1-1.42-1 1.53 1.53 0 0 1 .45-1.67l15.26-12.73a1.48 1.48 0 0 1 1.92 0L132.72 56a1.5 1.5 0 0 1-1 2.65h-1.34V69a1.5 1.5 0 0 1-1.47 1.52z"
          />
          <path fill="#e1e5e9" d="M119.11 69.02v-8.55h-5.22v8.55" />
          <path
            fill="#576474"
            d="M119.11 70.52a1.5 1.5 0 0 1-1.5-1.5V62h-2.22v7a1.5 1.5 0 0 1-3 0v-8.53a1.5 1.5 0 0 1 1.5-1.5h5.22a1.5 1.5 0 0 1 1.5 1.5V69a1.5 1.5 0 0 1-1.5 1.52z"
          />
          <path
            fill="#c4c8d0"
            d="M136.59 83.5H96.41A6.46 6.46 0 0 1 90 77.05V35.69a6.47 6.47 0 0 1 6.46-6.45h40.68a6 6 0 0 1 6 6v41.81a6.46 6.46 0 0 1-6.55 6.45zM96.41 32.24A3.46 3.46 0 0 0 93 35.69v41.36a3.46 3.46 0 0 0 3.46 3.45h40.18a3.46 3.46 0 0 0 3.46-3.45V35.2a3 3 0 0 0-3-3zM93.95 98.86h9.48v3h-9.48zM108.61 98.86h32.15v3h-32.15zM93.95 111.82h46.81v3H93.95zM93.95 124.78h35.77v3H93.95zM135.26 124.78h5.5v3h-5.5zM131.28 148.17h9.48v3h-9.48zM93.95 148.17h32.15v3H93.95zM93.95 161.13h46.81v3H93.95zM104.99 174.09h35.77v3h-35.77zM93.95 174.09h5.5v3h-5.5zM93.95 195.52h9.48v3h-9.48zM108.61 195.52h32.15v3h-32.15zM93.95 208.48h46.81v3H93.95zM93.95 221.44h35.77v3H93.95zM135.26 221.44h5.5v3h-5.5zM131.28 244.84h9.48v3h-9.48zM93.95 244.84h32.15v3H93.95zM93.95 257.8h46.81v3H93.95zM104.99 270.76h35.77v3h-35.77zM93.95 270.76h5.5v3h-5.5z"
          />
          <path
            fill="#576474"
            d="M198.91 70.52a1.5 1.5 0 0 1-1.5-1.5V57.13A1.49 1.49 0 0 1 198 56l-11.49-9.6L175 56a1.49 1.49 0 0 1 .58 1.18V69a1.5 1.5 0 1 1-3 0V58.63h-1.34a1.51 1.51 0 0 1-1.42-1 1.53 1.53 0 0 1 .45-1.67l15.26-12.73a1.48 1.48 0 0 1 1.92 0L202.72 56a1.5 1.5 0 0 1-1 2.65h-1.34V69a1.5 1.5 0 0 1-1.47 1.52z"
          />
          <path fill="#e1e5e9" d="M189.11 69.02v-8.55h-5.22v8.55" />
          <path
            fill="#576474"
            d="M189.11 70.52a1.5 1.5 0 0 1-1.5-1.5V62h-2.22v7a1.5 1.5 0 0 1-3 0v-8.53a1.5 1.5 0 0 1 1.5-1.5h5.22a1.5 1.5 0 0 1 1.5 1.5V69a1.5 1.5 0 0 1-1.5 1.52z"
          />
          <path
            fill="#c4c8d0"
            d="M206.59 83.5h-40.18a6.46 6.46 0 0 1-6.41-6.45V35.69a6.47 6.47 0 0 1 6.46-6.45h40.68a6 6 0 0 1 6 6v41.81a6.46 6.46 0 0 1-6.55 6.45zm-40.18-51.26a3.46 3.46 0 0 0-3.41 3.45v41.36a3.46 3.46 0 0 0 3.46 3.45h40.18a3.46 3.46 0 0 0 3.46-3.45V35.2a3 3 0 0 0-3-3zM201.28 98.86h9.48v3h-9.48zM163.95 98.86h32.15v3h-32.15zM163.95 111.82h46.81v3h-46.81zM174.99 124.78h35.77v3h-35.77zM163.95 124.78h5.5v3h-5.5zM163.95 148.17h9.48v3h-9.48zM178.61 148.17h32.15v3h-32.15zM163.95 161.13h46.81v3h-46.81zM163.95 174.09h35.77v3h-35.77zM205.26 174.09h5.5v3h-5.5zM201.28 195.52h9.48v3h-9.48zM163.95 195.52h32.15v3h-32.15zM163.95 208.48h46.81v3h-46.81zM174.99 221.44h35.77v3h-35.77zM163.95 221.44h5.5v3h-5.5zM163.95 244.84h9.48v3h-9.48zM178.61 244.84h32.15v3h-32.15zM163.95 257.8h46.81v3h-46.81zM163.95 270.76h35.77v3h-35.77zM205.26 270.76h5.5v3h-5.5z"
          />
        </Fragment>
      )}
      {props.hover && (
        <Fragment>
          <path
            fill="#236847"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#236847"
            d="M58.91 70.52a1.5 1.5 0 0 1-1.5-1.5V57.13A1.49 1.49 0 0 1 58 56l-11.5-9.65L35 56a1.49 1.49 0 0 1 .58 1.18V69a1.5 1.5 0 0 1-3 0V58.63h-1.33a1.51 1.51 0 0 1-1.42-1 1.53 1.53 0 0 1 .45-1.63l15.26-12.75a1.48 1.48 0 0 1 1.92 0L62.72 56a1.5 1.5 0 0 1-1 2.65h-1.31V69a1.5 1.5 0 0 1-1.5 1.52z"
          />
          <path fill="#e1e5e9" d="M49.11 69.02v-8.55h-5.22v8.55" />
          <path
            fill="#236847"
            d="M49.11 70.52a1.5 1.5 0 0 1-1.5-1.5V62h-2.22v7a1.5 1.5 0 0 1-3 0v-8.53a1.5 1.5 0 0 1 1.5-1.5h5.22a1.5 1.5 0 0 1 1.5 1.5V69a1.5 1.5 0 0 1-1.5 1.52z"
          />
          <path
            fill="#236847"
            d="M66.59 83.5H26.41A6.46 6.46 0 0 1 20 77.05V35.69a6.47 6.47 0 0 1 6.46-6.45h40.63a6 6 0 0 1 6 6v41.81a6.46 6.46 0 0 1-6.5 6.45zM26.41 32.24A3.46 3.46 0 0 0 23 35.69v41.36a3.46 3.46 0 0 0 3.46 3.45h40.13a3.46 3.46 0 0 0 3.46-3.45V35.2a3 3 0 0 0-3-3zM61.28 98.86h9.48v3h-9.48zM23.95 98.86H56.1v3H23.95zM23.95 111.82h46.81v3H23.95zM34.99 124.78h35.77v3H34.99zM23.95 124.78h5.5v3h-5.5zM23.95 148.17h9.48v3h-9.48zM38.61 148.17h32.15v3H38.61zM23.95 161.13h46.81v3H23.95zM23.95 174.09h35.77v3H23.95zM65.26 174.09h5.5v3h-5.5zM61.28 195.52h9.48v3h-9.48zM23.95 195.52H56.1v3H23.95zM23.95 208.48h46.81v3H23.95zM34.99 221.44h35.77v3H34.99zM23.95 221.44h5.5v3h-5.5zM23.95 244.84h9.48v3h-9.48zM38.61 244.84h32.15v3H38.61zM23.95 257.8h46.81v3H23.95zM23.95 270.76h35.77v3H23.95zM65.26 270.76h5.5v3h-5.5zM128.91 70.52a1.5 1.5 0 0 1-1.5-1.5V57.13A1.49 1.49 0 0 1 128 56l-11.49-9.6L105 56a1.49 1.49 0 0 1 .58 1.18V69a1.5 1.5 0 1 1-3 0V58.63h-1.34a1.51 1.51 0 0 1-1.42-1 1.53 1.53 0 0 1 .45-1.67l15.26-12.73a1.48 1.48 0 0 1 1.92 0L132.72 56a1.5 1.5 0 0 1-1 2.65h-1.34V69a1.5 1.5 0 0 1-1.47 1.52z"
          />
          <path fill="#e1e5e9" d="M119.11 69.02v-8.55h-5.22v8.55" />
          <path
            fill="#236847"
            d="M119.11 70.52a1.5 1.5 0 0 1-1.5-1.5V62h-2.22v7a1.5 1.5 0 0 1-3 0v-8.53a1.5 1.5 0 0 1 1.5-1.5h5.22a1.5 1.5 0 0 1 1.5 1.5V69a1.5 1.5 0 0 1-1.5 1.52z"
          />
          <path
            fill="#236847"
            d="M136.59 83.5H96.41A6.46 6.46 0 0 1 90 77.05V35.69a6.47 6.47 0 0 1 6.46-6.45h40.68a6 6 0 0 1 6 6v41.81a6.46 6.46 0 0 1-6.55 6.45zM96.41 32.24A3.46 3.46 0 0 0 93 35.69v41.36a3.46 3.46 0 0 0 3.46 3.45h40.18a3.46 3.46 0 0 0 3.46-3.45V35.2a3 3 0 0 0-3-3zM93.95 98.86h9.48v3h-9.48zM108.61 98.86h32.15v3h-32.15zM93.95 111.82h46.81v3H93.95zM93.95 124.78h35.77v3H93.95zM135.26 124.78h5.5v3h-5.5zM131.28 148.17h9.48v3h-9.48zM93.95 148.17h32.15v3H93.95zM93.95 161.13h46.81v3H93.95zM104.99 174.09h35.77v3h-35.77zM93.95 174.09h5.5v3h-5.5zM93.95 195.52h9.48v3h-9.48zM108.61 195.52h32.15v3h-32.15zM93.95 208.48h46.81v3H93.95zM93.95 221.44h35.77v3H93.95zM135.26 221.44h5.5v3h-5.5zM131.28 244.84h9.48v3h-9.48zM93.95 244.84h32.15v3H93.95zM93.95 257.8h46.81v3H93.95zM104.99 270.76h35.77v3h-35.77zM93.95 270.76h5.5v3h-5.5zM198.91 70.52a1.5 1.5 0 0 1-1.5-1.5V57.13A1.49 1.49 0 0 1 198 56l-11.49-9.6L175 56a1.49 1.49 0 0 1 .58 1.18V69a1.5 1.5 0 1 1-3 0V58.63h-1.34a1.51 1.51 0 0 1-1.42-1 1.53 1.53 0 0 1 .45-1.67l15.26-12.73a1.48 1.48 0 0 1 1.92 0L202.72 56a1.5 1.5 0 0 1-1 2.65h-1.34V69a1.5 1.5 0 0 1-1.47 1.52z"
          />
          <path fill="#e1e5e9" d="M189.11 69.02v-8.55h-5.22v8.55" />
          <path
            fill="#236847"
            d="M189.11 70.52a1.5 1.5 0 0 1-1.5-1.5V62h-2.22v7a1.5 1.5 0 0 1-3 0v-8.53a1.5 1.5 0 0 1 1.5-1.5h5.22a1.5 1.5 0 0 1 1.5 1.5V69a1.5 1.5 0 0 1-1.5 1.52z"
          />
          <path
            fill="#236847"
            d="M206.59 83.5h-40.18a6.46 6.46 0 0 1-6.41-6.45V35.69a6.47 6.47 0 0 1 6.46-6.45h40.68a6 6 0 0 1 6 6v41.81a6.46 6.46 0 0 1-6.55 6.45zm-40.18-51.26a3.46 3.46 0 0 0-3.41 3.45v41.36a3.46 3.46 0 0 0 3.46 3.45h40.18a3.46 3.46 0 0 0 3.46-3.45V35.2a3 3 0 0 0-3-3zM201.28 98.86h9.48v3h-9.48zM163.95 98.86h32.15v3h-32.15zM163.95 111.82h46.81v3h-46.81zM174.99 124.78h35.77v3h-35.77zM163.95 124.78h5.5v3h-5.5zM163.95 148.17h9.48v3h-9.48zM178.61 148.17h32.15v3h-32.15zM163.95 161.13h46.81v3h-46.81zM163.95 174.09h35.77v3h-35.77zM205.26 174.09h5.5v3h-5.5zM201.28 195.52h9.48v3h-9.48zM163.95 195.52h32.15v3h-32.15zM163.95 208.48h46.81v3h-46.81zM174.99 221.44h35.77v3h-35.77zM163.95 221.44h5.5v3h-5.5zM163.95 244.84h9.48v3h-9.48zM178.61 244.84h32.15v3h-32.15zM163.95 257.8h46.81v3h-46.81zM163.95 270.76h35.77v3h-35.77zM205.26 270.76h5.5v3h-5.5z"
          />
        </Fragment>
      )}
    </Icon>
  );
}

IconLayoutThreeProperties.propTypes = {
  hover: PropTypes.bool
};

IconLayoutThreeProperties.defaultProps = {
  hover: false
};

export default IconLayoutThreeProperties;
