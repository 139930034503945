import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "../../Icon";

function IconLayoutTwoProperties(props) {
  return (
    <Icon width="233" height="303" viewBox="0 0 233 303" {...props}>
      {!props.hover && (
        <Fragment>
          <path
            fill="#c4c8d0"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#576474"
            d="M83.22 95.81a1.5 1.5 0 0 1-1.5-1.5V77.42a1.49 1.49 0 0 1 1.4-1.49L65.58 61.28 48.05 75.93a1.47 1.47 0 0 1 1 .43 1.52 1.52 0 0 1 .44 1.06v16.89a1.5 1.5 0 0 1-1.5 1.5 1.5 1.5 0 0 1-1.5-1.5V78.92h-2.58a1.5 1.5 0 0 1-1.41-1 1.52 1.52 0 0 1 .5-1.65l21.62-18.09a1.5 1.5 0 0 1 1.93 0l21.67 18.09a1.52 1.52 0 0 1 .45 1.66 1.5 1.5 0 0 1-1.42 1h-2.53v15.38a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path fill="#e1e5e9" d="M69.29 94.31V82.16h-7.41v12.15" />
          <path
            fill="#576474"
            d="M69.29 95.81a1.5 1.5 0 0 1-1.5-1.5V83.66h-4.41v10.65a1.5 1.5 0 0 1-3 0V82.16a1.5 1.5 0 0 1 1.5-1.5h7.41a1.5 1.5 0 0 1 1.5 1.5v12.15a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path
            fill="#c4c8d0"
            d="M94.13 114.25H37a8.55 8.55 0 0 1-8.54-8.54V47A8.55 8.55 0 0 1 37 38.43h57.8a7.84 7.84 0 0 1 7.83 7.83v59.45a8.55 8.55 0 0 1-8.5 8.54zM37 41.43A5.55 5.55 0 0 0 31.5 47v58.74a5.55 5.55 0 0 0 5.5 5.51h57.13a5.55 5.55 0 0 0 5.54-5.54V46.26a4.84 4.84 0 0 0-4.83-4.83z"
          />
          <path
            fill="#576474"
            d="M185.05 95.81a1.5 1.5 0 0 1-1.5-1.5V77.42a1.49 1.49 0 0 1 1.4-1.49l-17.53-14.65-17.54 14.65a1.49 1.49 0 0 1 1.4 1.49v16.89a1.5 1.5 0 0 1-3 0V78.92h-2.53a1.5 1.5 0 0 1-1.42-1 1.52 1.52 0 0 1 .45-1.66l21.67-18.09a1.5 1.5 0 0 1 1.93 0l21.67 18.09a1.52 1.52 0 0 1 .45 1.66 1.5 1.5 0 0 1-1.41 1h-2.54v15.39a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path fill="#e1e5e9" d="M171.12 94.31V82.16h-7.41v12.15" />
          <path
            fill="#576474"
            d="M171.12 95.81a1.5 1.5 0 0 1-1.5-1.5V83.66h-4.41v10.65a1.5 1.5 0 0 1-3 0V82.16a1.5 1.5 0 0 1 1.5-1.5h7.41a1.5 1.5 0 0 1 1.5 1.5v12.15a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path
            fill="#c4c8d0"
            d="M196 114.25h-57.13a8.55 8.55 0 0 1-8.54-8.54V47a8.55 8.55 0 0 1 8.54-8.54h57.8a7.84 7.84 0 0 1 7.83 7.83v59.45a8.55 8.55 0 0 1-8.5 8.51zm-57.13-72.82a5.55 5.55 0 0 0-5.54 5.57v58.74a5.55 5.55 0 0 0 5.54 5.54H196a5.55 5.55 0 0 0 5.54-5.54V46.26a4.84 4.84 0 0 0-4.83-4.83zM86.58 137.32h13.47v3H86.58zM33.55 137.32h45.67v3H33.55zM33.55 155.73h66.5v3h-66.5zM49.23 174.15h50.82v3H49.23zM33.55 174.15h7.81v3h-7.81zM33.55 207.38h13.47v3H33.55zM54.38 207.38h45.67v3H54.38zM33.55 225.79h66.5v3h-66.5zM33.55 244.2h50.82v3H33.55zM92.24 244.2h7.81v3h-7.81zM135.05 137.32h13.47v3h-13.47zM155.88 137.32h45.67v3h-45.67zM135.05 155.73h66.5v3h-66.5zM135.05 174.15h50.82v3h-50.82zM193.74 174.15h7.81v3h-7.81zM188.08 207.38h13.47v3h-13.47zM135.05 207.38h45.67v3h-45.67zM135.05 225.79h66.5v3h-66.5zM150.73 244.2h50.82v3h-50.82zM135.05 244.2h7.81v3h-7.81z"
          />
        </Fragment>
      )}
      {props.hover && (
        <Fragment>
          <path
            fill="#236847"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#236847"
            d="M83.22 95.81a1.5 1.5 0 0 1-1.5-1.5V77.42a1.49 1.49 0 0 1 1.4-1.49L65.58 61.28 48.05 75.93a1.47 1.47 0 0 1 1 .43 1.52 1.52 0 0 1 .44 1.06v16.89a1.5 1.5 0 0 1-1.5 1.5 1.5 1.5 0 0 1-1.5-1.5V78.92h-2.58a1.5 1.5 0 0 1-1.41-1 1.52 1.52 0 0 1 .5-1.65l21.62-18.09a1.5 1.5 0 0 1 1.93 0l21.67 18.09a1.52 1.52 0 0 1 .45 1.66 1.5 1.5 0 0 1-1.42 1h-2.53v15.38a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path fill="#e1e5e9" d="M69.29 94.31V82.16h-7.41v12.15" />
          <path
            fill="#236847"
            d="M69.29 95.81a1.5 1.5 0 0 1-1.5-1.5V83.66h-4.41v10.65a1.5 1.5 0 0 1-3 0V82.16a1.5 1.5 0 0 1 1.5-1.5h7.41a1.5 1.5 0 0 1 1.5 1.5v12.15a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path
            fill="#236847"
            d="M94.13 114.25H37a8.55 8.55 0 0 1-8.54-8.54V47A8.55 8.55 0 0 1 37 38.43h57.8a7.84 7.84 0 0 1 7.83 7.83v59.45a8.55 8.55 0 0 1-8.5 8.54zM37 41.43A5.55 5.55 0 0 0 31.5 47v58.74a5.55 5.55 0 0 0 5.5 5.51h57.13a5.55 5.55 0 0 0 5.54-5.54V46.26a4.84 4.84 0 0 0-4.83-4.83zM185.05 95.81a1.5 1.5 0 0 1-1.5-1.5V77.42a1.49 1.49 0 0 1 1.4-1.49l-17.53-14.65-17.54 14.65a1.49 1.49 0 0 1 1.4 1.49v16.89a1.5 1.5 0 0 1-3 0V78.92h-2.53a1.5 1.5 0 0 1-1.42-1 1.52 1.52 0 0 1 .45-1.66l21.67-18.09a1.5 1.5 0 0 1 1.93 0l21.67 18.09a1.52 1.52 0 0 1 .45 1.66 1.5 1.5 0 0 1-1.41 1h-2.54v15.39a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path fill="#e1e5e9" d="M171.12 94.31V82.16h-7.41v12.15" />
          <path
            fill="#236847"
            d="M171.12 95.81a1.5 1.5 0 0 1-1.5-1.5V83.66h-4.41v10.65a1.5 1.5 0 0 1-3 0V82.16a1.5 1.5 0 0 1 1.5-1.5h7.41a1.5 1.5 0 0 1 1.5 1.5v12.15a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path
            fill="#236847"
            d="M196 114.25h-57.13a8.55 8.55 0 0 1-8.54-8.54V47a8.55 8.55 0 0 1 8.54-8.54h57.8a7.84 7.84 0 0 1 7.83 7.83v59.45a8.55 8.55 0 0 1-8.5 8.51zm-57.13-72.82a5.55 5.55 0 0 0-5.54 5.57v58.74a5.55 5.55 0 0 0 5.54 5.54H196a5.55 5.55 0 0 0 5.54-5.54V46.26a4.84 4.84 0 0 0-4.83-4.83zM86.58 137.32h13.47v3H86.58zM33.55 137.32h45.67v3H33.55zM33.55 155.73h66.5v3h-66.5zM49.23 174.15h50.82v3H49.23zM33.55 174.15h7.81v3h-7.81zM33.55 207.38h13.47v3H33.55zM54.38 207.38h45.67v3H54.38zM33.55 225.79h66.5v3h-66.5zM33.55 244.2h50.82v3H33.55zM92.24 244.2h7.81v3h-7.81zM135.05 137.32h13.47v3h-13.47zM155.88 137.32h45.67v3h-45.67zM135.05 155.73h66.5v3h-66.5zM135.05 174.15h50.82v3h-50.82zM193.74 174.15h7.81v3h-7.81zM188.08 207.38h13.47v3h-13.47zM135.05 207.38h45.67v3h-45.67zM135.05 225.79h66.5v3h-66.5zM150.73 244.2h50.82v3h-50.82zM135.05 244.2h7.81v3h-7.81z"
          />
        </Fragment>
      )}
    </Icon>
  );
}

IconLayoutTwoProperties.propTypes = {
  hover: PropTypes.bool
};

IconLayoutTwoProperties.defaultProps = {
  hover: false
};

export default IconLayoutTwoProperties;
