import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import IconKeyboardArrowRight from "../icons/material/hardware/IconKeyboardArrowRight";
import IconKeyboardArrowDown from "../icons/material/hardware/IconKeyboardArrowDown";
import CustomizeInactivePage from "./CustomizeInactivePage";

class CustomizeInactivePagesGroup extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired
      })
    )
  };

  state = {
    open: false
  };

  toggle() {
    this.setState(({ open }) => ({ open: !open }));
  }

  render() {
    const { title, pages } = this.props;
    const { open } = this.state;

    return (
      <Fragment>
        <button
          className="flex items-center text-sbase text-grey-darker leading-sxl mt-6"
          type="button"
          onClick={this.toggle.bind(this)}>
          <span className="text-helix-brand mr-3">
            {!open && <IconKeyboardArrowRight width={24} height={24} />}
            {open && <IconKeyboardArrowDown width={24} height={24} />}
          </span>
          <span className="text-left">{title}</span>
        </button>
        {open && (
          <div className="mt-6">
            {pages.map((page, childIndex) => (
              <div key={childIndex} className={childIndex > 0 ? "mt-4" : ""}>
                <CustomizeInactivePage page={page} />
              </div>
            ))}
          </div>
        )}
      </Fragment>
    );
  }
}

export default CustomizeInactivePagesGroup;
