import styled from "styled-components";

export const Text = styled.p`
  font-size: ${({ normal }) => (normal ? "inherit" : "1.3rem")};
  line-height: ${({ normal }) => (normal ? "2.4rem" : "2rem")};
  margin-bottom: 2rem;
  text-align: ${({ align = "left" }) => align};

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
    text-decoration: none;
  }
`;
