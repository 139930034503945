import { getProxiedImageUrl } from "@wrstudios/image-proxy";
import { getStatusColor as _getStatusColor } from "@wrstudios/theme";
import {
  formatCurrency,
  getDateLong,
  getDateShort,
  getDateTime
} from "@wrstudios/utils";
import { compact, orderBy, parseInt } from "lodash";
import titleize from "titleize";
import { getDistanceBetweenTwoLatLngPoints } from "./math";

export function transformListing(listing, photoProxy) {
  return {
    id: getId(listing),
    recordId: getRecordId(listing),
    coords: getCoords(listing),
    address: getAddress(listing),
    street: getStreet(listing),
    city: getCity(listing),
    state: getState(listing),
    zip: getZip(listing),
    saleRent: getSaleRent(listing),

    cityStateZip: getCityStateZip(listing),
    originalListPrice: getOriginalListPrice(listing),
    listPrice: getListPrice(listing),
    listDate: getListDate(listing),
    soldPrice: getSoldPrice(listing),
    soldDate: getSoldDate(listing),
    price: getPrice(listing),
    adjustedPrice: getAdjustedPrice(listing),
    pricePerSqft: getPricePerSqft(listing),
    pricePerSqftByLotSize: getPricePerSqftByLotSize(listing),
    adjustedPricePerSqft: getAdjustedPricePerSqft(listing),
    adjustedPricePerSqftByLotSize: getAdjustedPricePerSqftByLotSize(listing),
    pricePerAcre: getPricePerAcre(listing),
    adjustedPricePerAcre: getAdjustedPricePerAcre(listing),
    soldToListPricePercentage: getSoldToListPricePercentage(listing),
    beds: getBeds(listing),
    baths: getBaths(listing),
    bathsFull: getBathsFull(listing),
    bathsThreeQuarter: getBathsThreeQuarter(listing),
    bathsHalf: getBathsHalf(listing),
    bathsQuarter: getBathsQuarter(listing),
    formattedBaths: getFormattedBaths(listing),
    dom: getDOM(listing),
    sqft: getSqft(listing),
    acres: getAcres(listing),
    lotSize: getLotSize(listing),
    area: getArea(listing),
    lotDims: getLotDim(listing),
    garages: getGarages(listing),
    hasGarage: getHasGarage(listing),
    yearBuilt: getYearBuilt(listing),
    taxes: getTaxes(listing),
    remarks: getRemarks(listing),
    note: getNote(listing),
    status: getStatus(listing),
    statusColor: getStatusColor(listing),
    statusAsLetter: getStatusAsLetter(listing),
    mappedStatus: getMappedStatus(listing),
    mlsNumber: getMLSNumber(listing),
    photos: getPhotos(listing, photoProxy),
    features: getFeatures(listing),
    virtualTour: getFeature("Virtual Tour", listing),
    schools: getSchools(listing),
    updatedAt: getUpdatedAt(listing),
    updatedAtDateShort: getUpdatedAtDateShort(listing),
    updatedAtTime: getUpdatedAtTime(listing),
    listingOffice: getListOffice(listing),
    adjustments: getAdjustments(listing),
    adjustmentsTotal: getAdjustmentsTotal(listing),
    zestimate: getZestimate(listing),
    isAcreage: isAcreage(listing),
    isGhost: isGhost(listing), // TODO: IDK if we need this
    isForgeListing: isForgeListing(listing), // TODO: IDK if we need this
    isMyRecentSalesListing: isMyRecentSalesListing(listing),
    isHidden: isHidden(listing),
    isPriceAdjusted: isPriceAdjusted(listing),
    isPriceAdjustedPositive: isPriceAdjustedPositive(listing),
    isPriceAdjustedNegative: isPriceAdjustedNegative(listing),
    assocFee: getAssocFee(listing),

    schoolDistrict: getSchoolDistrict(listing),
    schoolElementary: getSchoolElementary(listing),
    schoolHigh: getSchoolHigh(listing),
    schoolMiddle: getSchoolMiddle(listing),
    history: getHistory(listing),
    subdivision: getSubdivison(listing),
    style: getStyle(listing),
    county: getCounty(listing)
  };
}

export function getSchoolDistrict(listing) {
  return (listing.data || {}).schoolDistrict;
}
export function getSchoolElementary(listing) {
  return (listing.data || {}).schoolElementary;
}
export function getSchoolHigh(listing) {
  return (listing.data || {}).schoolHigh;
}
export function getSchoolMiddle(listing) {
  return (listing.data || {}).schoolMiddle;
}

export function transformZestimate(listing) {
  return getZestimate(listing);
}

export function transformSchools(schools) {
  return getSchools({ schools });
}

export function getTag(listing, listings, subjectProperty) {
  // Is this listing one of the most recently sold listing ids?
  const mostRecentlySoldListingIds = getMostRecentlySoldListings(listings).map(
    (listing) => listing.recordId
  );
  if (mostRecentlySoldListingIds.includes(listing.recordId)) {
    return "Most Recent Sale";
  }

  // Is this listing one of the closest listings to the subject property?
  const closestListingsToSubjectPropertyIds =
    getClosestListingsToSubjectProperty(listings, subjectProperty).map(
      (listing) => listing.recordId
    );
  if (closestListingsToSubjectPropertyIds.includes(listing.recordId)) {
    return "Closest to You";
  }

  // Is this listing one of the most expensive listings?
  const mostExpensiveListingIds = getMostExpensiveListings(listings).map(
    (listing) => listing.recordId
  );
  if (mostExpensiveListingIds.includes(listing.recordId)) {
    return "Most Expensive";
  }

  return "";
}

// * This is the db record of the listing from cma.
export function getRecordId(listing) {
  return listing.id || "";
}

// * This could be a listing id or a cma record id
// * depending on if it was a manual listing or not
export function getId(listing) {
  return (listing.data || {}).id || listing.id;
}

export function getLat(listing) {
  return Number(listing.lat || listing.geoLat || (listing.data || {}).lat || 0);
}

export function getLon(listing) {
  return Number(listing.lon || listing.geoLon || (listing.data || {}).lon || 0);
}

export function getArea(listing) {
  return listing.area || (listing.data || {}).area;
}

export function getAssocFee(listing) {
  return (listing.data || {}).assocFee;
}

function getSaleRent(listing) {
  return (listing.data || {}).saleRent;
}

export function getCoords(listing) {
  return {
    lat: getLat(listing),
    lon: getLon(listing)
  };
}

// * If the original price isn't found, use the price list
// * If the price list isn't found use the price
export function getOriginalListPrice(listing) {
  return Number((listing.data || {}).priceListOrig || 0);
}

export function getListPrice(listing) {
  return (
    listing.priceList || (listing.data || {}).priceList || getPrice(listing)
  );
}

export function getListDate(listing) {
  return listing.dateList || (listing.data || {}).dateList || "";
}

export function getSoldPrice(listing) {
  return listing.priceSold || (listing.data || {}).priceSold || 0;
}

export function getSoldDate(listing) {
  const soldDate = listing.dateSold || (listing.data || {}).dateSold || "";
  const soldPrice = getSoldPrice(listing);
  return soldPrice ? soldDate : "";
}

export function getPrice(listing) {
  return Number(listing.priceRaw || listing.price || 0);
}

// * This will use price if no adjusted price is found
export function getAdjustedPrice(listing) {
  const price = getPrice(listing);
  const adjustmentsTotal = getAdjustmentsTotal(listing);
  return price + adjustmentsTotal;
}

export function getBeds(listing) {
  return Number(listing.beds || (listing.data || {}).beds || 0);
}

export function getBaths(listing) {
  const bathsFull = getBathsFull(listing);
  const bathsThreeQuarter = getBathsThreeQuarter(listing);
  const bathsHalf = getBathsHalf(listing);
  const bathsQuarter = getBathsQuarter(listing);
  const bathsTotal = bathsFull + bathsThreeQuarter + bathsHalf + bathsQuarter;
  return Number(listing.baths || (listing.data || {}).baths || 0) || bathsTotal;
}

export function getBathsFull(listing) {
  return Number(listing.bathsFull || (listing.data || {}).bathsFull || 0);
}

export function getBathsThreeQuarter(listing) {
  return Number(
    listing.bathsThreeQuarter || (listing.data || {}).bathsThreeQuarter || 0
  );
}

export function getBathsHalf(listing) {
  return Number(listing.bathsHalf || (listing.data || {}).bathsHalf || 0);
}

export function getBathsQuarter(listing) {
  return Number(listing.bathsQuarter || (listing.data || {}).bathsQuarter || 0);
}

export function getFormattedBaths(listing) {
  const bathsFull = getBathsFull(listing);
  const bathsThreeQuarter = getBathsThreeQuarter(listing);
  const bathsHalf = getBathsHalf(listing);
  const bathsQuarter = getBathsQuarter(listing);
  return [
    bathsFull ? `${bathsFull} full` : "",
    bathsThreeQuarter ? `${bathsThreeQuarter} three quarter` : "",
    bathsHalf ? `${bathsHalf} half` : "",
    bathsQuarter ? `${bathsQuarter} quarter` : ""
  ]
    .filter(Boolean)
    .join(", ");
}

export function getSqft(listing) {
  return Number(listing.sqft || (listing.data || {}).sqft || 0);
}

export function getGarages(listing) {
  return Number(listing.garages || (listing.data || {}).garages || 0);
}

export function getHasGarage(listing) {
  const hasGarage = getFeature("Garage Yn", listing);
  return hasGarage === "True";
}

export function getYearBuilt(listing) {
  return Number(listing.yearBuilt || (listing.data || {}).yearBuilt || 0);
}

export function getTaxes(listing) {
  return Number(listing.taxes || (listing.data || {}).taxes || "");
}

export function getRemarks(listing) {
  return listing.remarks || (listing.data || {}).remarks || "";
}

export function getNote(listing) {
  return listing.note || "";
}

export function getLotSize(listing) {
  return Number(listing.lotsize || (listing.data || {}).lotsize || 0);
}

export function getLotDim(listing) {
  return listing.lotdim || (listing.data || {}).lotdim || "";
}

export function getStatus(listing) {
  return listing.status || (listing.data || {}).status || "";
}

export function getStatusColor(listing) {
  const mappedStatus = getMappedStatus(listing);
  return listing.statusAsColor
    ? `#${listing.statusAsColor || "000"}`
    : _getStatusColor(mappedStatus.toLowerCase());
}

export function getStatusAsLetter(listing) {
  return listing.statusAsLetter || (listing.data || {}).statusAsLetter || "";
}

export function getMappedStatus(listing) {
  return listing.mappedStatus || (listing.data || {}).mappedStatus || "";
}

export function getAdjustments(listing) {
  return listing.adjustments || (listing.data || {}).adjustments || [];
}

export function getMLSNumber(listing) {
  return listing.mlsnum || (listing.data || {}).mlsnum || "";
}

export function getListOffice(listing) {
  return listing.officeList || (listing.data || {}).officeList || {};
}

export function getUpdatedAt(listing) {
  return listing.updatedAt || (listing.data || {}).updatedAt || "";
}

export function getUpdatedAtDateShort(listing) {
  const updatedAt = getUpdatedAt(listing);
  return getDateShort(updatedAt);
}

export function getUpdatedAtTime(listing) {
  const updatedAt = getUpdatedAt(listing);
  return getDateTime(updatedAt);
}

export function getDOM(listing) {
  return Number(listing.dom || (listing.data || {}).dom || 0);
}

export function getAddress(listing) {
  return listing.address || (listing.data || {}).address || "";
}

export function getStreet(listing) {
  return listing.street || (listing.data || {}).street || "";
}

export function getCity(listing) {
  return listing.city || (listing.data || {}).city || "";
}

export function getState(listing) {
  return listing.state || (listing.data || {}).state || "";
}

export function getZip(listing) {
  return listing.zip || (listing.data || {}).zip || "";
}

export function getAcres(listing) {
  return Number(listing.acres || (listing.data || {}).acres || 0);
}

function getSubdivison(listing) {
  return listing.subdivision || (listing.data || {}).subdivision || "";
}

function getStyle(listing) {
  return listing.style || (listing.data || {}).style || "";
}

function getCounty(listing) {
  return listing.county || (listing.data || {}).county || "";
}

export function getCityStateZip(listing) {
  const city = getCity(listing);
  const state = getState(listing);
  const zip = getZip(listing);
  return [city, [state, zip].filter(Boolean).join(" ")]
    .filter(Boolean)
    .join(", ");
}

export function getPricePerSqft(listing) {
  const price = getPrice(listing);
  const sqft = getSqft(listing);
  if (!price || !sqft) return 0;
  return price / sqft;
}

export function getPricePerSqftByLotSize(listing) {
  const price = getPrice(listing);
  const sqft = getLotSize(listing);
  if (!price || !sqft) return 0;
  return price / sqft;
}

export function getPricePerAcre(listing) {
  const price = getPrice(listing);
  const acres = getAcres(listing);
  if (!price || !acres) return 0;
  return price / acres;
}

export function getSoldToListPricePercentage(listing) {
  const originalListPrice = getOriginalListPrice(listing);
  const soldPrice = getSoldPrice(listing);
  if (!soldPrice || !originalListPrice) return 0;
  return (soldPrice / originalListPrice) * 100;
}

export function getAdjustedPricePerSqft(listing) {
  const price = getPrice(listing);
  const sqft = getSqft(listing);
  const adjustmentsTotal = getAdjustmentsTotal(listing);
  const adjustedPrice = price + adjustmentsTotal;
  if (!adjustedPrice || !sqft) return 0;
  return adjustedPrice / sqft;
}

export function getAdjustedPricePerSqftByLotSize(listing) {
  const price = getPrice(listing);
  const sqft = getLotSize(listing);
  const adjustmentsTotal = getAdjustmentsTotal(listing);
  const adjustedPrice = price + adjustmentsTotal;
  if (!adjustedPrice || !sqft) return 0;
  return adjustedPrice / sqft;
}

export function getAdjustedPricePerAcre(listing) {
  const price = getPrice(listing);
  const acres = getAcres(listing);
  const adjustmentsTotal = getAdjustmentsTotal(listing);
  const adjustedPrice = price + adjustmentsTotal;
  if (!adjustedPrice || !acres) return 0;
  return adjustedPrice / acres;
}

export function getAdjustmentsTotal(listing) {
  return getAdjustments(listing).reduce(
    (total, adjustment) => total + adjustment.value,
    0
  );
}

export function getZestimate(listing) {
  const [zestimate, url] = listing.zestimate || [0, ""];
  let parsedZestimated = Number(zestimate);
  if (Number.isNaN(parsedZestimated)) parsedZestimated = 0;
  const price = getPrice(listing);
  const hasPriceAndZestimate = !!price && !!parsedZestimated;
  const priceDifference = hasPriceAndZestimate ? price - parsedZestimated : 0;
  const priceDifferencePercentage = hasPriceAndZestimate
    ? (parsedZestimated / price) * 100 - 100
    : 0;
  return {
    value: parsedZestimated,
    priceDifference,
    priceDifferencePercentage,
    url
  };
}

export function getPhotos(listing, photoProxy) {
  if (
    listing.data &&
    (!listing.mlsPhotoProxy || listing.data.source === "manual")
  ) {
    return listing.photos || (listing.data || {}).photos || [];
  }

  return (listing.photos || (listing.data || {}).photos || []).map((photo) =>
    getProxiedImageUrl({
      url: photo,
      strategy: photoProxy
    })
  );
}

export function getPropertyType(listing) {
  const propertyType = (listing.data || {}).propType || "";

  try {
    let parsedPropertyType = JSON.parse(propertyType);

    if (Array.isArray(parsedPropertyType)) {
      parsedPropertyType = parsedPropertyType.join(", ");
    }

    return parsedPropertyType;
  } catch (e) {
    return propertyType;
  }
}

export function getPropertySubType(listing) {
  const propertySubType = (listing.data || {}).propSubType || "";

  try {
    let parsedPropertySubType = JSON.parse(propertySubType);

    if (Array.isArray(parsedPropertySubType)) {
      parsedPropertySubType = parsedPropertySubType.join(", ");
    }

    return parsedPropertySubType;
  } catch (e) {
    return propertySubType;
  }
}

export function getFeatures(listing) {
  let features = listing.features || (listing.data || {}).features || {};
  if (typeof features === "string") features = JSON.parse(features);

  // Add aditional features
  features.propertyType = getPropertyType(listing);
  features.propertySubType = getPropertySubType(listing);
  features.lotDimensions = getLotDim(listing);
  features.assocFee =
    getAssocFee(listing) === null
      ? "N/A"
      : formatCurrency(getAssocFee(listing));

  const sortedFeatures = Object.keys(features)
    .sort()
    .reduce((sortedFeatures, key) => {
      if (features[key]) sortedFeatures[key] = features[key];
      return sortedFeatures;
    }, {});

  return Object.entries(sortedFeatures).map(([field, value]) => ({
    field: titleize(field.replace(/([a-z0-9])([A-Z])/g, "$1 $2")),
    value
  }));
}

export function getFeature(featureName, listing) {
  const features = getFeatures(listing);
  const feature =
    features.find((feature) => feature.field === featureName) || {};
  return feature.value || "";
}

export function getSchools(listing) {
  const schools = listing.schools || (listing.data || {}).schools || [];
  return schools.map((school) => ({
    title: school.name,
    grades: school.gradeRange,
    distance: `${new Intl.NumberFormat().format(school.distance)} mi`,
    address: `${school.street}, ${school.city}, ${school.state} ${school.zip}`,
    raiting: school.rating,
    profilePage: school.overviewUrl
  }));
}

export function getMostRecentlySoldListings(listings) {
  const soldListings = listings.filter(getSoldPrice);
  if (!listings.length || !soldListings.length) return [];
  const sortedSoldListings = orderBy(soldListings, getSoldDate, "desc");
  const [recentlySoldListing] = sortedSoldListings;
  const recentlySoldListingDate = getSoldDate(recentlySoldListing);
  const recentlySoldListingTime = new Date(recentlySoldListingDate).getTime();
  return soldListings.filter((listing) => {
    const soldDate = getSoldDate(listing);
    const soldTime = new Date(soldDate).getTime();
    return soldTime === recentlySoldListingTime;
  });
}

export function getClosestListingsToSubjectProperty(listings, subjectProperty) {
  if (
    !listings.length ||
    !subjectProperty.coords.lat ||
    !subjectProperty.coords.lon
  ) {
    return [];
  }
  const sortedListings = orderBy(listings, (listing) => {
    const distance = getDistanceBetweenTwoLatLngPoints(
      [listing.coords.lat, listing.coords.lon],
      [subjectProperty.coords.lat, subjectProperty.coords.lon]
    );
    return distance;
  });
  const [closestListing] = sortedListings;
  const closestListingDistance = getDistanceBetweenTwoLatLngPoints(
    [closestListing.coords.lat, closestListing.coords.lon],
    [subjectProperty.coords.lat, subjectProperty.coords.lon]
  );
  return sortedListings.filter((listing) => {
    const listingDistance = getDistanceBetweenTwoLatLngPoints(
      [listing.coords.lat, listing.coords.lon],
      [subjectProperty.coords.lat, subjectProperty.coords.lon]
    );
    return listingDistance === closestListingDistance;
  });
}

export function getMostExpensiveListings(listings) {
  if (!listings.length) return [];
  const sortedListings = orderBy(listings, getAdjustedPrice, "desc");
  const [mostExpensiveListing] = sortedListings;
  const mostExpensiveListingAdjustedPrice =
    getAdjustedPrice(mostExpensiveListing);
  return sortedListings.filter((listing) => {
    const adjustedPrice = getAdjustedPrice(listing);
    return adjustedPrice === mostExpensiveListingAdjustedPrice;
  });
}

export function getClosedListings(listings, activeStatus) {
  if (!listings.length) return [];
  return listings.filter((listing) => {
    // Expired, Withdrawn, Cancelled, Hold
    if (["x", "w", "c", "h"].includes(listing.statusAsLetter.toLowerCase())) {
      return false;
    }
    return !activeStatus || listing.mappedStatus === activeStatus;
  });
}

export function isAcreage(listing) {
  const beds = getBeds(listing);
  const baths = getBaths(listing);
  const sqft = getSqft(listing);
  const garages = getGarages(listing);
  const lotSize = getLotSize(listing);
  const acres = getAcres(listing);
  const details = [beds, baths, sqft, garages].filter(Boolean);
  const hasDetails = !!details.length;

  if (!!lotSize && !acres && !hasDetails) return false;

  return !hasDetails;
}

export function isGhost(listing) {
  return listing.isGhost || false;
}

export function isForgeListing(listing) {
  return listing.isForgeListing || false;
}

export function isMyRecentSalesListing(listing) {
  return listing.isMyRecentSalesListing || false;
}

export function isHidden(listing) {
  return listing.hide || false;
}

export function isPriceAdjusted(listing) {
  const price = getPrice(listing);
  const adjustedPrice = getAdjustedPrice(listing);
  return Boolean(price && adjustedPrice && price !== adjustedPrice);
}

export function isPriceAdjustedPositive(listing) {
  const price = getPrice(listing);
  const adjustedPrice = getAdjustedPrice(listing);
  const isAdjusted = !!price && !!adjustedPrice;
  const diff = isAdjusted ? adjustedPrice - price : 0;
  return diff > 0;
}

export function isPriceAdjustedNegative(listing) {
  const price = getPrice(listing);
  const adjustedPrice = getAdjustedPrice(listing);
  const isAdjusted = !!price && !!adjustedPrice;
  const diff = isAdjusted ? adjustedPrice - price : 0;
  return diff < 0;
}

export function getListingAdjustmentUpdates({
  price,
  sqft,
  acres,
  adjustments
}) {
  return {
    adjustedPrice: getAdjustedPrice({ price, adjustments }),
    adjustedPricePerSqft: getAdjustedPricePerSqft({ price, sqft, adjustments }),
    adjustedPricePerAcre: getAdjustedPricePerAcre({
      price,
      acres,
      adjustments
    }),
    adjustments: getAdjustments({ adjustments }),
    adjustmentsTotal: getAdjustmentsTotal({ adjustments }),
    isPriceAdjusted: isPriceAdjusted({ price, adjustments }),
    isPriceAdjustedPositive: isPriceAdjustedPositive({ price, adjustments }),
    isPriceAdjustedNegative: isPriceAdjustedNegative({ price, adjustments })
  };
}

function getHistory(listing) {
  const changes = listing.changes || (listing.data || {}).changes;

  if (!changes) {
    return [];
  }

  const changesObject = JSON.parse(changes);
  const dates = Object.keys(changesObject);

  return dates.reduce((state, date) => {
    const types = Object.keys(changesObject[date]);
    const initial = { date: getDateLong(date) };

    const updates = compact(
      types.map((type) => {
        switch (type) {
          case "price_list":
            if (!changesObject[date][type][1]) {
              return null;
            }
            return {
              ...initial,
              type: "price",
              description: getPriceUpdate(changesObject[date][type])
            };
          case "status":
            if (
              !changesObject[date][type][0] ||
              !changesObject[date][type][1]
            ) {
              return null;
            }
            return {
              ...initial,
              type: "status",
              description: getStatusUpdate(changesObject[date][type])
            };
          case "photos":
            if (
              !changesObject[date][type][0] ||
              !changesObject[date][type][1]
            ) {
              return null;
            }
            return {
              ...initial,
              type: "photos",
              description: getPhotosUpdate(changesObject[date][type])
            };
          default:
            return "";
        }
      })
    );

    return [...state, ...updates];
  }, []);
}

function getPriceUpdate(update) {
  const firstPriceNumber = parseInt(update[0]);
  const secondPriceNumber = parseInt(update[1]);
  const firstPriceString = formatCurrency(firstPriceNumber);
  const secondPriceString = formatCurrency(secondPriceNumber);

  if (!update[0]) {
    return `Initial listing price set to ${secondPriceString}`;
  }
  if (firstPriceNumber < secondPriceNumber) {
    return `Price increased from ${firstPriceString} to ${secondPriceString}`;
  }
  return `Price decreased from ${firstPriceString} to ${secondPriceString}`;
}

function getStatusUpdate(update) {
  return `Status changed from ${update[0]} to ${update[1]}`;
}

function getPhotosUpdate(update) {
  if (update[0].length < update[1].length) {
    return "Photos were added";
  }
  return "Photos were removed";
}
