import styled, { css } from "styled-components";
import Color from "color";
import { Container as BaseButtonStyle } from "../../common/styled/button";
import BaseButton from "../../common/Button";
import BaseWistia from "../../common/Wistia";
import BaseYouTube from "../../common/YouTube";

export const Subheader = styled.div`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.6rem;
  margin-bottom: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
    line-height: 1.8rem;
  `}
`;

export const Title = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3rem;
  margin-bottom: 1.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.6rem;
    line-height: 2rem;
  `}
`;

export const TitlePoint = styled.span`
  font-weight: 700;
  position: relative;

  svg {
    position: absolute;
    bottom: -0.5rem;
    left: 0.1rem;
  }
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2.4rem;
`;

export const Intro = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.6rem 0;

  ${({ theme }) => theme.media.sm`
    flex-grow: 0;
    margin-bottom: -2rem;
    text-align: center;
  `};
`;

export const IntroGrid = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
  `};
`;

export const IntroCellLeft = styled.div`
  ${({ theme }) => theme.media.sm`
    display: block;
  `};
`;

export const IntroCellRight = styled.div`
  flex-shrink: 0;
  text-align: center;
  margin-left: auto;
  padding-left: 3rem;

  svg {
    width: 10rem;
    height: 10rem;
    fill: ${({ theme }) => Color(theme.styleGuide.colors.helix['brand']).alpha(0.5).string()};

    ${({ theme }) => theme.media.sm`
      margin: auto;
    `};
  }

  ${({ theme }) => theme.media.sm`
    width: 100%;
    margin: 2rem 0;
    padding-right: 0;
  `};
`;

export const IntroHeader = styled.h3`
  font-size: ${({ hasCustomBranding }) => (hasCustomBranding ? 1.6 : 1.8)}rem;
  font-weight: 600;
  line-height: 2.2rem;
  margin-bottom: 1.2rem;
  padding-right: 0.5rem;
  color: ${({ theme }) => theme.colors.grayDark};

  ${({ theme }) => theme.media.sm`
    padding-right: 0;
  `};
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  display: flex;
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-left: 0;
  color: ${({ theme }) => theme.colors.grayDark};

  &:not(:first-child) {
    margin-top: 0.8rem;
  }
`;

export const ListItemIcon = styled.div`
  font-size: 2.1rem;
  margin-right: 1.6rem;
`;

export const TOS = styled.div`
  display: flex;
  align-items: center;
  margin: 2.4rem 0;
  padding: 1rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[300]};
  border-radius: 0.4rem;
`;

export const TOSIcon = styled.div`
  flex-shrink: 0;
  margin: 0.3rem 0.7rem 0 0.8rem;
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  text-decoration: none;
`;

export const TOSText = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin: 0;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${Link} {
    text-decoration: underline;
    color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  }
`;

export const ButtonLink = styled(BaseButtonStyle.withComponent("a"))`
  margin-right: 1.6rem;
  vertical-align: middle;

  ${({ theme }) => theme.media.sm`
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.6rem;
  `}
`;

const video = css`
  margin: 0 auto;
  border: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};

  &:before {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.white};
  }

  .w-bpb-wrapper {
    width: 12.2rem;
    height: 7.8rem;
    border-radius: 0.4rem;
    overflow: hidden;

    /* Overwrite injected styles with important */
    .w-big-play-button {
      width: 100% !important;
      height: 100% !important;
      background-color: rgba(255, 255, 255, 0.5) !important;
    }
  }
`;

export const Wistia = styled(BaseWistia)`
  ${video};
`;

export const YouTube = styled(BaseYouTube)`
  ${video};
`;

export const Error = styled(Text)`
  margin-top: 2.4rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.red};
`;

export const ButtonIcon = styled.span`
  display: inline-block;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;
    color: currentColor;
  }
`;

export const BackButton = styled(BaseButton)`
  margin-right: 2rem;
`;

export const BackButtonIcon = styled.span`
  display: inline-block;

  svg {
    width: 1.9rem;
    height: 1.3rem;
    margin-right: 0.8rem;
    color: currentColor;
  }
`;

export const Cancel = styled.button`
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 2rem;
  padding: 0.8rem 2rem 0.8rem 0;
  cursor: pointer;
  appearance: none;
  color: ${({ theme }) => theme.colors.gray};

  &:focus {
    outline: none;
    color: ${({ theme }) => theme.styleGuide.colors.helix['brand']};
  }

  svg {
    margin-right: 0.5rem;
  }
`;
