import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";
import { default as BaseButton } from "../../common/Button";

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 60rem;
  padding: 2.4rem 0;
`;

export const ModalHeader = styled(BaseModal.Header)`
  padding: 0 3.2rem;
`;

export const ModalBody = styled(BaseModal.Body)`
  padding-bottom: 2rem;
`;

export const ModalFooter = styled(BaseModal.Footer)`
  border-top: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[300]};
  padding: 2rem 3.2rem 0;
  margin-top: 0;
`;

export const Form = styled.form`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 0;
  padding: 0 3.2rem;
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  gap: 1.2rem;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0;
  gap: 1.2rem;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
    margin: -0.5rem;
  `};
`;

export const Action = styled.div`
  ${({ theme }) => theme.media.sm`
    width: 100%;
    padding: 0.5rem;
  `};
`;

export const Button = styled(BaseButton)`
  height: 3.6rem;

  ${({ theme }) => theme.media.sm`
    width: 100%;
  `};
`;

export const FieldError = styled.p`
  display: inline-block;
  font-size: 1.2rem;
  line-height: 2rem;
  margin: 0;
  color: ${({ theme }) => theme.styleGuide.colors.red[600]};
`;

export const FlashError = styled.div`
  padding: 20px 20px 0 20px;
`;
