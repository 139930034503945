import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "../../Icon";

function IconLayoutPhotoMap(props) {
  return (
    <Icon width="233" height="303" viewBox="0 0 233 303" {...props}>
      {!props.hover && (
        <Fragment>
          <path
            fill="#c4c8d0"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#576474"
            d="M84.13 95.81a1.5 1.5 0 0 1-1.5-1.5V77.42A1.5 1.5 0 0 1 84 75.93L66.5 61.28 49 75.93a1.5 1.5 0 0 1 1.41 1.49v16.89a1.5 1.5 0 0 1-3 0V78.92h-2.58a1.5 1.5 0 0 1-1-2.65l21.71-18.09a1.48 1.48 0 0 1 1.92 0l21.67 18.09a1.52 1.52 0 0 1 .45 1.66 1.5 1.5 0 0 1-1.41 1h-2.54v15.38a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path fill="#e1e5e9" d="M70.2 94.31V82.17h-7.41v12.14" />
          <path
            fill="#576474"
            d="M70.2 95.81a1.5 1.5 0 0 1-1.5-1.5V83.67h-4.41v10.64a1.5 1.5 0 1 1-3 0V82.17a1.5 1.5 0 0 1 1.5-1.5h7.41a1.5 1.5 0 0 1 1.5 1.5v12.14a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path
            fill="#c4c8d0"
            d="M95 114.25H38a8.55 8.55 0 0 1-8.53-8.54V47A8.55 8.55 0 0 1 38 38.43h57.8a7.84 7.84 0 0 1 7.83 7.83v59.45a8.55 8.55 0 0 1-8.63 8.54zM38 41.43A5.54 5.54 0 0 0 32.42 47v58.74a5.54 5.54 0 0 0 5.58 5.51h57a5.54 5.54 0 0 0 5.54-5.54V46.26a4.84 4.84 0 0 0-4.83-4.83zM195.63 114.25h-57.09a8.54 8.54 0 0 1-8.53-8.54V47a8.54 8.54 0 0 1 8.53-8.54h57.8a7.84 7.84 0 0 1 7.83 7.83v59.45a8.55 8.55 0 0 1-8.54 8.51zm-57.09-72.82A5.54 5.54 0 0 0 133 47v58.74a5.54 5.54 0 0 0 5.53 5.54h57.09a5.54 5.54 0 0 0 5.54-5.54V46.26a4.84 4.84 0 0 0-4.83-4.83zM168.23 137.32h34.73v3h-34.73zM31.46 137.32h117.77v3H31.46zM31.46 155.74h171.5v3H31.46zM71.91 174.15h131.05v3H71.91zM31.46 174.15h20.13v3H31.46zM31.46 207.38h34.73v3H31.46zM85.19 207.38h117.77v3H85.19zM31.46 225.79h171.5v3H31.46zM31.46 244.2h131.05v3H31.46zM182.83 244.2h20.13v3h-20.13z"
          />
          <path
            fill="#c4c8d0"
            d="M142.21 104.69a1.49 1.49 0 0 1-1.07-.44 1.51 1.51 0 0 1 0-2.12l52.6-52.59a1.5 1.5 0 1 1 2.12 2.12l-52.59 52.59a1.48 1.48 0 0 1-1.06.44z"
          />
          <path
            fill="#c4c8d0"
            d="M194.8 104.69a1.45 1.45 0 0 1-1.06-.44L168.1 78.62a1.51 1.51 0 0 1 0-2.12 1.49 1.49 0 0 1 2.12 0l25.64 25.63a1.51 1.51 0 0 1 0 2.12 1.48 1.48 0 0 1-1.06.44zM138.37 81.41a1.5 1.5 0 0 1-1.06-2.56l26.94-26.94a1.5 1.5 0 0 1 2.12 2.09l-26.94 27a1.51 1.51 0 0 1-1.06.41z"
          />
          <path
            fill="#c4c8d0"
            d="M179.93 66.54a1.48 1.48 0 0 1-1.05-.43l-18.53-18a1.5 1.5 0 0 1 2.1-2.15L181 64a1.49 1.49 0 0 1 0 2.12 1.45 1.45 0 0 1-1.07.42zM170.31 109.35a1.47 1.47 0 0 1-1.06-.44L153 92.65a1.5 1.5 0 0 1 2.13-2.12l16.25 16.26a1.49 1.49 0 0 1 0 2.12 1.45 1.45 0 0 1-1.07.44z"
          />
          <path
            fill="#e1e5e9"
            d="M188.82 67.84a6 6 0 0 0-6 6c0 4.79 6 12 6 12s6-7.2 6-12a6 6 0 0 0-6-6z"
          />
          <path
            fill="#576474"
            d="M188.82 87.33a1.47 1.47 0 0 1-1.15-.54c-.65-.78-6.35-7.77-6.35-13a7.5 7.5 0 1 1 15 0c0 5.18-5.7 12.17-6.35 13a1.46 1.46 0 0 1-1.15.54zm0-18a4.51 4.51 0 0 0-4.5 4.5c0 2.8 2.65 7 4.5 9.55 1.85-2.53 4.5-6.75 4.5-9.55a4.51 4.51 0 0 0-4.5-4.49z"
          />
        </Fragment>
      )}
      {props.hover && (
        <Fragment>
          <path
            fill="#236847"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#236847"
            d="M84.13 95.81a1.5 1.5 0 0 1-1.5-1.5V77.42A1.5 1.5 0 0 1 84 75.93L66.5 61.28 49 75.93a1.5 1.5 0 0 1 1.41 1.49v16.89a1.5 1.5 0 0 1-3 0V78.92h-2.58a1.5 1.5 0 0 1-1-2.65l21.71-18.09a1.48 1.48 0 0 1 1.92 0l21.67 18.09a1.52 1.52 0 0 1 .45 1.66 1.5 1.5 0 0 1-1.41 1h-2.54v15.38a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path fill="#e1e5e9" d="M70.2 94.31V82.17h-7.41v12.14" />
          <path
            fill="#236847"
            d="M70.2 95.81a1.5 1.5 0 0 1-1.5-1.5V83.67h-4.41v10.64a1.5 1.5 0 1 1-3 0V82.17a1.5 1.5 0 0 1 1.5-1.5h7.41a1.5 1.5 0 0 1 1.5 1.5v12.14a1.5 1.5 0 0 1-1.5 1.5z"
          />
          <path
            fill="#236847"
            d="M95 114.25H38a8.55 8.55 0 0 1-8.53-8.54V47A8.55 8.55 0 0 1 38 38.43h57.8a7.84 7.84 0 0 1 7.83 7.83v59.45a8.55 8.55 0 0 1-8.63 8.54zM38 41.43A5.54 5.54 0 0 0 32.42 47v58.74a5.54 5.54 0 0 0 5.58 5.51h57a5.54 5.54 0 0 0 5.54-5.54V46.26a4.84 4.84 0 0 0-4.83-4.83zM195.63 114.25h-57.09a8.54 8.54 0 0 1-8.53-8.54V47a8.54 8.54 0 0 1 8.53-8.54h57.8a7.84 7.84 0 0 1 7.83 7.83v59.45a8.55 8.55 0 0 1-8.54 8.51zm-57.09-72.82A5.54 5.54 0 0 0 133 47v58.74a5.54 5.54 0 0 0 5.53 5.54h57.09a5.54 5.54 0 0 0 5.54-5.54V46.26a4.84 4.84 0 0 0-4.83-4.83zM168.23 137.32h34.73v3h-34.73zM31.46 137.32h117.77v3H31.46zM31.46 155.74h171.5v3H31.46zM71.91 174.15h131.05v3H71.91zM31.46 174.15h20.13v3H31.46zM31.46 207.38h34.73v3H31.46zM85.19 207.38h117.77v3H85.19zM31.46 225.79h171.5v3H31.46zM31.46 244.2h131.05v3H31.46zM182.83 244.2h20.13v3h-20.13z"
          />
          <path
            fill="#236847"
            d="M142.21 104.69a1.49 1.49 0 0 1-1.07-.44 1.51 1.51 0 0 1 0-2.12l52.6-52.59a1.5 1.5 0 1 1 2.12 2.12l-52.59 52.59a1.48 1.48 0 0 1-1.06.44z"
          />
          <path
            fill="#236847"
            d="M194.8 104.69a1.45 1.45 0 0 1-1.06-.44L168.1 78.62a1.51 1.51 0 0 1 0-2.12 1.49 1.49 0 0 1 2.12 0l25.64 25.63a1.51 1.51 0 0 1 0 2.12 1.48 1.48 0 0 1-1.06.44zM138.37 81.41a1.5 1.5 0 0 1-1.06-2.56l26.94-26.94a1.5 1.5 0 0 1 2.12 2.09l-26.94 27a1.51 1.51 0 0 1-1.06.41z"
          />
          <path
            fill="#236847"
            d="M179.93 66.54a1.48 1.48 0 0 1-1.05-.43l-18.53-18a1.5 1.5 0 0 1 2.1-2.15L181 64a1.49 1.49 0 0 1 0 2.12 1.45 1.45 0 0 1-1.07.42zM170.31 109.35a1.47 1.47 0 0 1-1.06-.44L153 92.65a1.5 1.5 0 0 1 2.13-2.12l16.25 16.26a1.49 1.49 0 0 1 0 2.12 1.45 1.45 0 0 1-1.07.44z"
          />
          <path
            fill="#e1e5e9"
            d="M188.82 67.84a6 6 0 0 0-6 6c0 4.79 6 12 6 12s6-7.2 6-12a6 6 0 0 0-6-6z"
          />
          <path
            fill="#236847"
            d="M188.82 87.33a1.47 1.47 0 0 1-1.15-.54c-.65-.78-6.35-7.77-6.35-13a7.5 7.5 0 1 1 15 0c0 5.18-5.7 12.17-6.35 13a1.46 1.46 0 0 1-1.15.54zm0-18a4.51 4.51 0 0 0-4.5 4.5c0 2.8 2.65 7 4.5 9.55 1.85-2.53 4.5-6.75 4.5-9.55a4.51 4.51 0 0 0-4.5-4.49z"
          />
        </Fragment>
      )}
    </Icon>
  );
}

IconLayoutPhotoMap.propTypes = {
  hover: PropTypes.bool
};

IconLayoutPhotoMap.defaultProps = {
  hover: false
};

export default IconLayoutPhotoMap;
