import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useSession } from "../../Session";
import { groupActivePages } from "../../selectors/customize";
import IconLoading from "../icons/IconLoading";
import IconSparkle from "../icons/IconSparkle";
import CustomizeActivePages from "./CustomizeActivePages";
import CustomizeFlyer from "./CustomizeFlyer";
import CustomizeActiveHiddenInputs from "./connected/CustomizeActiveHiddenInputs";
import CustomizeInactiveFolders from "./connected/CustomizeInactiveFolders";
import CustomizeControls from "./controls/connected/CustomizeControls";

function Customize({ pages, reportType, guid, isResetting, theme, template }) {
  const { features } = useSession();
  const themeFetching = theme && theme.request && theme.request.fetching;
  const templateFetching =
    template && template.request && template.request.fetching && template.load;

  return (
    <div className="flex flex-wrap md:flex-no-wrap relative border border-grey-lighter bg-white rounded-xl overflow-hidden">
      {reportType !== "flyer" && (
        <div
          className="flex-no-shrink w-full md:w-2/5 lg:w-1/3 border-b-1 md:border-b-0 md:border-r-1 border-grey-lighter p-14"
          style={{ backgroundColor: "#f0f2f4" }}>
          <h2 className="text-xl leading-s2xl font-normal text-grey-darker mb-12">
            Additional Content
          </h2>
          <CustomizeInactiveFolders />
        </div>
      )}

      <div className="w-full md:w-3/5 lg:w-2/3">
        <div className="p-14 bg-white customize-active-panel">
          <div className="flex lg:flex-row flex-col lg:items-center justify-between mb-12">
            <h2 className="text-2xl leading-s3xl font-normal text-grey-darker">
              {reportType === "cma" && "Your CMA Report"}
              {reportType === "tour" && "Your Buyer Tour"}
              {reportType === "property" && "Your Property Report"}
              {reportType === "flyer" && "Your Flyer"}
              {reportType === "document" && "Your Document"}
            </h2>
            {reportType === "cma" && features.hasLive && (
              <a
                href={`${window.location.origin}/live/${guid}?edit=true`}
                target="_blank"
                rel="noreferrer"
                className="lg:mt-0 mt-4 flex items-center text-helix-brand flex-no-shrink no-underline font-semibold text-ssm leading-xl">
                {features.hasRdcToolkit && (
                  <IconSparkle
                    style={{ color: "#8953C6" }}
                    height="20"
                    className="mr-s1"
                  />
                )}
                <span className="mr-s4 whitespace-no-wrap">
                  Customize Live CMA Report
                </span>
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  width="20"
                  height="20"
                  className="flex-no-shrink">
                  <path
                    fill="none"
                    d="m16 4-7 7m7-7h-4m4 0v4M14 11v5H4V6h5"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            )}
          </div>
          <CustomizeControls />
          <CustomizeActiveHiddenInputs pages={pages} />
          {reportType !== "flyer" && <CustomizeActivePages pages={pages} />}
        </div>

        {reportType === "flyer" && (
          <div className="bg-grey-lighter border-grey-lighter border-t-1 sm:border-t-0 p-0 sm:p-14">
            <CustomizeFlyer />
          </div>
        )}
      </div>

      {(themeFetching || templateFetching) && reportType !== "flyer" && (
        <div className="flex w-full h-full absolute z-5 pin-t pin-l rounded-lg customize-loading">
          <div className="flex flex-col my-36 mx-auto">
            <div className="text-center">
              <IconLoading />
            </div>
            <span className="block mt-8 text-lg leading-2xl">
              {themeFetching && "Applying Theme"}
              {templateFetching && "Loading Template"}
            </span>
          </div>
        </div>
      )}

      {isResetting && reportType !== "flyer" && (
        <div className="flex w-full h-full absolute z-5 pin-t pin-l rounded-lg customize-loading">
          <div className="flex flex-col my-36 mx-auto">
            <div className="text-center">
              <IconLoading />
            </div>
            <span className="block mt-8 text-lg leading-2xl">
              Resetting Pages
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

Customize.propTypes = {
  reportType: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired,
  isResetting: PropTypes.bool,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      parent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      key: PropTypes.string.isRequired
    })
  ),
  theme: PropTypes.shape({
    request: PropTypes.shape({
      fetching: PropTypes.bool.isRequired
    })
  }),
  template: PropTypes.shape({
    request: PropTypes.shape({
      fetching: PropTypes.bool.isRequired
    })
  })
};

Customize.defaultProps = {
  pages: []
};

const mapStateToProps = (state) => ({
  reportType: state.report.type.toLowerCase(),
  guid: state.report.guid,
  pages: groupActivePages(state),
  isResetting: state.customize.isResetting,
  theme: state.customize.theme,
  template: state.customize.template
});

export default connect(mapStateToProps)(Customize);
